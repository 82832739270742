import React from 'react';
import ShipmentTracking from './ShipmentTracking';
import ResultsFilter from './ResultsFilter';
import { ResultsComponent, getProduct, getListing } from './AllThing';
import { getPath, setPath } from './fire';
import { Container, Row, Col } from 'react-bootstrap';
import { Pager } from './Pager';
import { WorklistRow } from './WorklistRow';
import { SaleEdit } from './SaleEdit';
import { ReturnEdit } from './ReturnEdit';
import { ProductEdit } from './ProductEdit';
import { ListingEdit } from './ListingEdit';
import { StockCommitEdit } from './StockCommitEdit';
import { worklistStatusFilter, worklistSort } from './filterDefinitions';
import ClipboardCopy from './ClipboardCopy';

class Worklist extends ResultsComponent {
  constructor(props) {
    super(props);
  }

  deleteSale = async (row) => {
    const orderId = row.key;

    if (!orderId) return;

    // Retrieve the order
    const order = await getPath(`/order/sale/${orderId}`);

    // Set the quantity to 0 for each item
    for (const [key, item] of Object.entries(order.item || {})) {
      await setPath(`/order/sale/${orderId}/item/${key}/quantity`, 0);
    }

    // Set order status to cancelled
    return setPath(`/order/sale/${orderId}/status`, 'Canceled');
  }

  openReturnEditModal = (row, modalCallback) => {
    const showModal = modalCallback || this.showModal;

    const modalContent = (
      <ReturnEdit 
        row={ row } 
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
      />
    );
    
    const modalTitle = (
      <>
        Return for Order # {row.number} <ClipboardCopy copy={row.number} />
      </>
    );
    
    showModal(modalTitle, modalContent);
  }

  openProductEditModal = async (productId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const productSnapshot = await getProduct(productId);
    const product = { ...productSnapshot.val(), key: productId };

    const modalContent = (
      <ProductEdit 
        row={ product } 
        search={ this.props.search }
        index={ this.props.index }
        hideModal={ this.hideModal }
        setModalButtons={this.setModalButtons} />
    );
    
    const modalTitle = (
      <>
        Edit Product <ClipboardCopy copy={productId} />
      </>
    );
    
    showModal(modalTitle, modalContent);
  }

  openListingEditModal = async (listingId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const listingSnapshot = await getListing(listingId);
    const listing = { ...listingSnapshot.val(), key: listingId };

    const modalContent = (
      <ListingEdit 
        row={listing}
        search={this.props.search}
        index={ this.props.index }
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons} />
    );
    
    const modalTitle = (
      <>
        Edit Listing <ClipboardCopy copy={listingId} />
      </>
    );
    
    showModal(modalTitle, modalContent);
  }

  openSaleEditModal = (row, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    if (row.status.toLowerCase() === "pending" || row.status.toLowerCase().startsWith('cancel')) {
      return; // Don't open the modal for pending or cancelled orders
    }

    const modalContent = (
      <SaleEdit 
        row={row}
        search={this.props.search}
        hideModal={this.hideModal}
        allowDelete={true}
        onDelete={this.deleteSale}
        deleteLabel="Cancel"
        setModalButtons={this.setModalButtons}
        openListingEditModal={this.openListingEditModal}
        openProductEditModal={this.openProductEditModal}
        openStockCommitEditModal={this.openStockCommitEditModal}
      />
    );
    
    const modalTitle = (
      <>
        Edit Order <ClipboardCopy copy={row.key} />
      </>
    );
    
    showModal(modalTitle, modalContent);
  }

  openStockCommitEditModal = async (productId, modalCallback) => {
    const showModal = modalCallback || this.showModal;
    const productSnapshot = await getProduct(productId);
    const product = { ...productSnapshot.val(), key: productId };

    const modalContent = (
      <StockCommitEdit 
        product={product}
        search={this.props.search}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons}
      />
    );
    
    const modalTitle = (
      <>
        Edit Stock for Product <ClipboardCopy copy={productId} />
      </>
    );
    
    showModal(modalTitle, modalContent);
  }

  openShipmentTrackingModal = (tracking) => {
    const modalContent = (
      <ShipmentTracking tracking={tracking} />
    );

    this.showModal('Shipment Tracking', modalContent);
  }

  render() {
    let search = this.props.search;
    let worklistRows = null;
    
    if (search.location === window.location.href) {
      worklistRows = this.props.results.map(row =>
        <WorklistRow 
          key={row.key} 
          row={row} 
          search={this.search}
          index={this.index}
          editRow={this.editRow}
          editListing={this.editListing}
          showModal={this.showModal}
          defaultChannel={this.state.defaultChannel}
          defaultCondition={this.state.defaultCondition} 
          openListingEditModal={this.openListingEditModal}
          openProductEditModal={this.openProductEditModal}
          openReturnEditModal={this.openReturnEditModal}
          openSaleEditModal={this.openSaleEditModal}
          openShipmentTrackingModal={this.openShipmentTrackingModal}
          openStockCommitEditModal={this.openStockCommitEditModal}
        /> 
      );
    } else {
      worklistRows = <Row><Col>Loading Data ...</Col></Row>;
    }
    
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col>
              <ResultsFilter 
                filterDefinitions={{ worklistStatusFilter, worklistSort }} 
                onFilterChange={this.setFilter}
                onSortChange={this.setSort}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-2 font-weight-bold d-none d-md-flex">
                <Col xs={12} md={2}>Ordered</Col>
                <Col xs={12} md={2}>Marketplace</Col>
                <Col xs={12} md={4}>Order Items</Col>
                <Col xs={6} md={1}>Customer</Col>
              </Row>
              {worklistRows}
              <Pager
                pageCount={search.pages} 
                activePage={search.page} 
                onSelect={search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Worklist };