import React from 'react';
import { EditComponent, formatDate, getProduct, getStockLog, getStockStyles, getStockTypes, getStockTypeMap, updatePath } from './AllThing';
import { Alert, Badge, Container, Row, Col, FormGroup, InputGroup, Form, Table, Tabs, Tab } from 'react-bootstrap';
import { FaRegFile, FaArrowRight } from 'react-icons/fa';
import { ImageCarousel } from './ImageCarousel';
import { QuickEdit } from './QuickEdit';
import { setPath, watchPath } from './fire';


class StockCommitEdit extends EditComponent {
  constructor(props) {
    super(props);
    
    this.stockTypes = getStockTypes();
    this.stockStyles = getStockStyles();
    
    // Initialize Stock Values
    if (props.allocateQuantity && props.allocateCondition) {
      this.state.stock = {
        allocated: parseInt(props.allocateQuantity),
        [ props.allocateCondition ]: parseInt(props.allocateQuantity)
      };
    } else {
      this.state.stock = { };
    }
    
    this.state.note = '';
    this.state.stockLog = { };
    
    Object.keys(this.stockTypes).map(type => {
      if (!props.allocateCondition || type !== props.allocateCondition) {
        this.state.stock[ type ] = 0;
      }
      
      return null;
    });
    
    this.product = { };
        
    // Bindings
    this.commitStock = this.commitStock.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.updateStock = this.updateStock.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.validateNote = this.validateNote.bind(this);
  }
  
  componentDidMount() {
    const props = this.props;
    const product = props.product;
    
    // Clicking the 'Commit Stock' button will update the parent component
    // state via the provided commitStock callback.  It's possible a user may
    // try to commit stock to a new purchase order item that does not yet
    // have an entry, which is why we are not saving the updated data directly
    // from this component.
    //this.validateNote(this.state.note, this.state.stock);
    this.setParentModalButtons(this.getModalButtons());
    
    if (product) {
      if (typeof product === 'object') {
        this.initLocation(product);      
        this.setState({ product: this.reverseCommit( product ) });
        this.loadOpenOrders(product.key);
        this.loadStockLog(product.key);
      } else {
        getProduct(product).then(snap => {
          this.initLocation(snap.val());
          this.setState({ product: this.reverseCommit(snap.val()) })
          this.loadOpenOrders(snap.key);
          this.loadStockLog(snap.key);
        });
      }
    }
  }

  // Update button validation state
  componentDidUpdate() {
    this.setParentModalButtons(this.getModalButtons());
  }
  
  commitStock() {
    const pid = this.state.product.key;
    
    const data = {
      commit: this.state.stock, 
      note: this.state.note || null,
      committed: true,
      date: new Date().toJSON(),
      source: 'manual',
      user: this.getUser().uid
    };
    
    this.commit(data, `stock/${pid}`)
      .then(() => {
        this.props.hideModal();
        this.props.onClose && setTimeout(this.props.onClose, 1000);
      });
    
    // Commit location
    if (pid) {
      this.commit(this.state.product.location, `product/${pid}`, 'location');
    }
  }  
  
  initLocation(product) {
    // Initialize product warehouse locations
    product.location = product.location || { };
    
    Object.keys(this.stockTypes).map(type => {
      const stockType = parseInt(this.state.stock[ type ]) || 0;     
    
      product.location[ type ] = product.location[ type ] || {
        aisle: '', floor: '', bay: '', shelf: '', notes: ''
      };
    });
    
    return product;    
  }
  
  getModalButtons() {
    if (this.props.commitStock) {
      return [{ 
        callback: () => this.props.commitStock({ 
          ...this.state.stock, note: this.state.note || null
        }), 
        value: 'Commit',
        disabled: this.hasValidationErrors()
      }];
    } else {
      return [{ 
        callback: () => this.commitStock(), 
        value: 'Commit',
        disabled: this.hasValidationErrors()
      }];
    }
  }
  
  loadOpenOrders(pid) {
    const search = this.props.search;
    const query = `
      (ordered:>=now-14d) 
        AND (shipping.status:InfoReceived OR shipping.status.keyword:"") 
        AND (NOT status:cancelled) 
        AND (NOT status:shipped)
        AND (item.product:${pid})    
    `;
    
    search.simpleQuery('order_sale', query)
      .then(results => {
        let quantity = 0;
        
        results.map(result =>
          Object.keys(result.item || { }).map(idx => {
            const item = result.item[ idx ] || { };
            
            if (item.product === pid) {
              quantity += item.quantity;
            }
            
            return quantity;
          })
        );
        
        this.setState({ openOrderQuantity: quantity });
      });
  }
  
  loadStockLog(pid) {
    watchPath(`stock/${pid}`, ({ key, ...stockLog }) => {
      this.setState({ stockLog });
    });
  }
  
  // When editing already committed stock receipts, back out the committed 
  // values from starting product stock for display purposes.
  reverseCommit(product) {
    if (this.props.commit) {
      Object.keys(this.props.commit).map(type =>
        product.stock[ type ] -= this.props.commit[ type ]
      );
    }
  
    return product;
  }
  
  // Not using inherited this.updateRow() because it also controls validation
  // and modal buttons, which we are manually controlling in this component.
  // updatePath() is a utility function that parses recursive / nested data
  // using dot '.' notation
  updateLocation(e, type) {
    const { name, value } = e.target;
    const product = this.state.product;
    
    this.setState({
      product: {
        ...product,
        location: {
          ...product.location,
          [ type ]: {
             ...product.location[ type ],
             [ name ]: value
          }
        }
      }
    });
  }
  
  updateNote(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateNote(this.state.note, this.state.stock);
    });
  }
  
  updateStock(e) {
    const { name, value } = e.target;
    const { note, stock } = this.state;
    const quantity = parseInt(value);
    const allocateQty = this.props.allocateQuantity;
    const stockTypeMap = getStockTypeMap();
    
    // Remove exempt types from map
    delete stockTypeMap[ 'src' ];
    
    // Calculate allocate stock
    if (allocateQty && name !== 'src') {    
      const allocated = Object.keys(stockTypeMap)
        .reduce((acc, val) => acc + parseInt(stock[ val ] || 0), 0)
        - parseInt(stock[ name ] || 0) + quantity; 
      
      // If all stock has not been allocated, remove the commit button  
      if (allocated < allocateQty) {
        this.setParentModalButtons();
      } else {
        this.setParentModalButtons(this.getModalButtons());
      }
      
      // If allocated stock is out of bounds, do nothing  
      if (allocated > allocateQty || quantity < 0) return;

      this.setState({ stock: {
        ...stock, [ name ]: quantity, allocated
      }});
    } else {
      this.validateNote(note, { ...stock, [ name ]: quantity });
      this.setState({ stock: {
        ...stock, [ name ]: quantity
      }});
    }        
  }

  validateNote(note, stock) {
    const stockQty = Object.keys(stock || { }).reduce((acc, val) => acc + parseInt(stock[ val ] || 0), 0);
    const isManual = !this.props.allocateQuantity && !this.props.allocateCondition;
    const errMsg = 'Note is required for manual adjustments';
    this.validateField('note', !isManual || !stockQty || note?.length > 0, errMsg);
  }

  render() {
    const { inception = { }, ...stockLog } = this.state.stockLog;
    const product = this.state.product || {};
    const stock = this.state.stock;
    const props = this.props;
    const unallocated = props.allocateQuantity - stock.allocated; 
    const errors = this.getValidationErrors();

    
    const markup = Object.keys(this.stockTypes).map(type => {
      const currentStock = parseInt((product 
       && product.stock 
       && product.stock[type] 
       && product.stock[type].available) || 0);             
      
      const stockType = parseInt(stock[ type ]) || 0; 
      const newStock = currentStock + stockType;
      
      if (type !== 'fba') {
        return (
          <tr key={ type } className={ stockType && 'stock-allocated'}>
            <td>
              <span className={ `text-nowrap ${ this.stockStyles[ type ] }` }>
                { this.stockTypes[ type ] } <FaArrowRight />
              </span>
            </td>
            <td>{ currentStock }</td> 
            <td>+</td>            
            <td>
              <Form.Control 
                type="number" 
                name={ type }
                size="sm"
                value={ stockType } 
                onChange={ this.updateStock } />
            </td>
            <td>=</td>            
            <td>{ newStock }</td>
          </tr>
        );
      } else {
        return (
          <tr key={ type } className={ stockType && 'stock-allocated'}>
            <td colSpan={ 4 }>
              <span className={ `text-nowrap ${ this.stockStyles[ type ] }` }>
                { this.stockTypes[ type ] } <FaArrowRight />
              </span>
            </td>
            <td>=</td>            
            <td>{ currentStock }</td> 
          </tr>
        );
      }
    });
    
    const locationMarkup = Object.keys(this.stockTypes).map(type => {
      this.initLocation(product);

      const location = product.location || { };
      const stockType = parseInt(stock[ type ]) || 0;       
      
      if (type !== 'fba') {
        return (
          <tr key={ type } className={ stockType && 'stock-allocated'}>
            <td>
              <span className={ `text-nowrap ${ this.stockStyles[ type ] }` }>
                { this.stockTypes[ type ] } <FaArrowRight />
              </span>
            </td>
            <td>
              <Form.Control 
                type="text" 
                name="aisle"
                size="sm"
                value={ location[ type ].aisle } 
                placeholder="A-Z"
                onChange={ (e) => this.updateLocation(e, type) } />
            </td>
            <td>
              <Form.Control 
                type="text" 
                name="floor"
                size="sm"
                value={ location[ type ].floor } 
                placeholder="1-2"
                onChange={ (e) => this.updateLocation(e, type) } />
            </td>
            <td>
              <Form.Control 
                type="text" 
                name="bay"
                size="sm"
                value={ location[ type ].bay } 
                placeholder="A-Z"
                onChange={ (e) => this.updateLocation(e, type) } />
            </td>
            <td>
              <Form.Control 
                type="text" 
                name="shelf"
                size="sm"
                value={ location[ type ].shelf } 
                placeholder="1-10"
                onChange={ (e) => this.updateLocation(e, type) } />
            </td>
            <td>
              <Form.Control 
                type="text" 
                name="notes"
                size="sm"
                value={ location[ type ].notes } 
                onChange={ (e) => this.updateLocation(e, type) } />
            </td>
          </tr>
        );
      }
    });
              
    return (
      <form autoComplete="off">
        { this.renderModal() }
        <Container fluid>
  
          { this.hasValidationErrors() && 
            <Alert variant="danger" bg="danger">
              <Alert.Heading>Form Validation Error(s)</Alert.Heading>              
              { Object.keys(errors).map(field => 
                <p key={ field }>
                  <strong>{ field }:</strong> { errors[ field ] }
                </p>
              )}
            </Alert>
          }

          <Row>
            <Col sm={ 12 } className="text-truncate">
              <Form.Group>
                <Form.Label>      
                  <div className="font-weight-bold">
                      { product.title }
                  </div>
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          
          { !!this.state.openOrderQuantity &&
            <Row>
              <Col sm={ 12 }>
                <Alert variant="warning">
                  There are currently&nbsp; 
                  <Badge variant="danger">
                    { this.state.openOrderQuantity } 
                  </Badge> units assigned to open orders for this product.
                </Alert>
              </Col>
            </Row>
          }

          <Tabs defaultActiveKey="stockEdit" id="stock-commit-edit">

            <Tab eventKey="stockEdit" title="Stock Edit">
              <Row>
                <Col sm={ 6 }>
                  { product.image &&
                    <ImageCarousel images={ product.image } />
                  }
                </Col>
                <Col sm={ 6 }>
                  <Table borderless size="sm" className="stock-allocate">
                    <tbody>
                      { markup }
                      { this.props.allocateQuantity &&
                        <tr className="stock-unallocated">
                          <td colSpan={ 5 }>
                            <span className="text-nowrap">
                              { `Unallocated 
                                ${ this
                                     .stockTypes[ this.props.allocateCondition ]
                                     .toUpperCase() 
                                 }
                                 Remaining` } <FaArrowRight />
                            </span>
                          </td>
                          <td>
                            <span className={ `stock-final 
                              ${ unallocated && 'unallocated' }` }>
                                { unallocated }
                            </span>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              { !this.props.allocateQuantity && !this.props.allocateCondition &&
                <Row>
                  <Col sm={ 12 }>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text><FaRegFile /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control 
                          type="text" 
                          name="note"
                          size="sm"
                          required
                          placeholder="Reason for adjustment (required)"
                          value={ this.state.note } 
                          onChange={ this.updateNote } />            
                      </InputGroup>
                  </FormGroup>
                  </Col>
                </Row>
              }
            </Tab>

            <Tab eventKey="stockLog" title="Stock Log">
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Start</th>
                    <th>Activity</th>
                    <th>End</th>
                  </tr>
                </thead>
                <tbody>
                  { renderStockLog(stockLog, inception, { 
                    exclude: { external: true },
                    pid: product.key
                  }) }
                </tbody>
              </Table>
            </Tab>
            
            <Tab eventKey="3plLog" title="3PL Log">
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Start</th>
                    <th>Activity</th>
                    <th>End</th>
                  </tr>
                </thead>
                <tbody>
                  { renderStockLog(stockLog, inception, { 
                    include: { external: true } 
                  }) }
                </tbody>
              </Table>
            </Tab>
            
            { product.key &&
              <Tab eventKey="location" title="Warehouse Location">
                <Row>
                  <Col sm={ 12 }>
                    <Table borderless size="sm" className="stock-allocate">
                      <tbody>
                        <tr>
                          <td></td>
                          <td>Aisle</td>
                          <td>Floor</td>
                          <td>Bay</td>
                          <td>Shelf</td>
                          <td className="location-notes">Notes</td>
                        </tr>
                        { locationMarkup }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Tab>
            }

          </Tabs>

        </Container>
      </form>    
    );
  }
}

const renderStockLog = (stockLog, inception = { }, opts = { }) => {
  const stockTypes = getStockTypes();
  const stockStyles = getStockStyles();
  const init = { ...inception.commit };
  
  Object.keys(stockLog).map(key => {
    const log = stockLog[ key ];
    
    log.start = log.start || { };
    log.end = log.end || { };
    
    return Object.keys(log.commit || {}).map(condition => {
      const commit = log.commit[ condition ];

      log.start[ condition ] = init[ condition ] = init[ condition ] || 0; 
      log.end[ condition ] = init[ condition ] += (log.committed ? commit : 0);
    });

  });
  
  return Object.keys(stockLog).reverse().map(key => {
    const log = stockLog[ key ];
    
    return Object.keys(log.commit || {}).map(condition => {
      const commit = log.commit[ condition ];
      //const init = { ...inception.commit } || {};
      const sign = commit > 0 ? '+' : '';
      const style = stockStyles[ condition ];
      const type = stockTypes[ condition ];
      const format = commit > 0 ? 'text-success' : 'text-danger';
      
      if (!commit 
        || (opts.exclude && opts.exclude[ log.source ])
        || (opts.include && !opts.include[ log.source ])) return null;
      
      // Tabulate running changes to stock
      const start = log.start[ condition ];
      const end = log.end[ condition ];
      //const start = init[ condition ] = init[ condition ] || 0;
      //const end = init[ condition ] += (log.committed ? commit : 0);        
            
      return (
        <React.Fragment key={ `${key}-${condition}` }>
          <tr
            className={ 
              `stock-log-entry ${ log.committed ? '' : 'inactive' }` 
            }>
              <td>{ formatDate(log.date) }</td>
              <td className={ style }>
                { start }x <strong>{ type }</strong>
              </td>
              <td>
                <strong>
                  <span className={ format }>{ sign }{ commit }x</span>
                  &nbsp;
                  <span>
                    <span className="text-capitalize">{ log.source }</span>
                    <span className="stock-log-cost">
                      { log.source === 'receipt' &&
                        <span>(${ log.cost } ea.)</span>
                      }
                      { log.source === 'sale' &&
                        <span>(${ log.price } ea.)</span>
                      }
                      { log.source === 'manual' && commit > 0 &&
                        <QuickEdit as="input" size="sm" htmlSize={4}
                          name="cost"
                          value={ log.cost }
                          onEndEdit={ (e) => updateCost(e, `/stock/${opts.pid}/${key}`) }>
                            <span className="editable">
                              ({ log.cost ? `$${log.cost} ea.` : 'Edit Cost' })
                            </span>
                        </QuickEdit>
                      }
                    </span>
                  </span>
                </strong>
              </td>
              <td className={ style }>
                { end }x <strong>{ type }</strong>
              </td>
          </tr>
          { log.note &&
            <tr>
              <td colSpan="4" className="text-center">
                <small className="text-muted">
                  { log.note }
                </small>
              </td>
            </tr>
          }
        </React.Fragment>
      );
    });
  });
} 

const updateCost = (e, path) => {
  const { name, value } = e.target;
  setPath(`${path}/${name}`, Number(value));
}


export { StockCommitEdit };
