import React, { useState } from 'react';
import { Card, Form, Button, ListGroup, Badge } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import BatchContext from './BatchContext';

/**
 * BatchEdit component for handling bulk operations on selected items
 * @param {Object} props
 * @param {number} props.checkedCount - Number of currently selected items
 * @param {number} props.totalCount - Total number of items available
 * @param {Function} props.onCheckAll - Handler for selecting all items
 * @param {Function} props.getBatchDataPaths - Function to get paths of selected items
 * @param {Object} props.batchOperations - Object containing batch functions
 */
const BatchEdit = ({ checkedCount, totalCount, onCheckAll, getBatchDataPaths, batchOperations = {} }) => {
  const [op, setOp] = useState('');
  const [opValue, setOpValue] = useState('');
  const [operations, setOperations] = useState([]);

  const addBatchOperation = () => {
    const operation = batchOperations[op];
    const { fn, label, input } = operation;

    console.log(operation, opValue);

    if (operation && (!input || opValue)) {
      setOperations(prev => [...prev, { label, value: opValue, fn }]);
      setOp('');
      setOpValue('');
    }
  };

  const removeBatchOperation = (idx) => {
    setOperations(prev => prev.filter((_, i) => i !== idx));
  };

  return (
    <Card className={`bulk-edit ${checkedCount > 0 ? 'd-block' : 'd-none'}`}>
      <Card.Header><strong>Bulk Editing</strong></Card.Header>
      <Card.Body>
        <Form.Group className="text-center">
          <Form.Label className="font-weight-bold">{checkedCount} rows selected</Form.Label>
          <Form.Check 
            type="checkbox"
            onChange={() => onCheckAll(true)} 
            label={`Select all ${totalCount} matching rows`} />
        </Form.Group>                      
        <Form.Group>
          <Form.Label>Batch Operation</Form.Label>
          <Form.Control
            as="select"
            name="op"
            value={op}
            onChange={(e) => setOp(e.target.value)}>
             <option value="">Select Operation</option>
              {Object.entries(batchOperations).map(([key, op]) => (
                <option value={key}>{op.label}</option>
              ))}
          </Form.Control>
        </Form.Group>
        { op && batchOperations[op].input && (
          <Form.Group>
            <Form.Control
              type={batchOperations[op].input.type}
              name="opValue"
              value={opValue}
              onChange={(e) => setOpValue(e.target.value)}
              placeholder={batchOperations[op].input?.placeholder} />
          </Form.Group>
        )}
        <Form.Group>
          <Button 
            block
            size="sm"
            variant="outline-info"
            onClick={addBatchOperation}>
            Add Bulk Operation
          </Button>
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        <small>
          Planned Operations: {operations.length}
        </small>
      </Card.Footer>

      <ListGroup variant="flush">
        {operations.map((operation, idx) => (
          <ListGroup.Item key={idx} className="d-flex justify-content-between">
            <span>
              {idx + 1}. {operation.label} 
              <Badge size="sm" variant="secondary" className="ml-2">{operation.value}</Badge>
            </span>
            <span>
              <FaTimes 
                className="clickable" 
                onClick={() => removeBatchOperation(idx)} />
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {operations.length > 0 && (
        <Card.Body>
          <BatchContext.Consumer>
            {batch => (
              <Button 
                block
                size="sm"
                variant="info"
                onClick={() => batch.addBatch({
                  dataPaths: getBatchDataPaths(),
                  onData: operations
                })}>
                Begin Bulk Updates
              </Button>
            )}
          </BatchContext.Consumer>
        </Card.Body>
      )}
    </Card>
  );
};

export default BatchEdit;
