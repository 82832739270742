import React from 'react';
import ShipmentStatus from './ShipmentStatus';
import { OverlayTrigger, Button, Row, Col, Tooltip } from 'react-bootstrap';
import { FaUndo, FaHome, FaBuilding, FaClock, FaShippingFast } from 'react-icons/fa';
import { TbLinkOff } from 'react-icons/tb';
import { RowComponent, getChannelIcon, getListing, getContact, getProduct, calculateAge, getShipStatusLevel } from './AllThing';
import WorklistWidget from './WorklistWidget';

class WorklistRow extends RowComponent {
  constructor(props) {
    super(props);

    // onData callback for side-loading listing data
    this.onData(data => {    
      this.loadContactDetails(data);
      this.loadItemDetails(data);
    });    

  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: '2-digit' });
  }

  loadContactDetails = async (row) => {
    if (!row?.ship?.contact) return;
    const contact = await getContact(row.ship.contact);
    this.setState({ contact });
  }

  loadItemDetails = async (row) => {
    const updatedItems = {};
    const listings = {};

    for (const [id, item] of Object.entries(row.item || {})) {
      const productSnapshot = await getProduct(item.product);
      const product = { ...productSnapshot.val(), key: productSnapshot.key };
      let listing = {};

      // Find the listing for this channel and item
      for (const listingId of Object.keys(product?.listing || {})) {
        const listingSnapshot = await getListing(listingId);
        const productListing = { ...listingSnapshot.val(), key: listingSnapshot.key };

        if ((productListing.key === item.channelId || 
            productListing.childId === item.channelId) && 
            productListing.channel === row.channel) {
          listing = { ...productListing };
          break;
        }
      }

      updatedItems[id] = {
        ...item,
        product,
        listing
      };
    }

    this.setState(prevState => ({
      row: {
        ...prevState.row,
        item: updatedItems
      }
    }));
  }

  render() {
    let row = this.state.row;
    row.history = row.history || {};
    row.shipping = row.shipping || {};
    row.ship = row.ship || {};

    const contact = this.state.contact;
    const shipTo = { ...row.ship, ...contact?.location?.[row.ship.location] };

    // Determine if the order is canceled
    const isCanceled = row.status.toLowerCase().startsWith('cancel');
    const mustShipToday = new Date(row.shipping.cutoff).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) && getShipStatusLevel(row?.shipping?.status) < 2;

    return (
      <Row className={`pb-2 pt-2 align-items-center sale-row ${isCanceled ? 'text-muted' : ''}`}>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 2, order: 3 }}>
          <span>{getChannelIcon(row.channel)}</span>
          <span 
            className="ml-1"
            onClick={() => this.props.openSaleEditModal(row)} 
            style={{ cursor: row.status.toLowerCase() === "pending" || isCanceled ? 'default' : 'pointer' }}
          >
            {isCanceled ?  row.number || row.channelId : row.number || row.channelId}
          </span>
          {isCanceled && (
            <span className="badge badge-secondary ml-1">
              <span className="small font-weight-bold">CANCELED</span>
            </span>
          )}
          {row.return && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-return-${row.key}`}>
                  {Object.values(row.return)[0]?.classification || 'Return'}
                </Tooltip>
              }
            >
              <Button variant="link" className="p-0 m-0" onClick={() => this.props.openReturnEditModal(row)}>
                <span className="badge badge-warning">
                {' '}<FaUndo size={10} />
                </span>
              </Button>
            </OverlayTrigger>
          )}
          {!isCanceled && mustShipToday && (
            <>
              {' '}<span className="badge badge-warning ml-1">
                <span className="small font-weight-bold">CONFIRM TODAY</span>
              </span>
            </>
          )}
          {!isCanceled && row.status.toLowerCase() === "pending" && (
            <span className="badge badge-secondary ml-1">
              <span className="small font-weight-bold">PENDING</span>
            </span>
          )}
        </Col>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 4 }}>
          {Object.keys(row.item || {}).map(id => 
            <div key={id} className="d-flex align-items-center">
              <div>
                <div>
                  <WorklistWidget row={row} id={id} />
                  <span 
                    className={`badge ${row.item[id].quantity > 1 ? 'badge-primary' : 'badge-secondary'} mr-1 rounded-circle d-inline-flex align-items-center justify-content-center`} 
                    style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                    onClick={() => this.props.openStockCommitEditModal(row.item[id].product.key)}
                  >
                    <span className="small font-weight-bold"> 
                      {row.item[id].quantity}
                    </span>
                  </span>
                  {row.item[id]?.product?.vendor && (
                    <span onClick={() => this.props.openProductEditModal(row.item[id].product.key)} style={{ cursor: 'pointer' }}>
                      {row.item[id].product.vendor}{' '}
                    </span>
                  )}                  
                  {row.item[id]?.product?.model && (
                    <span onClick={() => this.props.openProductEditModal(row.item[id].product.key)} style={{ cursor: 'pointer' }}>
                      {row.item[id].product.model}{' '}
                    </span>
                  )}
                  {!row.item[id].model && (
                    <>
                      <span className="text-muted mr-2">
                        {row.item[id].title}
                      </span>
                      <span className="text-danger mr-2">
                        <TbLinkOff title="Broken Link" />
                      </span>
                    </>
                  )}
                  <span 
                    className="small"
                    onClick={() => this.props.openListingEditModal(row.item[id]?.listing?.key)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="text-success font-weight-bold mr-1"> 
                      {row.item[id].quantity === 1 && 
                        `$${(
                          parseFloat(row.item[id]?.price) 
                          || parseFloat(row.item[id]?.listing?.price) 
                          || parseFloat(row.item[id]?.listing?.channelPrice) || 0
                        ).toFixed(2)}`
                      }
                      {row.item[id].quantity > 1 && 
                        `$${(
                          row.item[id].quantity * 
                          (parseFloat(row.item[id]?.price) 
                            || parseFloat(row.item[id]?.listing?.price) 
                            || parseFloat(row.item[id]?.listing?.channelPrice)
                          )
                        )?.toFixed(2)}`
                      }
                    </span>
                    <small className="text-muted">
                      {row.item[id].quantity > 1 && `
                      $${parseFloat(row.item[id]?.price)
                        || parseFloat(row.item[id]?.listing?.channelPrice) 
                        || parseFloat(row.item[id]?.listing?.price)
                        || parseFloat(row.item[id]?.listing?.override?.price)
                      } ea
                    `}
                    </small>
                  </span>
                  {row.item[id]?.listing?.channelCondition?.toLowerCase()?.startsWith('use') && (
                    <span className="badge badge-warning ml-1">
                      <span className="small font-weight-bold">
                        {row.item[id]?.listing?.channelCondition?.toUpperCase()}
                      </span>
                    </span>
                  )}
                </div>
                <div className="small text-muted">
                  <span>
                    {row.item[id]?.product?.title && row.item[id]?.product?.title.length > 50
                      ? `${row.item[id]?.product?.title.substring(0, 50)}...`
                      : row.item[id]?.product?.title}
                  </span>
                </div>
              </div>
            </div> 
          )}
        </Col>
        <Col xs={{ span: 6, order: 3 }} md={{ span: 2, order: 1 }}>
          <span className="mr-1 small text-muted">
            <FaClock />
          </span>
          <span className="mr-2 small text-muted">
            {calculateAge(row.ordered)}
          </span>
          <span className="mr-1 small text-muted">
            {row.shipping.cutoff && !isNaN(new Date(row.shipping.cutoff).getTime()) && <FaShippingFast />}
          </span>
          <span className="mr-2 small text-muted">
            {row.shipping.cutoff && !isNaN(new Date(row.shipping.cutoff).getTime()) 
              ? this.formatDate(row.shipping.cutoff) 
              : ''}
          </span>
        </Col>
        <Col xs={{ span: 6, order: 4 }} md={{ span: 4, order: 5 }}>
          {shipTo.person && (
            <div className="small text-muted">
              {shipTo.residential === false ? <FaBuilding /> : <FaHome />}
              {' '}
              {shipTo.person && `${shipTo.person}`}
              {!shipTo.person && shipTo.company && `${shipTo.company}`}
              <ShipmentStatus row={row} openShipmentTrackingModal={this.props.openShipmentTrackingModal}/>
              <span className="d-none d-md-inline">
                <br />
                {shipTo.city}, {shipTo.state} {shipTo.country !== 'US' && shipTo.country}
              </span>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export { WorklistRow };