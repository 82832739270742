import React from 'react';
import { EditComponent, getContact, getChannelIcon } from './AllThing';
import { Alert, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaBuilding, FaHome, FaBarcode } from 'react-icons/fa';
import { Image } from 'react-bootstrap';
import axios from 'axios';
import ClipboardCopy from './ClipboardCopy';
class SaleEdit extends EditComponent {
  constructor(props) {
    super(props);
    this.saveFields = [
      'key', 'number', 'status', 'ordered', 'shipping', 'total', 'item'
    ];

    // Configure data connector to allow new records
    //this.allowNewRecord = true;

    this.openListingEditModal = (listingId) => this.props.openListingEditModal(listingId, this.showModal);
    this.openProductEditModal = (productId) => this.props.openProductEditModal(productId, this.showModal);
    this.openStockCommitEditModal = (productId) => this.props.openStockCommitEditModal(productId, this.showModal);

    // Ensure the row has all necessary properties
    this.state.row = {
      ...this.state.row,
      shipping: this.state.row.shipping || {},
      item: this.state.row.item || {}
    };
  }

  componentDidMount() {
    this.loadContactDetails(this.state.row);

    Object.entries(this.state.row.item).forEach(([id, item]) => {
      this.updateListing(id, item.listing.key);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.row !== this.state.row) {
      this.loadContactDetails(this.state.row);
    }
  }

  async updateListing(id, listingId) {
    const endpoint = 'https://dev.newfanglednetworks.com:8080/updateListing';  
    const response = await axios.get(`${ endpoint }?listingId=${ listingId }`);

    this.setState({
      row: {
        ...this.state.row,
        item: {
          ...this.state.row.item,
          [id]: {
            ...this.state.row.item[id],
            listing: {  
              ...this.state.row.item[id].listing,
              ...response.data
            }
          }
        }
      }
    });
  }    

  loadContactDetails = async (row) => {
    const shipTo = await getContact(row?.ship?.contact);

    if (!shipTo.location) return;

    const location = shipTo?.location[row?.ship?.location] || {};
    this.setState({ shipTo: { ...row.ship, ...location } });
  } 

  render() {
    const { row, shipTo = {} } = this.state;
    
    return (
      <form autoComplete="off">
        {this.renderModal({ centered: true, dialogClassName: 'modal-sub' })}
        
        <Container fluid>
          <Row className="mb-3">
            <Col sm={12}>
              <div className="text-center">
                {'# '}
                <strong>{row.number}</strong>
                <ClipboardCopy copy={row.number} />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={8}>
              <h5>Ship To Address:</h5>
              <div className="text-muted">
                {shipTo.residential === false ? <FaBuilding /> : <FaHome />}
                {' '}
                {shipTo.company && `${shipTo.company}`}
                {shipTo.company && <br />}
                {shipTo.person && `${shipTo.person}`}
                {shipTo.streetOne && <><br />{shipTo.streetOne}</>}
                {shipTo.streetTwo && <><br />{shipTo.streetTwo}</>}
                {shipTo.streetThree && <><br />{shipTo.streetThree}</>}
                <br />
                {shipTo.city}, {shipTo.state} {shipTo.zip}
                {shipTo.country !== 'US' && <> {shipTo.country}</>}
              </div>
            </Col>
            <Col sm={4}>
            </Col>
          </Row>

          {Object.entries(row.item).map(([id, item]) => (
            <Row key={id}>
              <Col sm={2}>
                <Image 
                  fluid 
                  src={ item?.product?.image?.[0] } 
                  onClick={() => this.showImages(item?.product?.image)} />
              </Col>
              <Col sm={4} className="d-flex align-items-center">
                <div>
                  <div>
                    <span 
                      className="badge badge-secondary mr-1 rounded-circle d-inline-flex align-items-center justify-content-center" 
                      style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                      onClick={() => this.openStockCommitEditModal(row.item[id].product.key)}
                    >
                      <span className="small font-weight-bold"> 
                        {row.item[id].quantity}
                      </span>
                    </span>
                    {row.item[id]?.product?.vendor && (
                      <span onClick={() => this.openProductEditModal(row.item[id].product.key)} style={{ cursor: 'pointer' }}>
                        {row.item[id].product.vendor}{' '}
                      </span>
                    )}                  
                    {row.item[id]?.product?.model && (
                      <span onClick={() => this.openProductEditModal(row.item[id].product.key)} style={{ cursor: 'pointer' }}>
                        {row.item[id].product.model}{' '}
                      </span>
                    )}
                  </div>
                  <div className="small text-muted">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-title-${id}`}>
                          {row.item[id]?.product?.title}
                        </Tooltip>
                      }
                    >
                      <span>
                        {row.item[id]?.product?.title && row.item[id]?.product?.title.length > 30
                          ? `${row.item[id]?.product?.title.substring(0, 30)}...`
                          : row.item[id]?.product?.title}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="small text-muted">
                    {row.item[id]?.listing?.condition && (
                      <span className="badge badge-warning mr-2">
                        <span className="small font-weight-bold">
                          {row.item[id]?.listing?.condition?.toUpperCase()}
                        </span>
                      </span>
                    )}
                    <span className="mr-2">
                      <span className="text-success font-weight-bold"> 
                        {row.item[id].quantity === 1 && `$${parseFloat(row.item[id].price)?.toFixed(2) || 'N/A'}`}
                        {row.item[id].quantity > 1 && `$${(row.item[id].quantity * (parseFloat(row.item[id].price)))?.toFixed(2)}`}
                      </span>
                      <span className="text-muted">{row.item[id].quantity > 1 && ` / `}</span>
                      <span className="text-primary">
                        {row.item[id].quantity > 1 && `$${parseFloat(row.item[id].price)?.toFixed(2) } ea`}
                      </span>
                    </span>
                  </div>
                  <div className="small text-muted">
                    {row.item[id].upc && (
                      <span>
                        {' UPC # '}
                        {row.item[id].upc}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={2}>
                <Image 
                  fluid 
                  src={ item?.listing?.image?.[0] } 
                  onClick={() => this.showImages(item?.listing?.image)} />
              </Col>
              <Col sm={4} className="d-flex align-items-center">
                <div className="mb-2">
                  <div>
                    <span onClick={() => this.openListingEditModal(row.item[id]?.listing?.key)} style={{ cursor: 'pointer' }}>
                      {getChannelIcon(row.channel)}
                    </span>
                    {row.item[id]?.listing?.channelBrand && (
                      <span onClick={() => this.openListingEditModal(row.item[id]?.listing?.key)} style={{ cursor: 'pointer' }}>
                        {row.item[id]?.listing?.channelBrand}{' '}
                      </span>
                    )}                  
                    {row.item[id]?.listing?.channelModel && (
                      <span onClick={() => this.openListingEditModal(row.item[id]?.listing?.key)} style={{ cursor: 'pointer' }}>
                        {row.item[id]?.listing?.channelModel}{' '}
                      </span>
                    )}
                  </div>
                  <div className="small text-muted">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-title-${id}`}>
                          {row.item[id].listing.title}
                        </Tooltip>
                      }
                    >
                      <span>
                        {row.item[id].listing.title && row.item[id].listing.title.length > 30
                          ? `${row.item[id].listing.title.substring(0, 30)}...`
                          : row.item[id].listing.title}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="small text-muted">
                    {row.item[id]?.listing?.channelCondition && (
                      <span className="badge badge-warning mr-2">
                        <span className="small font-weight-bold">
                          {row.item[id]?.listing?.channelCondition?.toUpperCase()}
                        </span>
                      </span>
                    )}
                    
                    <span className="mr-2">
                      <span className="text-success font-weight-bold me-3"> 
                        {row.item[id].quantity === 1 && `$${row.item[id]?.listing?.channelPrice?.toFixed(2)}`}
                        {row.item[id].quantity > 1 && `$${(row.item[id].quantity * (row.item[id]?.listing?.channelPrice)).toFixed(2)}`}
                      </span>
                      <span className="text-muted">{row.item[id].quantity > 1 && ` / `}</span>
                      <span className="text-primary">
                        {row.item[id].quantity > 1 && `$${row.item[id]?.listing?.channelPrice?.toFixed(2) } ea`}
                      </span>
                    </span>                    
                  </div>
                  <div className="small text-muted">
                    {row.item[id]?.listing?.channelGTIN?.upc && (
                      <span>
                        {' UPC # '}
                        {row.item[id].listing.channelGTIN.upc}
                      </span>
                    )}
                  </div>
                </div>
              </Col>

            </Row>
          ))}

        </Container>
      </form>
    );
  }
}

export { SaleEdit };
