import React, { useState, useCallback } from 'react';
import NotificationContext from './NotificationContext';

/**
 * Provider component for notifications system
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components
 */
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  /**
   * Add a new notification
   * @param {string} message - Notification message
   * @param {string} [type='info'] - Notification type
   * @param {string} [headline=''] - Optional headline
   */
  const notify = useCallback((message, type = 'info', headline = '') => {
    setNotifications(current => [
      ...current,
      { 
        id: new Date().getTime(), 
        message, 
        type, 
        headline 
      }
    ]);
  }, []);

  /**
   * Remove a notification
   * @param {import('./NotificationContext').Notification} notification - Notification to remove
   */
  const dismiss = useCallback((notification) => {
    setNotifications(current => 
      current.filter(n => n.id !== notification.id)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        dismiss,
        notify,
        list: notifications
      }}>
      {children}
    </NotificationContext.Provider>
  );
};
