import axios from 'axios';

export const batchAddTag = {
  label: 'Add Tag',
  fn: (tag, data, path) => {
    data.tags = data.tags || [];
    
    if (data.tags.indexOf(tag) < 0) {
      data.tags.push(tag);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  },
  input: {
    type: 'text',
    placeholder: 'Tag',
  }
}

export const batchRemoveTag = {
  label: 'Remove Tag',
  fn: (tag, data, path) => {
    data.tags = data.tags || [];
    const tagIndex = data.tags.indexOf(tag);

    if (tagIndex >= 0) {
      data.tags.splice(tagIndex, 1);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  },
  input: {
    type: 'text',
    placeholder: 'Tag',
  }
}

export const batchSetVendor = {
  label: 'Set Vendor',
  fn: (vendor, data, path) => {
    return { data: { [`${path}/vendor`]: vendor } };
  },
  input: {
    type: 'text',
    placeholder: 'Vendor',
  }
}

export const batchIdentifyPurchase = {
  label: 'Identify Purchase (AI)',
  fn: async (_, data, path) => {
    const endpoint = 'https://dev.newfanglednetworks.com:8080/identifyPurchase';  
    const response = await axios.get(`${ endpoint }?id=${ data.key }`);

    console.log(response.data);

    if (response.data?.error) {
      console.log(new Date(), 'identifyPurchase ERR', response.data.error);
      throw new Error(response.data.error);
    }
  }
}

