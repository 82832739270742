import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { formatDate } from './AllThing';
import { getPath } from './fire';

const Tracking = props => {
  let tracking = props.tracking;
  let updates = null;
  
  if (tracking.progress) {
    updates = tracking.progress.map((progress, key) => {
      return (
        <tr key={key}>
          <td>{formatDate(progress.timestamp, 'M/D/YY LT')}</td>
          <td>{progress.status}</td>
          <td>{progress.location}</td>
          <td>{progress.message}</td>
        </tr>
      );
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col sm={4}>
          <div><strong className="text-primary">Carrier:</strong> {tracking.carrier.toUpperCase()}</div>
        </Col>
        <Col sm={4}>
          <div><strong className="text-primary">Service:</strong> {tracking.service}</div>
        </Col>
        <Col sm={4}>
          <div><strong className="text-primary">Tracking:</strong> {tracking.tracking}</div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={12}>
          <div style={{ marginBottom: '1em' }}>
            <strong className="text-primary">Shipment Progress</strong>&nbsp;
            <small className="text-muted">( Expected {formatDate(tracking.expected, 'M/D/YY')} )</small>
          </div>
          <Table striped bordered condensed="true" hover>
            <thead>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Location</th>
              <th>Message</th>
            </tr>
            </thead>
            <tbody>{updates}</tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export { Tracking };
