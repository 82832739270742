import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { QuickSearch } from './QuickSearch';

const QuickEdit = (props) => {
  const [edit, setEdit] = useState(false);
  const [options, setOptions] = useState(props.options);
  const [value, setValue] = useState(props.value || '');
  const [search, setSearch] = useState('');
  
  const lastEvent = useRef({ target: { name: props.name, value: '' } });
  const searchRef = useRef();
  const editRef = useRef();
  
  // Autofocus form control when in edit mode
  useEffect(() => {
    if (options && options.length && options.length > 10) {
      searchRef.current?.focus();
    } else {
      editRef.current?.focus();
    }
  }, [edit, options]);
  
  // Because we want to consider two elements together as a focus / blur "unit",
  // we need to use setTimeout to consider whether focus was changed from one
  // to the other.  Changing focus inherently means one element is unfocused
  // *before* another is focused (though near instantaneously).
  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== searchRef.current
        && document.activeElement !== editRef.current) endEdit();
    }, 10);
  };
  
  const handleChange = (event) => {
    if (event.target && event.target.value && event.target.value === '---') {
      return;
    }
    
    // If this is a select or quicksearch control, end editing once a value 
    // has been selected
    if (props.type === 'quicksearch' 
      || (props.as && props.as === 'select')) {
        endEdit();
    }
  
    if (event.target) {
      const { value } = event.target;
      const charCode = value.charCodeAt(value.length - 1);

      // Don't trigger onChange events for text inputs when the last character
      // is an Enter or Tab keypress
      if (props.type === 'text' && (charCode === 13 || charCode === 0)) {
        return;
      }
    }
    
    lastEvent.current = event;
    
    if (props.onChange) {
      props.onChange(event);
    } else {
      setValue(event.target.value);
    }
  };
  
  const handleKeyPress = (event) => {
    if (props.as === 'textarea') return;
    if (event.charCode === 13 || event.charCode === 9) endEdit();
  };
  
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  
  const startEdit = () => {
    setEdit(true);
  };

  const endEdit = () => {
    if (edit) {
      setEdit(false);
      props.onEndEdit && props.onEndEdit(lastEvent.current);
    }
  };
  
  const { 
    __proto__, 
    children, 
    onChange, 
    type, 
    className,
    options: opts, 
    nowrap: nowrapProp,
    ...restProps } = props;

  const controlStyle = { minHeight: 0 };

  const editStyle = {
    display: 'inline-block',
    position: 'absolute',
    top: '-0.7em',
    zIndex: 1,
    padding: '0.5em',
    background: '#eee',
    borderRadius: '5px'
  };

  const nowrap = props.as === 'textarea' || 'wrap' in props ? false : true;
  
  const searchLower = search && search.toLowerCase();
  const optionsList = [];
  
  // Smart formatting of options parameter.
  if (props.options) {
    // Arrays of objects are assigned as-is and expected to contain 'key' and
    // 'value' parameters.  Arrays of singletons are mapped to an array
    // of objects where the singleton value is assigned to both 'key' and
    // 'value'.
    if (typeof props.options === 'object' && props.options.length) {
      if (typeof props.options[0] === 'object') {
        optionsList.push(...props.options);
      } else {
        optionsList.push(...props.options.map(key => ({ key, value: key })));
      }
      
    // Objects are mapped to an array of objects having 'key' and 'value' 
    // parameters mapped to the objects keys and values.  If an object value
    // is itself an object, we attempt to assign that child objects 'value'
    // parameter.  Otherwise, the parent object's key is assigned to both
    // 'key' and 'value'.
    } else if (typeof props.options === 'object') {
      Object.keys(props.options).forEach(key => {
        if (typeof props.options[key] === 'object') {
          if ('value' in props.options[key]) {
            optionsList.push({ key, value: props.options[key].value });
          } else {
            optionsList.push({ key, value: key });
          }
        } else {
          optionsList.push({ key, value: props.options[key] });          
        }
      });
    }
  }
  
  // Filter options list to include only matching search terms if provided
  const optionsShow = optionsList.filter(option => 
    !searchLower || !searchLower.length
    || (option.value && option.value.toLowerCase().indexOf(searchLower) > -1)
  );
  
  if (type === 'text') restProps.as = 'input';
  
  // If the component is being used as a controlled component, we don't want
  // to set the value from state.  Otherwise, we do.
  const valueProps = props.onChange ? {} : { value };

  return (
    <span
      style={{
        position: 'relative',
        ...(nowrap && { whiteSpace: 'nowrap' }),
      }}
      className={className}
      key={restProps['name']}
    >
      <span style={edit ? editStyle : { display: 'none' }}>
        {props.as && props.as === 'select' &&
          <span>
            {optionsList.length && optionsList.length > 10 &&
              <Form.Control
                type="text"
                size={restProps.size || 'sm'}
                htmlSize={restProps.htmlSize}
                className="quickedit-select-search"
                style={controlStyle}
                onBlur={handleBlur}
                onChange={handleSearch}
                ref={searchRef} />
            }
            <Form.Control 
              {...restProps}
              className="quickedit-select"
              style={controlStyle}
              onBlur={handleBlur}
              onChange={handleChange}
              ref={editRef} >
                <option>---</option>
                {optionsShow.map(option =>
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                )}
            </Form.Control>
          </span>
        }
        
        {props.as && props.as !== 'select' &&
          <Form.Control 
            {...restProps}
            {...valueProps}
            style={controlStyle}
            onBlur={endEdit}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            ref={editRef} />
        }
        
        {props.type && props.type === 'quicksearch' &&
          <QuickSearch 
            {...restProps} 
            style={controlStyle}
            onBlur={endEdit}
            onChange={handleChange}
            focus={editRef} />
        }
        
      </span>
      <span onClick={startEdit}>
        {props.children}
      </span>
    </span>
  );
};

export { QuickEdit };
