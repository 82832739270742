import React from 'react';
import axios from 'axios';
import { getPath } from './fire';
import { RowComponent, getProduct, formatDate, calculateAge, getChannelIcon, getConditionVariant } from './AllThing';
import { OverlayTrigger, Popover, Image, Container, Row, Col, Badge, Form } from 'react-bootstrap';
import { FaDolly, FaRegFile, FaBarcode, FaMagic, FaTruck, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { FaArrowCircleRight, FaArrowCircleDown, FaHashtag } from 'react-icons/fa';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { LuLayers } from 'react-icons/lu';
import { IoPerson } from 'react-icons/io5'; 
import { BsCart4 } from 'react-icons/bs';
import { RiChat4Line, RiChatCheckLine } from 'react-icons/ri';
import { TrackingGlyph } from './TrackingGlyph';
import { MoreInfo } from './MoreInfo';
import ClipboardCopy from './ClipboardCopy';
import TrackingIcon from './TrackingIcon';

class PurchaseRow extends RowComponent {

  constructor(props) {
    super(props);
    
    this.state = {
      ...this.state,
      showMagicIcon: false
    };
    
    this.identifyPurchase = this.identifyPurchase.bind(this);
    
    this.onData(row => {
      row.manifest && row.manifest.map((manifest, idx) => 
        manifest.pid && this.updateProduct(row, manifest.pid, idx)
      );
    });
  }
  
  identifyPurchase(id) {
    const endpoint = 'https://dev.newfanglednetworks.com:8080/identifyPurchase';  
    axios.get(`${ endpoint }?id=${ id }`);
  }

  // Load product data for the given pid and merge it with the manifest entry
  // located at state.row.manifest[idx]
  async updateProduct(row, pid, idx) {  
    const product = await getPath(`product/${ pid }`);

    // Add a duplicate flag
    if (product?.title?.match(/^DUPLICATE.*/gi)) {
      product.duplicate = true;
    }
    
    this.setState({ ...this.state, row: { ...row, manifest: 
      row.manifest.map((m, i) => i === idx ? { ...product, ...m } : m)
    }});
  }  
  
  render() {
    const row = this.state.row;
    const { bulkEdit, handleCheck, isChecked } = this.props;
    row.history = row.history || {};
    
    // Issue with eBay import leaves empty notes with double single quotes
    if (row.notes === "''") row.notes = "";
    
    return (
      <Container fluid className="product-row py-2 border-bottom">
        <Row className="align-items-center">
          <Col xs={12} md={5}>
            <div>
              <div 
                className="clickable text-truncate mb-1 d-block d-md-none" 
                onClick={() => this.props.editPurchase(row)}
              >
                {getChannelIcon(row.source || 'eBay')}
                {' '}
                {row.title}
              </div>
              <div
                className="d-flex align-items-center justify-content-center float-left mr-3"
                style={{ 
                  width: window.innerWidth >= 768 ? '75px' : '50px',
                  height: window.innerWidth >= 768 ? '75px' : '50px'
                }}
              >
                {bulkEdit && (
                    <Form.Check
                      checked={isChecked(row.key)}
                      type="checkbox"
                      onChange={() => handleCheck(row.key)}
                    />
                )}
                <Image
                  className="clickable"
                  src={row?.images?.[0]}
                  fluid
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%'
                  }}
                  onClick={() => this.props.showImages(row.images)}
                />
              </div>
              <div 
                className="clickable text-truncate mb-1 d-none d-md-block" 
                onClick={() => this.props.editPurchase(row)}
              >
                {getChannelIcon(row.source || 'eBay')}
                {' '}
                {row.title}
              </div>
              <div>
                {row.history.purchased && 
                  <small className="text-muted mr-2">
                    <MdOutlineCalendarToday className="mr-1"/>{calculateAge(row.history.purchased)}
                  </small>
                }
                {row.quantity && 
                  <small className="text-muted mr-2">
                    <LuLayers className="mr-1"/>{row.quantity}
                  </small>
                }
                {row.price && 
                  <small className="text-muted mr-2">
                    <AiOutlineDollarCircle  className="mr-1"/>{row.price}
                  </small>
                }
                {row.status && 
                  <small className="text-muted mr-3">
                    <TrackingGlyph 
                      className="mr-1"
                      tracking={row.tracking} 
                      showModal={this.props.showModal} />
                    <OverlayTrigger 
                      trigger="click" 
                      rootClose 
                      placement="right" 
                      overlay={purchaseHistory(row.history)}>
                      <span>{row.status.charAt(0).toUpperCase() + row.status.slice(1)}</span>
                    </OverlayTrigger>
                  </small>
                }
              </div>
              <div>
                {row.from && 
                  <small className="text-muted mr-2">
                    <IoPerson className="mr-1"/>{row.from}
                  </small>
                }
                { row.tracking && 
                  <span className="text-muted mr-2">
                    <TrackingIcon tracking={row.tracking} size={16} showModal={this.props.showModal} />
                  </span>
                }
              </div>
            </div>
          </Col>
          <Col xs={12} md={1} className="d-flex align-items-center justify-content-center">
            <div 
              className="d-none d-md-block text-dimmer clickable" 
              onClick={() => this.identifyPurchase(row.key)}
            >
              {this.state.showMagicIcon ? (
                <FaMagic 
                  className="mr-1"
                  onMouseOut={() => this.setState({ showMagicIcon: false })}
                />
              ) : (
                <FaArrowCircleRight 
                  className="mr-1"
                  onMouseOver={() => this.setState({ showMagicIcon: true })}
                />
              )}
            </div>
            <div className="d-md-none text-dimmer clickable" onClick={() => this.identifyPurchase(row.key)}>
              <FaArrowCircleDown className="mr-1 my-2"/> 
            </div>
          </Col>
          <Col xs={12} md={5}>  
            <div>         
              <div className="d-flex align-items-center justify-content-center float-left mr-3"
                style={{ 
                  width: window.innerWidth >= 768 ? '75px' : '50px', 
                  height: window.innerWidth >= 768 ? '75px' : '50px' 
                }}
              >
                { row?.manifest?.filter(m => m?.image?.length).map((man, idx) =>
                  <Image
                    key={idx}
                    className="clickable"
                    src={man?.image?.[0]}
                    fluid
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%'
                    }}
                    onClick={() => this.props.showImages(man.image)}
                  />
                )}
              </div>

              { row?.manifest?.filter(m => m?.model?.length).map((man, idx) =>
                <div key={ idx } className="align-top">
                  <small className="text-muted">
                    { man.type === 'order' && (
                      <BsCart4 className="mr-2" />
                    )}
                    { man.condition === 'return' && !man.return && (
                      <MoreInfo header="Return Reason" body={man.returnReason}>
                        <RiChat4Line className="mr-2" />
                      </MoreInfo>
                    )}
                    { man.condition === 'returnRequested' && (
                      <RiChatCheckLine className="mr-2" />
                    )}
                    { man.return && (
                      <MoreInfo header="Return Tracking" body={man.return}>
                        <FaTruck className="mr-2" />
                      </MoreInfo>
                    )}
                    { man.type === 'receipt' && !man.condition.startsWith('return') && (
                      <FaDolly className="mr-2" />
                    )}
                    { man.type === 'complete' && !man.condition.startsWith('return') && (
                      <FaCheck className="mr-2" />
                    )}

                    <span className="text-muted mr-2">
                      <strong>{man.quantity}</strong>
                    </span>

                    <Badge 
                      variant={getConditionVariant(man.condition)} 
                      className={`mr-1 badge-outline-${getConditionVariant(man.condition)}`}
                    >
                      { man?.condition.toUpperCase() }
                    </Badge>

                    <span className="model mr-2">
                      <span className="clickable" onClick={() => this.props.editPurchase(row)}>
                        { man.vendor } <strong>{ man.model }</strong>
                      </span>                      
                    </span>

                    {man.partNumber && (
                      <small 
                        className="clickable mr-2 d-none d-md-inline" 
                        onClick={() => this.props.editPurchase(row)}
                      >
                        <FaHashtag className="mr-1" />
                        <span>{ man.partNumber }</span>
                      </small>
                    )}

                    { man.type === 'order' &&                  
                      <small className="text-success mr-2 d-none d-md-inline">
                        ${parseFloat(man.price).toFixed(2)} ea
                      </small>
                    }
                    
                    { man.type === 'order' && !man.duplicate
                      && !Object.keys(man.listing || { }).length && 
                      <MoreInfo body="No Listings">
                        <small className="text-danger clickable mr-2">
                          <FaExclamationTriangle />
                        </small>          
                      </MoreInfo>
                    }

                    { (man.type === 'receipt' || man.type === 'complete') && man.notes && 
                      <MoreInfo header="Receiving Notes" body={ man.notes }>
                        <FaRegFile className="text-info" /> 
                      </MoreInfo>
                    }

                    { (man.type === 'receipt' || man.type === 'complete') && man.serials && 
                      <MoreInfo header="Serials" body={ man.serials }>
                        <FaBarcode className="text-info" /> 
                      </MoreInfo>
                    }
                  </small>
                </div>
              )}                          
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const purchaseHistory = (history) => (
  <Popover id="popover-trigger-click-root-close " title="Purchase History" style={{padding: '0.5em'}}>
    {history.purchased && <div><strong>Purchased:</strong> {formatDate(history.purchased, "M/D/YY")}</div>}
    {history.paid && <div><strong>Paid:</strong> {formatDate(history.paid, "M/D/YY")}</div>}
    {history.cancelled && <div><strong>Cancelled:</strong> {formatDate(history.cancelled, "M/D/YY")}</div>}
    {history.shipped && <div><strong>Shipped:</strong> {formatDate(history.shipped, "M/D/YY")}</div>}
    {history.delivered && <div><strong>Delivered:</strong> {formatDate(history.delivered, "M/D/YY")}</div>}
    {history.received && <div><strong>Received:</strong> {formatDate(history.received, "M/D/YY")}</div>}
    {history.returned && <div><strong>Returned:</strong> {history.returned}</div>}
    {history.refunded && <div><strong>Refunded:</strong> {formatDate(history.refunded, "M/D/YY")}</div>}
  </Popover>
)

export { PurchaseRow };
