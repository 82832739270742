import React from 'react';
import { FaUsps, FaFedex, FaUps, FaDhl } from 'react-icons/fa';
import { SiAircanada } from 'react-icons/si';
import ShipmentTracking from './ShipmentTracking';

const getCarrierIcon = (tracking, size = 24) => {
  if (!tracking) return null;
  
  // Remove spaces and convert to uppercase
  const cleanTracking = tracking.replace(/\s+/g, '').toUpperCase();
  
  // USPS patterns
  if (/^94|^92|^93|^95|^CP|^EC|^RA|^LC|^LJ|^UA/.test(cleanTracking)) {
    return FaUsps;
  }
  
  // FedEx patterns
  if (/^7\d{19}$|^(\d{12}|\d{15})$|^(\d{14})$|^(\d{22})$/.test(cleanTracking)) {
    return FaFedex;
  }
  
  // UPS patterns
  if (/^1Z[A-Z0-9]{16}$|^T\d{10}$|^[0-9]{12}$/.test(cleanTracking)) {
    return FaUps;
  }
  
  // DHL patterns
  if (/^\d{10}$|^JD\d{18}$/.test(cleanTracking)) {
    return FaDhl;
  }
  
  // Canada Post patterns
  if (/^[A-Z]{2}\d{9}CA$|^\d{16}$/.test(cleanTracking)) {
    return SiAircanada;
  }
  
  return null;
};

const TrackingIcon = ({ tracking, iconOnly = false, showModal = false, className = '', size = 24 }) => {
  const IconComponent = getCarrierIcon(tracking, size);
  
  const showShipmentTracking = () => {
    if (showModal) {
      showModal('Shipment Tracking', <ShipmentTracking tracking={tracking} />);
    }
  }
  
  if (!IconComponent) return null;
  
  return (
    <span 
      onClick={() => showShipmentTracking()}
      className={`${className} ${showModal ? 'clickable' : ''}`}
    >
      <IconComponent size={size} className={`mr-1`} />
      {!iconOnly && <small>{tracking}</small>}
    </span>
  );
};

export default TrackingIcon;