import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getPath, setPath, watchPath } from './fire';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { IoNotifications } from 'react-icons/io5';
import './Chat.css';

const Chat = ({ currentUser }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [conversations, setConversations] = useState({});
  const conversationsRef = useRef({});
  const isExpandedRef = useRef(false);
  const selectedUserRef = useRef(null);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(new Audio('/sounds/bottleOpen.mp3'));
  const notificationTimeoutRef = useRef(null);
  const broadcastChannelRef = useRef(null);

  // Update notification permission check - simplified
  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

  // Initialize broadcast channel
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      broadcastChannelRef.current = new BroadcastChannel('chat-read-status');
      
      broadcastChannelRef.current.onmessage = (event) => {
        const { userId, timestamps } = event.data;
        
        // Update local state to mark messages as read
        setConversations(prev => {
          if (!prev[userId]) return prev;
          
          const updated = { ...prev };
          timestamps.forEach(timestamp => {
            if (updated[userId][timestamp]) {
              updated[userId][timestamp] = { ...updated[userId][timestamp], read: true };
            }
          });
          
          return updated;
        });
      };
    }

    return () => {
      broadcastChannelRef.current?.close();
    };
  }, []);

  // Add function to mark messages as read
  const markMessagesAsRead = useCallback(async (userId) => {
    if (!conversations[userId]) return;

    const unreadMessages = Object.entries(conversations[userId])
      .filter(([timestamp, message]) => 
        message.userId === userId && !message.read
      );

    if (unreadMessages.length === 0) return;

    const timestamps = [];
    
    // Update each message individually
    for (const [timestamp, message] of unreadMessages) {
      // Create the updated message object
      const updatedMessage = {
        ...message,
        read: Date.now()
      };

      // Update in both users' chat paths - the reader and the sender
      await Promise.all([
        // Update in current user's chat (reader)
        setPath(`chat/${currentUser.uid}/${userId}/${timestamp}`, updatedMessage),
        // Update in sender's chat
        setPath(`chat/${userId}/${currentUser.uid}/${timestamp}`, updatedMessage)
      ]);
      
      timestamps.push(timestamp);
    }

    // Update local state
    setConversations(prev => {
      if (!prev[userId]) return prev;
      
      const updated = { ...prev };
      timestamps.forEach(timestamp => {
        if (updated[userId][timestamp]) {
          updated[userId][timestamp] = { ...updated[userId][timestamp], read: true };
        }
      });
      
      return updated;
    });

    // Broadcast to other tabs
    broadcastChannelRef.current?.postMessage({
      userId,
      timestamps
    });
  }, [conversations, currentUser.uid]);

  // Load user's conversations
  useEffect(() => {
    const loadUsers = async () => {
      const { key, ...users } = await getPath('/user');
      return Object.entries(users || {})
        .filter(([key, value]) => value.chat === true && key !== currentUser.uid)
        .map(([key, value]) => ({ uid: key, ...value }));
    };

    const watchMessages = async () => {
      const users = await loadUsers();
      setAvailableUsers(users);

      watchPath(`/chat/${currentUser.uid}`, ({key, ...conversationUpdates}) => {
        let notifyMessages = [];
        let lastMessageTimestamp = null;
        let lastMessageUser = null;

        Object.entries(conversationUpdates || {}).forEach(([uid, conversation]) => {
          Object.entries(conversation).forEach(([timestamp, message]) => {
            // Update the last message timestamp and user
            if (lastMessageTimestamp === null || timestamp > lastMessageTimestamp) {
              lastMessageTimestamp = timestamp;
              lastMessageUser = uid;
            }

            // Only notify if message is not read and meets notification conditions
            if (!conversationsRef.current?.[uid]?.[timestamp] && !message.read) {
              if (currentUser.uid !== uid && (
                selectedUserRef.current?.uid !== uid || 
                !document.hasFocus() || 
                !isExpandedRef.current
              )) {
                notifyMessages.push(message);
              }
            }
          });
        });

        // Play sound if there are new messages
        if (notifyMessages.length > 0) {
          audioRef.current.play().catch(e => console.log('Audio play failed:', e));
          //showNotification(notifyMessages);
        }

        // If the selected user is not set, set it to the last user that sent a message
        if (!selectedUserRef.current) {
          setSelectedUser(users.find(u => u.uid === lastMessageUser));
        }

        setConversations(conversationUpdates);        
      });
    };

    watchMessages();
  }, [currentUser.uid]);

  // Simplified to regular function
  const showNotification = (messages) => {
    if (Notification.permission === 'granted' && !document.hasFocus()) {
      // Clear any existing notification timeout
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
      
      // Set a new timeout to show notification after a delay
      notificationTimeoutRef.current = setTimeout(() => {
        // Group messages by user
        const messagesByUser = messages.reduce((acc, msg) => {
          if (!acc[msg.userName]) {
            acc[msg.userName] = [];
          }
          acc[msg.userName].push(msg.text);
          return acc;
        }, {});

        // Create a single notification with grouped messages
        const notificationBody = Object.entries(messagesByUser)
          .map(([userName, texts]) => `${userName}: ${texts.length > 1 ? `(${texts.length} messages)` : texts[0]}`)
          .join('\n');

        new Notification('ALLThing Chat', {
          body: notificationBody,
          icon: '/favicon.ico'
        });
        
        notificationTimeoutRef.current = null;
      }, 1000); // 1 second debounce
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    conversationsRef.current = conversations;
    selectedUserRef.current = selectedUser;
    isExpandedRef.current = isExpanded;
    scrollToBottom();

    // Mark messages as read when chat is expanded or user is changed
    if (isExpanded && selectedUser) {
      markMessagesAsRead(selectedUser.uid);
    }
  }, [conversations, selectedUser, isExpanded, markMessagesAsRead]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() || !selectedUser) return;

    const timestamp = new Date();
    const newMessage = {
      text: message,
      userId: currentUser.uid,
      userName: currentUser.name,
      timestamp: timestamp.toISOString()
    };

    // Save message to both sender's and recipient's chat paths
    await Promise.all([
      setPath(`/chat/${currentUser.uid}/${selectedUser.uid}/${timestamp.getTime()}`, newMessage),
      setPath(`/chat/${selectedUser.uid}/${currentUser.uid}/${timestamp.getTime()}`, newMessage)
    ]);
    
    setMessage('');
  };

  const getUnreadCount = (uid) => {
    if (uid) {
      // Count unread messages for specific user
      return Object.entries(conversations?.[uid] || {})
        .filter(([timestamp, message]) => 
          message.userId !== currentUser.uid && !message.read
        ).length;
    }

    // Count all unread messages across all conversations
    return Object.entries(conversations || {})
      .reduce((total, [userId, messages]) => {
        return total + Object.entries(messages)
          .filter(([timestamp, message]) => 
            message.userId !== currentUser.uid && !message.read
          ).length;
      }, 0);
  };

  return (
    <div className={`chat-container ${isExpanded ? 'expanded' : ''}`}>
      <div className="chat-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="chat-header-content">
          <IoChatbubbleEllipsesOutline className="chat-icon" />
          <h3>Chat {selectedUser ? `- ${selectedUser.name}` : ''}</h3>
          {!isExpanded && getUnreadCount() > 0 && (
            <small className="unread-indicator">
              <IoNotifications className="notification-icon" />
              <span className="unread-count ml-1">{getUnreadCount()}</span>
            </small>
          )}
        </div>
        <button className="toggle-button">
          {isExpanded ? '▼' : '▲'}
        </button>
      </div>
      
      {isExpanded && (
        <>
          <div className="chat-users">
            {getUnreadCount() > 0 && (
              <span className="unread-indicator mr-3">
                <IoNotifications className="notification-icon" />
                <span className="unread-count ml-1">{getUnreadCount()}</span>
              </span>
            )}
            <select 
              value={selectedUser?.uid || ''} 
              onChange={(e) => setSelectedUser(availableUsers.find(u => u.uid === e.target.value))}
            >
              <option value="">Select a user to chat with...</option>
              {availableUsers
                .map(user => (
                  <option key={user.uid} value={user.uid}>
                    {user.name} {getUnreadCount(user.uid) > 0 ? `(${getUnreadCount(user.uid)} unread)` : ''}
                  </option>
                ))
              }
            </select>
          </div>

          {selectedUser && (
            <>
              <div className="messages-container">
                {Object.entries(conversations?.[selectedUser.uid] || {}).map(([timestamp, msg]) => (
                  <div 
                    key={timestamp} 
                    className={`message ${msg.userId === currentUser.uid ? 'own-message' : ''}`}
                  >
                    <div className="message-header">
                      <span className="username">{msg.userName}</span>
                      <span className="timestamp">
                        {new Date(msg.timestamp).toLocaleTimeString()}
                      </span>
                    </div>
                    <div className="message-text">{msg.text}</div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              
              <form onSubmit={handleSubmit} className="message-form">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={`Message ${selectedUser.name}...`}
                />
                <button type="submit">Send</button>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Chat; 