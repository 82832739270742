import React from 'react';
import ShipmentTracking from './ShipmentTracking';
import ResultsFilter from './ResultsFilter';
import BatchEdit from './BatchEdit';
import { ResultsComponent, escapeSearch } from './AllThing';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Pager } from './Pager';
import { PurchaseRow } from './PurchaseRow';
import { PurchaseEdit } from './PurchaseEdit';
import ClipboardCopy from './ClipboardCopy';
import { useBatch } from './BatchContext';

import { 
  purchaseStatusFilter, 
  purchaseReturnFilter, 
  purchaseDateFilter 
} from './filterDefinitions';

import { batchIdentifyPurchase } from './batchFunctions';

class Purchases extends ResultsComponent {
  
  constructor(props) {
    super(props);    
    
    this.editPurchase = this.editPurchase.bind(this);
    
    this.search = this.props.search;
    
    // Query transformations.  Reformats tracking number barcode scans so that
    // they appear in the format associated with the orders
    this.search.transformQuery(query => {
      const usps = query.match(/^[0-9]{3}[0-9]{5}([0-9]{22})$/);
      const fedex = query.match(/^[0-9]{22}([0-9]{12})$/);
      
      if (usps) return usps[1];
      else if (fedex) return fedex[1];
      
      return query;
    });
  }
  
  editPurchase(row = { }) {      
    const receipt = {
      id: row.id || '',
      key: row.key,
      type: 'Purchase',
      source: row.link && row.link.indexOf('ebay') >= 0 ? 'eBay' : '',
      received: row.received || new Date().toJSON(),
      tracking: row.tracking || '',
      images: row.images,
      condition: row.condition,
      buyerNotes: row.buyerNotes,
      notes: row.notes || '',
      description: row.description || '',
      manifest: row.manifest || [{ 
        type: 'order',
        quantity: row.quantity || 1, 
        lots: row.quantity || 1,
        lotSize: 1,
        condition: 'likenew',
        lotPrice: row.price || 0.00,
        price: row.price || 0.00,
        notes: row.notes || ''
      }]
    };
    
    const modalContent = (
      <PurchaseEdit 
        row={ receipt } 
        title={ row.title }
        search={ this.props.search }
        index={ this.props.index }
        addProduct={ this.addProduct }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons } />
    );

    const modalTitle = (
      <>
        Edit Purchase { row.key && <ClipboardCopy copy={row.key} /> }
      </>
    );
    
    this.showModal(modalTitle, modalContent);
  }

  render() {
    const search = this.props.search;
    let purchaseData = null;
    
    if (search.location === window.location.href) {
      purchaseData = this.props.results.map(row =>
        <PurchaseRow 
          key={ row.key } 
          row={ row } 
          index={ this.index }
          showModal={ this.showModal }
          showImages={ this.showImages }
          handleCheck={this.handleCheck}
          isChecked={this.isChecked}
          bulkEdit={this.state.bulkEdit}
          editPurchase={ this.editPurchase } />
      );
    } else {
      purchaseData = <Col xs={12}>Loading Data ...</Col>;
    }
  
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col>
              <ResultsFilter 
                filterDefinitions={{ purchaseStatusFilter, purchaseReturnFilter, purchaseDateFilter }} 
                onFilterChange={this.setFilter}
              />
            </Col>
          </Row>
          <Row>
            {this.countChecked() > 0 && (
              <Col sm={3} md={2}>
                <BatchEdit
                  checkedCount={this.countChecked()}
                  totalCount={this.search.count}
                  onCheckAll={this.checkAll}
                  getBatchDataPaths={this.getBatchDataPaths}
                  batchOperations={[ batchIdentifyPurchase ]}
                />
              </Col>
            )}
            <Col sm={this.countChecked() > 0 ? 9 : 12} md={this.countChecked() > 0 ? 10 : 12}>
              <Row>
                <Col>
                  <Badge 
                    pill 
                    variant={this.state.bulkEdit ? "primary" : "secondary"} 
                    className="mr-2 clickable " 
                    onClick={this.toggleBulkEdit}
                  >
                    Bulk Edit
                  </Badge>
                  {this.state.bulkEdit && (
                    <Badge pill variant="secondary" className="mr-2 clickable" onClick={() => this.handleCheck('all')}>
                      Select All
                    </Badge>
                  )}
                  <Badge pill variant="secondary" className="clickable" onClick={() => this.editPurchase()}>
                    Add New Purchase
                  </Badge>
                </Col>
              </Row>
              {purchaseData}
              <Pager
                pageCount={this.search.pages} 
                activePage={this.search.page} 
                onSelect={this.search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Purchases };
