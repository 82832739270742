import React from 'react';
import axios from 'axios';
import { EditComponent, formatListingLinks, getProduct, getProductPrice, getProductStockByType, getPurchaseItem, getTemplates, parseTemplate, removeUndefinedValues, toggleState, updatePath } from './AllThing';
import { FaRegFileAlt, FaRegFile, FaPencilAlt, FaSign, FaStore, FaBoxes, FaLayerGroup, FaFileInvoiceDollar, FaImage, FaImages, FaBarcode, FaProjectDiagram, FaEbay, FaTruck, FaSearch, FaWeight, FaRuler  } from 'react-icons/fa';
import { Alert, Badge, Container, Row, Col, Form, Image, Tabs, Tab } from 'react-bootstrap';
import { Slideshow } from './Slideshow';
import { QuickEdit } from './QuickEdit';
import { ProductEdit } from './ProductEdit';
import { WalmartSchemaForm } from './WalmartSchemaForm.js';
import { parseSchemaArrays } from './schemaUtils.js';
import { SearchListingResults } from './SearchListingResults.js';



class ListingEdit extends EditComponent {
  constructor(props) {
    super(props);
    
    // Set state row defaults
    const row = this.state.row;        

    this.state.product = props.product || { };
    this.state.product.productDims = props?.product?.dimensions;
    this.state.templates = { };
    this.state.aspects = [ ];
    this.state.allImages = [ ];
    
    row.channel = row.channel || '';
    row.channelCondition = row.channelCondition || '';
    row.channelFields = row.channelFields || { };
    row.condition = row.condition || 'new';
    row.enabled = row.enabled || false;
    row.image = row.image || [ ];
    row.lotSize = row.lotSize || 1;
    row.format = row.format || 'FIXED_PRICE';
    row.override = row.override || { price: 0.00 };
    row.title = row.title || this.state.product.title || '';
    row.vendor = row.vendor || this.state.product.vendor || '';

    row.dimensions = this.state.row.dimensions || {
      width: '', depth: '', height: '', weight: ''
    };
    this.allowNewRecord = true;
    this.listings = props.listings || {};
    this.channelErrors = {};
    this.channelFields = {};
    
    // Function bindings
    this.addImage = this.addImage.bind(this);
    this.addProductImages = this.addProductImages.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.getAllImages = this.getAllImages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.resetListing = this.resetListing.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.updateChannel = this.updateChannel.bind(this);
    this.updateListing = this.updateListing.bind(this);
    this.validateListing = this.validateListing.bind(this);
    
    this.toggleState = toggleState.bind(this);
    
    // Channel Map settings for each channel
    this.channelMap = {
      'Walmart': {
        name: 'Walmart',
        categoryShow: true,
        dimensionsShow: true,
        listingShow: true,
        imagesShow: true,
        upcShow: true,
        channelCondition: {
          'New': 'Brand New',
          'Pre-owned: Like New': 'Used / Like New',
          'Pre-owned: Good': 'Used / Good',
          'Pre-owned: Fair': 'Used / Fair'
        },
        defaults: {
          channelCondition: 'New'
        },
        endpoints: {
          base: 'https://dev.newfanglednetworks.com:8080',
          searchCategories: 'searchWalmartCategories',
          searchListings: 'searchWalmartListings',
          getSchema: 'getWalmartSchema'
        },
        validation: {
          channel: { },
          channelCondition: { },
          categoryName: { req: true, min: 1, alt: 'listingName' },
          channelFields: { },
          condition: { },
          image: { type: 'list', min: 1 },
          listingName: { req: true, min: 1, alt: 'categoryName' },
          lotSize: { type: 'quantity', min: 1 },
          model: { min: 1, max: 80 },
          'override.price': { type: 'currency', min: 1 },
          'override.upc': { pattern: '[0-9]{12,14}', req: true, alt: 'upc' },
          'dimensions.weight': { req: true, min: 1, alt: 'productDims.weight'},
          vendor: { min: 1 }
        },
      },
      'eBay': {
        name: 'eBay',
        auctionShow: true,
        categoryShow: true,
        dimensionsShow: true,
        imagesShow: true,
        modelShow: true,
        titleShow: true,
        titleMaxLen: 80,
        stockShow: true,
        upcShow: true,
        previewShow: true,
        returnsShow: true,
        shippingShow: true,
        formats: { 'FIXED_PRICE': 'FIXED PRICE', 'AUCTION': 'AUCTION' },
        channelCondition: {
          'NEW': 'New',
          'NEW_OTHER': 'New / Open Box',
          'USED_VERY_GOOD': 'Used / Very Good',
          'USED_ACCEPTABLE': 'Used / Acceptable',
          'FOR_PARTS_OR_NOT_WORKING': 'For Parts / Not Working'
        },
        returnPolicy: {
          '102751274021': '30-Day Returns',
          '263082578021': 'No Returns / Non-DOA'
        },
        shippingPolicy: {
          '102751277021': 'Free Shipping',
          '293521252021': 'Calculated Shipping'
        },
        defaults: {
          channelCondition: 'NEW',
          returnPolicy: '102751274021',
          shippingPolicy: '102751277021',
          categoryId: '51268',
          categoryName: 'Network Switches',
          channelFields: { Brand: 'Cisco', Type: 'Ethernet Switch' }
        },
        validation: {
          channel: { },
          channelCondition: { },
          categoryId: { },
          channelFields: { },
          condition: { },
          image: { type: 'list', min: 1 },
          lotSize: { type: 'quantity', min: 1 },
          model: { min: 1, max: 80 },
          title: { min: 1, max: 80 },
          'override.price': { type: 'currency', min: 1 },
          'override.upc': { pattern: '[0-9]{12}', req: true, alt: 'upc' },
          vendor: { min: 1 }
        },
        endpoints: {
          base: 'https://rest.newfanglednetworks.com/api',
          searchCategories: 'queue',
          getSchema: 'queue'
        }
      },
      'BrokerBin': {
        name: 'BrokerBin',
        conditionShow: true,
        mfgShow: true,
        modelShow: true,
        stockShow: true,
        titleShow: true,
        titleMaxLen: 80,
        formats: { 'FIXED_PRICE': 'FIXED PRICE' },
        channelCondition: {
          'new': 'New Retail',
          'nob': 'New / Open Box',
          'ref': 'Refurbished',
          'used': 'Used'
        },
      },
      'NewEgg': {
        name: 'NewEgg',
        channelIdShow: true,
        formats: { 'FIXED_PRICE': 'FIXED PRICE' }
      }
    };
    
    this.stockCondition = {
      'new': 'New',
      'ref': 'Refurb',
      'use': 'Used'
    }
        
    // Load product if not provided in props
    if (!props.product && row.product) {
      getProduct(row.product).then(snap => {
        const product = { ...snap.val(), key: snap.key };
        
        this.setStateSafe({ 
          product: { ...product, productDims: product.dimensions } 
        });
        this.addProductImages(snap.val());
      });
    }
    
    // Load All Images
    this.getAllImages()
    
    // Load templates
    getTemplates().then(templates => this.setStateSafe({ templates }));
  }
  
  componentDidMount() {  
    const row = this.state.row;
    const channel = this.channelMap[ row.channel ] || { };
    
    // Ensure parent componentDidMount is called for setStateSafe.  This is
    // automatically done in parent's componentDidMount unless we override as
    // we are doing here.  Also must be accounted for if we override
    // componentDidUnmount (false).
    this.setMounted(true);
    
    // Load category aspects for eBay listings where category has been selected
    if ((row.channel === 'eBay' && row.categoryId) 
      || (row.channel === 'Walmart' && row.categoryName)) {
        this.updateCategoryFields(channel, row.categoryName);
    }
    
    this.addProductImages(this.state.product);    
    this.updateChannelDetails();
  }
  
  async componentDidUpdate(prevProps, prevState) {
    const { row, product } = this.state;
    const { row: prevRow, product: prevProduct } = prevState;
    const channel = this.channelMap[ row.channel ] || { };
    
    // Retrieve category aspects (additional required fields) whenever the
    // category is updated
    if (prevState.row.categoryName !== row.categoryName) {
      this.updateCategoryFields(channel, row.categoryName);
    }
    
    // Automatically perform a listing search when channel is changed to 
    // walmart from any other option (or no option at all). OR if a
    // product's UPC is updated
    if (row.channel === 'Walmart' && (prevRow.channel !== row.channel || prevProduct?.upc !== product.upc)) {
      const itemMatch = await this.handleSearch(
        product?.upc, channel, 'searchListings'
      );
      
      itemMatch?.[0] && this.updateListing(itemMatch[0]);  
    }
    
    // Validate
    this.validateListing();
    //this.validateForm(formData, validate, this.channelErrors); 
  }
  
  // @TODO Handled by <Slideshow /> - this should probably save any newly
  // uploaded images to ./imageAll so we don't lose them if they aren't 
  // selected
  addImage(url) {
    this.setStateSafe({ row: {
      ...this.state.row, 
      image: [ ].concat( this.state.row.image, url )
    }});
  }
  
  // @TODO This should be updated to also add in product.imageAll after we 
  // start saving those.  imageAll should contain (a) anything uploaded for
  // this product, and (b) anything ever selected for this product
  addProductImages(product) { 
    this.setStateSafe({ 
      allImages: [ 
        ...this.state.allImages, 
        ...Object.values(product.image || { })
      ]
    });
  }
  
  beforeSave(row) {
    const product = this.state.product;
  
    return new Promise ((res, rej) => {
      const price = getProductPrice(
        product, row.condition, 'Amazon US', this.listings
      );

      // @TODO Condition seems to only be used for BrokerBin, and is a duplicate
      // of variant. May want to go through and cleanup / remove this later.
      // Need to update the BrokerBin CSV generation code in server.js when
      // we do that.
      //row.condition = row.condition.toUpperCase();

      // @TODO Removed this.  I think this is all handled by firebase functions.
      //row.stock = row.stock || getProductStock(product, row.condition);
      
      row.price = parseFloat(
        row.override.price || row.price || price
      ).toFixed(2);
      
      row.created = row.created || new Date().toJSON();
      
      if (Object.keys(this.channelFields).length) {
        row.channelFields = this.channelFields;
      }

      res();
    });
  }
  
  editProduct() {
    const { row, product } = this.state;

    const modalContent = (
      <ProductEdit 
        row={ product } 
        search={ this.props.search }
        index="product"
        hideModal={ this.hideModal }
        onClose={ p => this.setState({ product: { ...p, productDims: p.dimensions } }) }
        setModalButtons={ this.setModalButtons } />
    );
    
    this.showModal(`Edit Product (${row.product})`, modalContent);
  }
  
  getAllImages() {
    const product = this.state.product;
    
    this.search.simpleQuery(
      'order_purchase_item',
      `
        (manifest.model:"${ product.model }") 
      `
    ).then(results => {
      const purchaseIds = [ ];
      const images = [ ];
      
      results.map(result => purchaseIds.push(result.key));
        
      Promise.all(
        purchaseIds.map(id => getPurchaseItem(id))
      )
      .then(snaps => {
        snaps.map(
          snap => images.push( ...Object.values(snap.val().images || { }))
        );
        
        this.setStateSafe({ 
          allImages: [ ...this.state.allImages, ...images ]
        });
      });
      
    });
  }
  
  handleChange(formData) {
    this.channelFields = removeUndefinedValues(formData);
  }
  
  handleErrors(errors) {
    const channelErrors = {};
    
    errors.forEach(error => {
      const { property, message } = error;
      channelErrors[ property ] = channelErrors[ property ] || [];
      channelErrors[ property ].push(message);
    });
    
    this.channelErrors = channelErrors;
    
    this.validateListing();
  }
  
  // @TODO Need more secure api calls
  async handleSearch(query, channel, type, updateOptions) {
    const endpoints = channel?.endpoints || {};
    const endpoint = `${ endpoints.base }/${ endpoints[ type ] }`;
    let categories;
    let response;
    let options = [];

    switch (channel.name) {
      case 'Walmart':
        response = await axios.get(`${ endpoint  }?q=${ query }`);   
        
        switch (type) {
          case 'searchCategories':
            options = response?.data.map(name => ({ name })) || [];
            break;
          case 'searchListings':
            options = response?.data?.items || [];
            break;
        }
      break;
      case 'eBay':
        response = await axios.post(endpoint, {
          pass: 'g!24$al;^?0yw~;*',
          provider: 'eBay',
          service: 'getCategorySuggestions',
          params: { query }
        });
        
        categories = response?.data?.categorySuggestions?.map(
          tree => {
            const { categoryName: name, categoryId: id } = tree?.category;
            const ancestors = tree?.categoryTreeNodeAncestors
              .sort((a, b) => 
                a.categoryTreeNodeLevel - b.categoryTreeNodeLevel
              )
              .map(ancestor => ancestor.categoryName);
            
            options.push({ name, id, ancestors });
          }
        );
        
        break;
      default:
        break;
    }
    
    updateOptions && updateOptions(options);
  }

/*  
    return axios.post('https://rest.newfanglednetworks.com/api/queue', {
      pass: 'g!24$al;^?0yw~;*',
      provider: 'eBay',
      service: 'getCategorySuggestions',
      params: { query: terms }
    })
    .then(res => {
      return res.data && res.data.categorySuggestions
    });
  }
*/
  
  removeImage(index) {
    this.setStateSafe({ row: {
      ...this.state.row,
      image: this.state.row.image.filter((image, idx) => idx !== index)
    }});
  }
  
  resetListing(id) {
    this.setStateSafe({ row: {
      ...this.state.row,
      listingId: undefined, listingName: undefined, channelListing: undefined,
      override: { ...this.state.row.overide, price: 0 }
    }});      
  }
  
  updateCategory(category) {
    const { row, product } = this.state;
    const { image } = product;
    const { id: categoryId = '', name: categoryName } = category;
    const aspects = [ ];
    
    this.setStateSafe({ 
      aspects,
      row: { ...row, image, categoryId, categoryName }
    });
  }
  
  async updateCategoryFields(channel, categoryName) {  
    const endpoints = channel?.endpoints || {};
    const endpoint = `${ endpoints.base }/${ endpoints.getSchema }`;
    let categories;
    let response;
    
    switch (channel.name) {
      case 'Walmart':
        response = await axios.get(`${ endpoint }?q=${ encodeURIComponent(categoryName) }`);
        this.setStateSafe({ schema: response?.data });
      break;
      case 'eBay':
        response = await axios.post(endpoint, {
          pass: 'g!24$al;^?0yw~;*',
          provider: 'eBay',
          service: 'getItemAspectsForCategory',
          params: { id: this.state.row.categoryId }
        });
        
        this.setStateSafe({ aspects: response.data.aspects || [] });
        console.log(response.data.aspects);
      break;
      default:
      break;
    }
  }
  
  updateChannel(event) {
    const { name, value } = event.target;
    const channel = this.channelMap[ value ] || { };
    const defaults = channel.defaults || { };
        
    const updates = {
      ...this.state.row,
      ...defaults
    };
    
    this.setStateSafe({ row: updatePath(name, value, updates) });
  }


  async updateChannelDetails() {
    const listingId = this.state.row.key;
    const endpoint = 'https://dev.newfanglednetworks.com:8080/updateListing';  
    const response = await axios.get(`${ endpoint }?listingId=${ listingId }`);

    //console.log(this.state.row);
    //console.log(response.data);

    this.setState({
      row: {
        ...this.state.row,
        ...response.data
      }
    });
  }   
  
  updateListing(channelListing) {
    const { row, product } = this.state;
    const { image } = product;
    const { 
      itemId: listingId, 
      title: listingName,
      price: { amount: channelPrice }
    } = channelListing;
    
    const price = parseFloat(channelPrice * 0.95).toFixed(2);
    
    console.log(channelListing);
    
    this.setStateSafe({ 
      row: { 
        ...row, 
        listingId, listingName, channelListing, image,
        override: { ...this.state.row.overide, price }
      }
    });
  }
  
  validateListing() {
    const { row, product } = this.state;
    const channel = this.channelMap[ row.channel ] || { };
    const validate = channel.validation || { };
    const formData = { ...product, ...row };

    // Validate fields
    this.validateForm(formData, validate, this.channelErrors); 
  }
  
  
  render() {
    const row = this.state.row;
    const channel = this.channelMap[ row.channel ] || { };       
    const product = this.state.product;
    const model = row.override.model || product.model;
    const titleRemaining = (80 - (row.title && row.title.length) || 0);
    const productPrice = getProductPrice(product, this.listings);
    const errors = this.getValidationErrors();
    
    const template =
      `${ row.channel.toLowerCase() }-` +
      `${ row.channelCondition.toLowerCase().replace('_', '-') }`;
    
    const stock = row.override.stock 
      || getProductStockByType(product, row.condition);
    const lots = parseInt((row.lots || stock || 0) / row.lotSize);
    
    // Generate listing preview
    const preview = parseTemplate(
      this.state.templates[ template ], {
      images: row.image || [ ],
      title: row.title || '',
      notes: row.notes || ''
    });
    
    // Dimensions
    const height = row.dimensions.height || product?.dimensions?.height;
    const width = row.dimensions.width || product?.dimensions?.width;
    const depth = row.dimensions.depth || product?.dimensions?.depth;
    const weight = row.dimensions.weight || product?.dimensions?.weight;
    
    const schema = this.state.schema && parseSchemaArrays(this.state.schema);
    const schemaDefaults = {
      ...product,
      channelCondition: row.channelCondition,
      modelNumber: product.model,
      ...(row?.channelFields || {})
    };

    return (
      <form autoComplete="off">
        { this.renderModal({ centered: true, dialogClassName: 'modal-sub' }) }
        
          { this.hasValidationErrors() && 
            <Alert variant="danger" bg="danger">
              <Alert.Heading>Form Validation Error(s)</Alert.Heading>              
              { Object.keys(errors).map(field => 
                <p key={ field }>
                  <strong>{ field }:</strong> { errors[ field ] }
                </p>
              )}
            </Alert>
          }
          
          { !!Object.keys(row.channelErrors || { }).length &&
            <Alert variant="danger" bg="danger" >
              <Alert.Heading>Channel Listing Error(s)</Alert.Heading>              
              { Object.keys(row.channelErrors || { }).map(idx => 
                <p key={ idx }>{ row.channelErrors[ idx ] }</p>
              )}
            </Alert>
          }

          { !!Object.keys(row.channelMessages || { }).length &&
            <Alert variant="warning" bg="danger">
              <Alert.Heading>Channel Listing Message(s)</Alert.Heading>              
              { Object.keys(row.channelMessages || { }).map(idx => 
                <p key={ idx }>{ row.channelMessages[ idx ] }</p>
              )}
            </Alert>
          }

          <Tabs defaultActiveKey="listingEdit" id="listing-details-edit">

            <Tab eventKey="listingEdit" title="Listing Details">

              <Container fluid>
                <Row><Col sm={12}>
                  { formatListingLinks(row, product) }
                </Col></Row>
                <Row><Col sm={12}>&nbsp;</Col></Row>
                <Row className="manifest-item-row listing-edit">
                  <Col sm={ 6 }>
                    <Slideshow embed maxHeight={ 250 } images={ row.image } show={ this.showImages } />
                  </Col>
                  
                  <Col sm={ 6 }>            

                    <Form.Group>
                      <Form.Label 
                        className="product-title">
                          <Badge 
                            className="clickable"
                            variant={ row.enabled ? 'success' : 'danger' }
                            bg={ row.enabled ? 'success' : 'danger' }
                            onClick={ e => 
                              this.toggleState('row.enabled', [ true, false ]) }>
                              { row.enabled ? 'Enabled' : 'Disabled' }
                              { row.enabled }
                          </Badge>
                          <span 
                            className="clickable" 
                            onClick={ this.editProduct }>
                              { product.title }
                              <FaPencilAlt />
                          </span>
                      </Form.Label>
                    </Form.Group>      

                    <div>
                    
                      <QuickEdit as="select" size="sm" htmlSize={10} nowrap
                        name="channel"
                        options={ Object.keys(this.channelMap).map(channel =>
                          ({ key: channel, value: this.channelMap[ channel ].name })
                        )}
                        onChange={ this.updateChannel }
                        value={ row.channel }>
                          <span className="section">
                            <FaStore />
                            <span>{ row.channel || 'Set Channel' }</span>
                          </span>
                      </QuickEdit>
                                  
                      { row.channel &&
                        <QuickEdit as="select" size="sm" htmlSize={15} nowrap
                          name="channelCondition"
                          options={ channel.channelCondition }
                          onChange={ this.updateRow }
                          value={ row.channelCondition }>
                            <span className="section">
                              <FaSign />
                              <span>{ 
                                (channel.channelCondition 
                                  && channel.channelCondition[ row.channelCondition ])
                                  || 'Condition' }
                              </span>
                            </span>
                        </QuickEdit>
                      }
                                  
                      { channel.shippingShow &&
                        <QuickEdit as="select" size="sm" htmlSize={15} nowrap
                          name="shippingPolicy"
                          options={ channel.shippingPolicy }
                          onChange={ this.updateRow }
                          value={ row.shippingPolicy }>
                            <span className="section">
                              <FaTruck />
                              <span>{ 
                                (channel.shippingPolicy 
                                  && channel.shippingPolicy[ row.shippingPolicy ])
                                  || 'Shipping Policy' }
                              </span>
                            </span>
                        </QuickEdit>
                      }

                      { channel.returnsShow &&
                        <QuickEdit as="select" size="sm" htmlSize={15} nowrap
                          name="returnPolicy"
                          options={ channel.returnPolicy }
                          onChange={ this.updateRow }
                          value={ row.returnPolicy }>
                            <span className="section">
                              <FaTruck />
                              <span>{ 
                                (channel.shippingPolicy 
                                  && channel.returnPolicy[ row.returnPolicy ])
                                  || 'Return Policy' }
                              </span>
                            </span>
                        </QuickEdit>
                      } 
                      
                      { channel.imagesShow &&
                        <span className="section" onClick={ 
                          e => this.showImages(this.state.allImages, {
                            selected: row.image,
                            add: true, 
                            remove: true, next: true, prev: true,
                            select: this.selectImage,
                            buttons: [
                              { value: 'Save', callback: this.selectImages }
                            ]
                          }) 
                        } >
                            <FaImages />
                            <span>Update Images</span>
                        </span>
                      }                                           

                    </div>
                    <div>
                    
                      { channel.listingShow &&
                        <QuickEdit type="quicksearch" size="sm" nowrap
                          name="listingName"
                          placeholder="Search Listings"
                          renderInputValue={ renderInputValue }
                          renderOption={ opt => 
                            <SearchListingResults
                              images={ opt.images }
                              title={ opt.title }
                              vendor={ opt.brand }
                              price={ opt.price }
                              categories={ opt?.properties?.categories }
                              condition={ opt.condition }
                            />
                          }
                          onSearch={ ( q, updateOptions) => 
                            this.handleSearch(q, channel, 'searchListings', updateOptions) 
                          }
                          onChange={ this.updateListing } >
                            <span className="section">                  
                              <FaSearch />
                              <span>{ row.listingId || 'Listing' }</span>
                            </span>
                        </QuickEdit>
                      }
                      
                      { channel.dimensionsShow &&
                        <>
                          <QuickEdit as="input" size="sm" htmlSize={4} nowrap
                            name="dimensions.width"
                            value={ width }
                            onChange={ this.updateRow }>
                              <span className="section narrow">
                                <FaRuler />
                                <span>
                                  { width ? `${width}"` : 'Width (in)' }
                                </span>
                              </span>
                          </QuickEdit>
                          
                          <span className="section-divider narrow">
                            <span>x</span>
                          </span>

                          <QuickEdit as="input" size="sm" htmlSize={4} nowrap
                            name="dimensions.depth"
                            value={ depth }
                            onChange={ this.updateRow }>
                              <span className="section narrow">
                                <span>
                                  { depth ? `${depth}"` : 'Depth (in)' }
                                </span>
                              </span>
                          </QuickEdit>
                          
                          <span className="section-divider narrow">
                            <span>x</span>
                          </span>

                          <QuickEdit as="input" size="sm" htmlSize={4} nowrap
                            name="dimensions.height"
                            value={ height }
                            onChange={ this.updateRow }>
                              <span className="section">
                                <span>
                                  { height ? `${height}"` : 'Height (in)' }
                                </span>
                              </span>
                          </QuickEdit>
                          
                          <QuickEdit as="input" size="sm" htmlSize={4} nowrap
                            name="dimensions.weight"
                            value={ weight }
                            onChange={ this.updateRow }>
                              <span className="section">
                                <FaWeight />              
                                <span>
                                  { weight ? `${weight}"` : 'Add Weight (lbs)' }
                                </span>
                              </span>
                          </QuickEdit>             
                        </>
                      }     

                      { channel.categoryShow && (!row.channelListing || row.channel !== 'Walmart') &&
                        <QuickEdit type="quicksearch" size="sm" nowrap
                          name="categoryName"
                          placeholder="Search Categories"
                          renderInputValue={ renderInputValue }
                          renderOption={ renderCategory }
                          onSearch={ ( q, updateOptions) => 
                            this.handleSearch(q, channel, 'searchCategories', updateOptions) 
                          }
                          onChange={ this.updateCategory } >
                            <span className="section">                  
                              <FaProjectDiagram />
                              <span>{ row.categoryName || 'Category' }</span>
                            </span>
                        </QuickEdit>
                      }
                      

                      
                      { schema &&
                        <WalmartSchemaForm 
                          schema={ schema } 
                          data={ schemaDefaults }
                          onChange={ this.handleChange }
                          onError={ this.handleErrors }
                        /> 
                      }
                      
                    </div>
                    <div>
                      
                      { this.state.aspects.map(aspect =>
                        aspect.aspectConstraint.aspectRequired &&
                        <QuickEdit as="select" size="sm" htmlSize={10} nowrap 
                          key={ aspect.localizedAspectName }
                          name={ `channelFields.${ aspect.localizedAspectName }` }
                          options={ 
                            aspect.aspectValues && aspect.aspectValues.length &&
                            aspect.aspectValues.map(value => value.localizedValue) 
                          }
                          onChange={ this.updateRow }
                          value={ row.channelFields[ aspect.localizedAspectName ] }>
                            <span className="section">
                              <FaEbay />
                              <span>{ 
                                row.channelFields[ aspect.localizedAspectName ]
                                  || aspect.localizedAspectName }
                              </span>
                            </span>
                        </QuickEdit>                           )}

                    </div>
                    
                    { row.channelListing && row.channel === 'Walmart' &&
                      <div className="channel-listing-match">
                        <SearchListingResults
                          channel={ row.channel }
                          showImages={ false }
                          id={ row.listingId }
                          images={ row.channelListing.images }
                          title={ row.channelListing.title }
                          vendor={ row.channelListing.brand }
                          price={ row.channelListing.price }
                          categories={ row.channelListing?.properties?.categories }
                          condition={ row.channelListing.condition }
                          onRemove={ id => this.resetListing(id) }
                        />
                      </div>
                    }
                  </Col>
                </Row>

                <Row className="manifest-item-row">
                  <Col sm={ 2 }>
                    { product.image &&
                      <div>
                        <div className="thumbnail">
                          <Image fluid 
                            src={ product.image && product.image[0] } 
                            onClick={ e => this.showImages(product.image) } />
                        </div>
                      </div>
                    }
                    { !product.image && 
                      <h1 className="text-center"><FaImage /></h1>
                    }
                  </Col>
              

                  <Col sm={ 10 }>
                      
                    <div>
                      { row.channel && row.channel.length &&
                        <Badge 
                          className="clickable"
                          variant={ row.format === 'fixed' ? 'success' : 'info' }
                          bg={ row.format === 'fixed' ? 'success' : 'info' }
                          onClick={ e => 
                            this.toggleState('row.format', channel.formats) }>
                            { channel.formats && channel.formats[ row.format ] }
                        </Badge>
                      }
                  
                      <Badge variant="secondary" bg="secondary">
                        { lots }x LOT{ lots !== 1 ? 'S' : '' }
                      </Badge>
                      
                      <QuickEdit as="select" size="sm" htmlSize={3}
                        name="condition"
                        options={ this.stockCondition }
                        onChange={ this.updateRow }
                        value={ row.condition }>
                          <Badge variant="secondary" bg="secondary">
                            { row.condition.toUpperCase() }
                          </Badge>
                      </QuickEdit>

                      <QuickEdit as="input" size="sm" htmlSize={10} 
                        name="override.vendor"
                        placeholder="Mfg Override" 
                        onChange={ this.updateRow }
                        value={ row.override.vendor }>
                          <span className="purchase-product">
                            { row.override.vendor || product.vendor }
                          </span>
                      </QuickEdit>                  
                      
                      <QuickEdit as="input" size="sm" htmlSize={10} 
                        name="override.model"
                        placeholder="Model Override" 
                        onChange={ this.updateRow }
                        value={ row.override.model }>
                          <span className="purchase-product">
                            { row.override.model || product.model }
                          </span>
                      </QuickEdit>                                  

                      <Badge variant="success" bg="success" className="badge-success-inverted">
                        ${ row.override.price } per LOT
                      </Badge>

                    </div>
                    
                    <div>

                      <QuickEdit as="textarea" size="sm" rows={5} htmlSize={20}
                        name="title"
                        onChange={ this.updateRow }
                        value={ row.title }>
                          <span className="purchase-title section">
                            <FaRegFile />
                            <small className="text-muted">{row.title}</small>            
                          </span>
                      </QuickEdit>   
                    
                    </div>
                    <div>
                    
                      <QuickEdit as="textarea" size="sm" rows={5} htmlSize={20}
                        name="notes"
                        onChange={ this.updateRow }
                        value={ row.notes }>
                          <span className="section">                  
                            <FaRegFileAlt />
                            <small className="text-muted">{row.notes}</small>            
                          </span>
                      </QuickEdit>
                                            
                    </div>
                    
                    
                    <div>
                    
                      <QuickEdit as="input" size="sm" htmlSize={10} 
                        name="override.stock"
                        placeholder="Stock Override" 
                        onChange={ this.updateRow }
                        value={ row.override.stock }>
                          <span className="purchase-quantity section">                 
                            <FaBoxes />
                            <span>
                              { stock }x Unit
                              { stock > 1 ? 's' : '' }
                            </span>
                          </span>
                      </QuickEdit>

                      <QuickEdit as="input" size="sm" htmlSize={1} 
                        name="lotSize"
                        onChange={ this.updateRow }
                        value={ row.lotSize }>
                          <span className="purchase-lot section">                 
                            <FaLayerGroup />
                            <span>{ row.lotSize }x per Lot</span>
                          </span>
                      </QuickEdit>

                      <QuickEdit as="input" size="sm" htmlSize={10} 
                        name="override.price"
                        placeholder="Lot Price"
                        onChange={ this.updateRow }
                        value={ row.override.price }>
                          <span className="purchase-price section">
                            <FaFileInvoiceDollar />
                            <span>${ row.override.price } per Lot</span>
                          </span>
                      </QuickEdit>
                      
                      <QuickEdit as="textarea" size="sm" htmlSize={12} 
                        name="override.upc"
                        placeholder="UPC Override"
                        onChange={ this.updateRow }
                        value={ row.override.upc }>
                          <span className="purchase-upc section">
                            <FaBarcode />
                            <span>{ row.override.upc || product.upc }</span>
                          </span>
                      </QuickEdit>

                    </div>                              
                  </Col>     
     
                </Row>
                    
                <br />
              </Container>
        
            </Tab>

            { channel.previewShow &&
              <Tab eventKey="listingPreview" title="Listing Preview">
                <Row><Col sm="12">
                  <iframe 
                    className="listing-preview" 
                    srcDoc={ preview } />
                </Col></Row>
              </Tab>
            }

          </Tabs>
    </form>

    );
  }
}

const renderCategory = category => {
  const { id, name, ancestors = [] } = category;

  return (
    <div>
      <div>
        <span className="font-weight-bold">
          { name }{ ' ' }
        </span>
        <Badge pill variant="secondary" bg="secondary">{ id || '' }</Badge>
      </div>
      <div>
        <small className="text-muted">{ ancestors.join(' > ') }</small>
      </div>
    </div>
  );
}

const renderInputValue = item => {
  const { name, title } = item;
  
  return name || title;
} 

const renderListing = listing => {
}


export { ListingEdit };
