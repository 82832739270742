//import * as firebase from 'firebase'

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const fbConfig = {
  apiKey: "AIzaSyAtxiHhvBeMWd7Ib6dgacn9mlMySuYwH7c",
  authDomain: "newfangled.firebaseapp.com",
  databaseURL: "https://newfangled.firebaseio.com",
  projectId: "firebase-newfangled",
  storageBucket: "firebase-newfangled.appspot.com",
  messagingSenderId: "52985052365"
};

firebase.initializeApp(fbConfig);

const db = firebase.database();

/**
 * Saves a file to Firebase storage and returns the download URL.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} [path='uploads'] - The storage path where the file will be saved.
 * @param {Function} [onUpload] - Optional callback that receives upload progress (0-100).
 * @returns {Promise<string>} - A promise that resolves to the download URL of the uploaded file.
 */
export const saveFile = async (file, path = 'uploads', onUpload) => {
  const filename = uuidv4();
  const filePath = `${path}/${filename}`;
  const storageRef = firebase.storage().ref().child(filePath);

  const uploadTask = storageRef.put(file);

  if (onUpload) {
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onUpload(progress);
      },
      (error) => {
        console.error('Upload error:', error);
      }
    );
  }

  const snapshot = await uploadTask;
  return snapshot.ref.getDownloadURL();
}

/**
 * Flattens a nested object structure into a single-level object with path-like keys.
 *
 * @param {string} parentPath - The base path for the current level of the object.
 * @param {Object} data - The nested object to be flattened.
 * @param {Object} [result={}] - The object to store the flattened key-value pairs.
 * @returns {Object} - The flattened object with path-like keys.
 */
export const flattenPathData = (parentPath, data, result = {}) => {
  parentPath = parentPath || "";
  
  if (data && typeof data === 'object' && !Array.isArray(data)) {
    Object.keys(data).forEach(function(key) {
      flattenPathData(`${parentPath}/${key}`, data[key], result);
    });
  } else {
    result[parentPath] = data;
  }
  
  return result;
}

/**
 * Retrieves a record from the database at a specified path.
 *
 * @param {string} path - The database path to retrieve the record from.
 * @param {Array} [watchParams=[]] - An array containing optional watch parameters.
 * @returns {Promise<Object>} - A promise that resolves to the record data.
 */
export const getPath = async (path, watchParams = []) => {
  const [watchKey, watchValue] = watchParams;
  let snapshot;
  if (watchKey && watchValue) {
    snapshot = await db.ref(path).orderByChild(watchKey).equalTo(watchValue).once('value');
  } else {
    snapshot = await db.ref(path).once('value');
  }
  return {
    key: snapshot.key,
    ...snapshot.val()
  };
}

/**
 * Executes an array of promises sequentially.
 *
 * @param {Array} promises - An array of promise-returning functions.
 * @returns {Promise<void>} - A promise that resolves when all promises have been executed.
 */
export const promiseEach = async (promises) => {
  for (const promise of promises) await promise()
}

/**
 * Pushes a new record to the database at a specified path.
 *
 * @param {string} path - The database path where the new record will be pushed.
 * @param {Object} data - The data to be pushed as a new record.
 * @returns {Promise<Object>} - A promise that resolves to the new record reference.
 */
export const pushPath = async (path, data) => {
  const newRef = db.ref(path).push();
  await newRef.set(data);
  return newRef;
}

/**
 * Sets a record at a specified path in the database.
 *
 * @param {string} path - The database path where the record will be set.
 * @param {Object} data - The data to be set as a record.
 * @returns {Promise<void>} - A promise that resolves when the record has been set.
 */
export const setPath = async (path, data) => {
  return await db.ref(path).set(data);
}

/**
 * Updates records at specified paths in the database.
 *
 * @param {string} path - The database path where the records will be updated.
 * @param {Object} data - The data to be updated.
 * @param {boolean} [sequential=false] - If true, updates will be applied sequentially.
 * @returns {Promise<void>} - A promise that resolves when all updates have been applied.
 */
export const updatePath = async (path, data, sequential = false) => {
  const updateMap = flattenPathData(path, data);

  // Remove keys with undefined values from updateMap
  Object.keys(updateMap).forEach(key => {
    if (updateMap[key] === undefined) {
      delete updateMap[key];
    }
  });

  if (!sequential) {
    return db.ref().update(updateMap);
  } else {
    return promiseEach( 
      Object.keys(updateMap).map(path => 
        () => db.ref().update({ [ path ]: updateMap[ path ] })
      )
    );
  }
}

/**
 * Watches for updates at a specific path where a provided key matches a provided value.
 * @param {string} path - The database path to watch.
 * @param {string} watchKey - The key to match.
 * @param {*} watchValue - The value to match.
 * @param {function} callback - The callback function to be called when the condition is met.
 */
export const watchPath = (path, callback, watchParams = []) => {
  const [watchKey, watchValue] = watchParams;
  let ref = db.ref(path);

  if (watchKey && watchValue) {
    ref = db.ref(path).orderByChild(watchKey).equalTo(watchValue);
  }

  ref.on('value', snap => {
    callback({ key: snap.key, ...snap.val() });
  });

  return ref;
}

/**
 * Watches for updates at a specific path where a provided key matches a provided value.
 * @param {string} path - The database path to watch.
 * @param {function} callback - The callback function to be called when the condition is met.
 * @param {Array} [watchParams=[]] - An array containing optional watch parameters.
 */
export const watchPathChildren = (path, callback, watchParams = []) => {
  const [watchKey, watchValue] = watchParams;
  let ref = db.ref(path);
  
  if (watchKey && watchValue) {
    ref = db.ref(path).orderByChild(watchKey).equalTo(watchValue);
  }

  ref.on('child_changed', snap => {
      callback({ key: snap.key, ...snap.val() });
    });
    
    ref.on('child_added', snap => {
      callback({ key: snap.key, ...snap.val() });
  });
}

export { 
  firebase as default
};

