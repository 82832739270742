import React from 'react';
import BatchContext from './BatchContext';
import { ResultsComponent } from './AllThing';
import { Container, Row, Col, Form, Card, Button, ListGroup, Badge } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { Pager } from './Pager';
import { ListingEdit } from './ListingEdit';
import { ListingLink } from './ListingLink';
import { ListingRow } from './ListingRow';
import { StockCommitEdit } from './StockCommitEdit';
import ClipboardCopy from './ClipboardCopy';


class Listings extends ResultsComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      ...this.state,
      slideshowImages: [] ,
      filters: {
        archived: 'NOT archived:true',
        channel: '',
        condition: ''
      }
    };
    
    this.state.batch.opValue = '';
    this.state.batch.functions = {
      'Archive': this.batchArchive,
      'Unarchive': this.batchUnarchive
    };    
    
    this.editListing = this.editListing.bind(this);
    this.editStock = this.editStock.bind(this);
    this.linkListing = this.linkListing.bind(this);
  }

  batchArchive(unused, data, path) {
    return { 
      data: { [`${path}/archived`]: true } 
    }; 
  }
    
  batchUnarchive(unused, data, path) {
    return { 
      data: { [`${path}/archived`]: false } 
    }; 
  }
    
  editListing(listing) {
    const modalContent = (
      <ListingEdit 
        row={listing} 
        index={this.index}
        search={this.props.search}
        hideModal={this.hideModal}
        allowDelete="true"
        setModalButtons={this.setModalButtons} />
    );

    const modalTitle = (
      <>
        Edit Listing <ClipboardCopy copy={listing.key} />
      </>
    );
    
    this.showModal(modalTitle, modalContent);
  }  
  
  editStock(product) { 
    const modalContent = (
      <StockCommitEdit 
        title={ product.title }
        search={ this.props.search }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
        product={ product } />
    );

    const modalTitle = (
      <>
        Edit Stock <ClipboardCopy copy={product.key} />
      </>
    );
    
    this.showModal(modalTitle, modalContent);
  }

  linkListing(listing, product, listings) {
    const modalContent = (
      <ListingLink
        index="listing"
        row={ listing } 
        product={ product }
        listings={ listings }
        search={ this.props.search }
        hideModal={ this.hideModal }
        editListing={ this.editListing }
        editStock = { this.editStock }
        setModalButtons={ this.setModalButtons } />
    );

    const modalTitle = (
      <>
        Link Listing <ClipboardCopy copy={listing.key} />
      </>
    );
    
    this.showModal(modalTitle, modalContent);
  }
  
  render() {
    let search = this.props.search;
    let tableData = null;
    
    if (search.location === window.location.href) {
      tableData = this.props.results.map(row =>
        <ListingRow 
          key={row.key} 
          row={row} 
          index={this.index}
          editListing={this.editListing}
          editStock = { this.editStock }
          handleCheck={this.handleCheck}
          isChecked={this.isChecked}
          linkListing={ this.linkListing }
          showModal={this.showModal} /> 
      );
    } else {
      tableData = <div>Loading Data ...</div>;
    }
    
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>
            
              <Card className={`bulk-edit ${this.hasChecked() ? 'd-block' : 'd-none'}`}>
                <Card.Header><strong>Bulk Editing</strong></Card.Header>
                <Card.Body>
                  <Form.Group className="text-center">
                    <Form.Label className="font-weight-bold">{ this.countChecked() } rows selected</Form.Label>
                    <Form.Check 
                      type="checkbox"
                      onChange={() => this.checkAll(true)} 
                      label={`Select all ${this.search.count} matching rows`} />
                  </Form.Group>                      
                  <Form.Group>
                    <Form.Label>Batch Operation</Form.Label>
                    <Form.Control
                      as="select"
                      name="op"
                      defaultValue={this.state.batch.op}
                      onClick={e => this.handleSelect(e, 'batch')}>
                        <option>Select Operation</option>
                        <option>Archive</option>
                        <option>Unarchive</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="opValue"
                      value={this.state.batch.opValue}
                      onChange={e => this.handleUpdate(e, 'batch')}>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Button 
                      block
                      size="sm"
                      variant="outline-info"
                      onClick={this.addBatchOperation}>
                        Add Bulk Operation
                    </Button>
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <small>
                    Planned Operations: { this.state.batch.operations.length }
                  </small>
                </Card.Footer>

                <ListGroup variant="flush">
                  { this.state.batch.operations.map((operation, idx) =>
                    <ListGroup.Item key={idx} className="d-flex justify-content-between">
                      <span>
                        {idx + 1}. {operation.title} 
                        <Badge size="sm" variant="secondary" className="ml-2">{operation.value}</Badge>
                      </span>
                      <span>
                        <FaTimes 
                          className="clickable" 
                          onClick={() => this.removeBatchOperation(idx)} />
                      </span>
                    </ListGroup.Item>
                  )}
                </ListGroup>

                { !!this.state.batch.operations.length &&
                  <Card.Body>
                    <Form.Group>
                      <BatchContext.Consumer>
                        { batch => (
                          <Button 
                            block
                            size="sm"
                            variant="info"
                            onClick={() => batch.addBatch({
                              dataPaths: this.getBatchDataPaths(),
                              onData: this.state.batch.operations
                            })}>
                              Begin Bulk Updates
                          </Button>
                        )}
                      </BatchContext.Consumer>
                    </Form.Group>
                  </Card.Body>
                }
              </Card>            
            
              <Card>
                <Card.Header>Listing Filters</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Channel</Form.Label>
                    <Form.Control 
                      as="select"
                      name="channel"
                      defaultValue={this.state.filters.channel}
                      onClick={e => this.handleSelect(e, 'filters')}>
                        <option value="">All</option>
                        <option value="channel:&quot;Amazon*&quot;">Amazon</option>
                        <option value="channel:&quot;BrokerBin&quot;">BrokerBin</option>
                        <option value="channel:&quot;eBay&quot;">eBay</option>
                        <option value="channel:&quot;NewEgg&quot;">NewEgg</option>
                        <option value="channel:&quot;Shopify&quot;">Shopify</option>
                        <option value="channel:&quot;Walmart&quot;">Walmart</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Active Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="active"
                      defaultValue={this.state.filters.active}
                      onClick={e => this.handleSelect(e, 'filters')}>
                        <option value="">All</option>
                        <option value="(active:true) OR (NOT _exists_:active)">Active</option>
                        <option value="active:false">Not Active</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Link Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="link"
                      defaultValue={this.state.filters.link}
                      onClick={e => this.handleSelect(e, 'filters')}>
                        <option value="">All</option>
                        <option value="_exists_:product">Linked</option>
                        <option value="NOT _exists_:product">Not Linked</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Suppressed Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="suppressed"
                      defaultValue={this.state.filters.suppressed}
                      onClick={e => this.handleSelect(e, 'filters')}>
                        <option value="">All</option>
                        <option value="suppressed:false">Not Suppressed</option>
                        <option value="suppressed:true">Suppressed</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Listing Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="archived"
                      defaultValue={this.state.filters.archived}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Listings</option>
                        <option value="archived:true">Archived Listings</option>
                        <option value="NOT archived:true">Active Listings</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={10}>{ tableData }</Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <Pager
                pageCount={search.pages} 
                activePage={search.page} 
                onSelect={search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Listings };
