import React, { useState, useEffect, useCallback } from 'react';
import DynamicForm, { conditionsMet } from './DynamicForm';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { debounce } from 'lodash';
import { getPath, setPath, updatePath } from './fire';
import { useFormConfig } from './FormConfigContext';

import {
  TbPercentage0,
  TbPercentage10,
  TbPercentage20,
  TbPercentage30,
  TbPercentage40,
  TbPercentage50,
  TbPercentage60,
  TbPercentage70,
  TbPercentage80,
  TbPercentage90,
  TbPercentage100,
  TbCircleCheckFilled
} from 'react-icons/tb';

/**
 * A widget component for managing worklist form data and status
 * @param {Object} props - Component props
 * @param {Object} props.row - The row data containing order information
 * @param {string} props.id - The unique identifier for this worklist item
 * @param {Object} [props.formConfig] - Optional form configuration object
 * @param {React.ComponentType} [props.formIcon] - Optional icon component to display
 * @returns {JSX.Element} Rendered widget component
 */
const WorklistWidget = ({ formName, formIcon, formPath, formData: initialFormData = {}, defaultClass = 'text-dimmer'}) => {
  const [completePercent, setCompletePercent] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [formConfig, setFormConfig] = useState(null);
  const { getFormConfig } = useFormConfig();

  // Load form config
  useEffect(() => {
    const loadConfig = async () => {
      const config = await getFormConfig(formName);
      setFormConfig(config);
    };
    loadConfig();
  }, [getFormConfig, formName]);

  // Load form data
  useEffect(() => {
    const loadFormData = async () => {
      const data = await getPath(formPath);
      setFormData(data);
    };

    if (Object.keys(initialFormData).length > 0) {
      loadFormData();
    }
  }, [formPath, initialFormData]);

  // Debounced save function
  const debouncedSave = useCallback(
    debounce(async (newData) => {
      const questions = formConfig?.questions || [];

      if (formPath?.length > 0) {
        await Promise.all(questions.map(question => 
          setPath(`${formPath}/${question.id}`, newData[question.id] || null)
        ));
      }
    }, 2000),
    [formPath, formConfig]
  );

  const handleInputChange = (id, value) => {
    const newFormData = { ...formData, [id]: value };
    setFormData(newFormData);
    debouncedSave(newFormData);
  };

  // Calculate completion percentage when form data changes
  useEffect(() => {
    if (!formConfig) return;

    const activeQuestions = formConfig.questions.filter(
      question => conditionsMet(question, formData, formConfig.questions)
    );

    const answeredQuestions = activeQuestions.filter(
      question => formData[question.id]
    );

    setCompletePercent(Math.round(answeredQuestions.length / activeQuestions.length * 100));
  }, [formData, formConfig]);

  const getIcon = () => {
    if (!formIcon) {
      const classes = `${
        completePercent <= 0 
          ? defaultClass 
          : completePercent >= 100 
            ? 'text-success' 
            : 'text-warning'
      }`;

      switch (completePercent) {
        case 10: return <TbPercentage10 className={classes} />;
        case 20: return <TbPercentage20 className={classes} />;
        case 30: return <TbPercentage30 className={classes} />;
        case 40: return <TbPercentage40 className={classes} />;
        case 50: return <TbPercentage50 className={classes} />;
        case 60: return <TbPercentage60 className={classes} />;
        case 70: return <TbPercentage70 className={classes} />;
        case 80: return <TbPercentage80 className={classes} />;
        case 90: return <TbPercentage90 className={classes} />;
        case 100: return <TbCircleCheckFilled className={classes} />;
        default: return <TbPercentage0 className={classes} />;
      }
    }

    const classes = `${
      completePercent <= 0 
        ? defaultClass 
        : completePercent >= 100 
          ? 'text-success' 
          : 'text-warning'
    }`;

    return React.cloneElement(formIcon, { className: classes });
  };

  return (
    <>
      <OverlayTrigger
        placement="auto"
        trigger="click"
        rootClose={true}
        overlay={
          <Popover id={`popover-worklist-${formName}`}>
            <Popover.Content>
              {formConfig && (
                <DynamicForm 
                  formConfig={formConfig}
                  formData={formData}
                  onInputChange={handleInputChange}
                />
              )}
            </Popover.Content>
          </Popover>
        }
      >
        <span className="mr-1 clickable text-secondary">
          {getIcon()}
        </span>
      </OverlayTrigger>                      
    </>
  );
};

export default WorklistWidget;