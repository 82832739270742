import React, { createContext, useState, useContext, useCallback } from 'react';
import yaml from 'js-yaml';
import axios from 'axios';

const FormConfigContext = createContext({});

export const FormConfigProvider = ({ children }) => {
  const [formConfigs, setFormConfigs] = useState({});

  const getFormConfig = useCallback(async (yamlForm) => {
    // Return cached config if available
    if (formConfigs[yamlForm]) {
      return formConfigs[yamlForm];
    }

    // Fetch and process new config
    try {
      const response = await axios.get(`/yaml/${yamlForm}.yaml`);
      const config = yaml.load(response.data);
      let tabGroup = config?.questions?.[0]?.tabGroup;
      
      config.questions = config.questions.map(question => {
        tabGroup = question.tabGroup || tabGroup;
        return {
          level: 0,
          tabGroup: tabGroup,
          type: "radio",
          id: question.question.toLowerCase().replace(/\s+/g, '_'),
          options: question.type !== "checkbox" ? question.options || ["Yes", "No"] : [1],
          ...question
        };
      });

      // Cache the config
      setFormConfigs(prev => ({
        ...prev,
        [yamlForm]: config
      }));

      return config;
    } catch (error) {
      console.error('Error loading form:', error);
      return null;
    }
  }, []);

  return (
    <FormConfigContext.Provider value={{ formConfigs, getFormConfig }}>
      {children}
    </FormConfigContext.Provider>
  );
};

export const useFormConfig = () => useContext(FormConfigContext); 