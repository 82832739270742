import React from 'react';
import { FaBoxOpen, FaBox, FaTruckLoading, FaTruck, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { GiHandTruck } from 'react-icons/gi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Component to display shipment status icons
 * @param {Object} props - Component props
 * @param {Object} props.row - Row data containing status and shipping information
 * @returns {JSX.Element} ShipmentStatus component
 */
const ShipmentStatus = ({ row, openShipmentTrackingModal }) => {
  const { status, shipping, tracking = {} } = row;
  const today = new Date().setHours(0, 0, 0, 0);
  const cutoffDate = new Date(shipping.cutoff).setHours(0, 0, 0, 0);
  const isCanceled = status.toLowerCase().startsWith('cancel');
  const trackingNumber = Object.keys(tracking)[0];

  let icon = null;
  let tooltipText = '';
  let colorClass = '';

  if (status.toLowerCase() === 'shipped' && shipping.status === 'InfoReceived') {
    icon = <FaBoxOpen />;
    tooltipText = 'Label Created';
  } else if (status.toLowerCase() === 'packed') {
    icon = <FaTruckLoading />;
    tooltipText = 'Packed / Ready for Pickup';
  } else if (shipping.status === 'InTransit') {
    icon = <FaTruck />;
    tooltipText = 'In Transit';
  } else if (shipping.status === 'OutForDelivery') {
    icon = <GiHandTruck />;
    tooltipText = 'Out for Delivery';
  } else if (shipping.status === 'Delivered') {
    icon = <FaCheckCircle />;
    colorClass = 'text-success';
    tooltipText = 'Delivered';
  } else if (shipping.status === 'Exception' || shipping.status === 'AttemptFail') {
    icon = <FaExclamationCircle />;
    colorClass = 'text-danger';
    tooltipText = `Shipping Exception: ${shipping.status}`;
  }

  if (!icon) return null;

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-shipment-status-${row.key}`}>{tooltipText}</Tooltip>}
    >
      <span className={`ml-2 ${colorClass}`} onClick={() => openShipmentTrackingModal(tracking)}>{icon}</span>
    </OverlayTrigger>
  );
};

export default ShipmentStatus;