/**
 * ShipmentTracking Component
 *
 * A modern, responsive React component to display shipment tracking data.
 * Utilizes Bootstrap v4, React Bootstrap v1, and React Icons for theming.
 *
 * @component
 * @example
 * const trackingData = { ... } // Tracking JSON data
 * return <ShipmentTracking data={trackingData} />;
 */

import React, { useState, useEffect } from 'react';
import { getShipment } from './AllThing';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { FaTruck, FaBoxOpen, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { GiHandTruck } from 'react-icons/gi';
import ClipboardCopy from './ClipboardCopy';
import TrackingIcon from './TrackingIcon';

/**
 * Formats a date string to a readable format.
 *
 * @param {string} dateStr - The ISO date string to format.
 * @returns {string} - Formatted date string.
 */
const formatDate = (dateStr) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateStr).toLocaleDateString(undefined, options);
};

/**
 * Constructs the carrier's tracking URL.
 *
 * @param {string} carrier - The name of the carrier.
 * @param {string} trackingNumber - The tracking number.
 * @returns {string} - The tracking URL.
 */
const getTrackingUrl = (carrier, trackingNumber) => {
  if (!carrier || !trackingNumber) return '#';

  const baseUrls = {
    fedex: `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`,
    ups: `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`,
    usps: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
    // Add more carriers as needed
  };
  return baseUrls[carrier.toLowerCase()] || '#';
};

/**
 * ShipmentTracking Component to display shipment details and progress.
 *
 * @param {Object} props - Component props.
 * @param {string} props.tracking - Tracking number for the shipment.
 * @returns {JSX.Element} - Rendered component.
 */
const ShipmentTracking = ({ tracking }) => {
  const [shipments, setShipments] = useState({});

  // Convert tracking to array of tracking numbers for stable dependency
  const trackingNumbers = Object.keys(typeof tracking === 'object' ? tracking : { [tracking]: tracking });

  useEffect(() => {
    const fetchShipments = async () => {
      const newShipments = {};
      for (const trackingNumber of trackingNumbers) {
        try {
          const data = await getShipment(trackingNumber);
          newShipments[trackingNumber] = data;
        } catch (error) {
          console.error(`Error fetching shipment for ${trackingNumber}:`, error);
          newShipments[trackingNumber] = null;
        }
      }
      setShipments(newShipments);
    };
    fetchShipments();
  }, [tracking]);

  if (Object.keys(shipments).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="my-4">
      {Object.entries(shipments).map(([trackingNumber, shipment]) => (
        <Card key={trackingNumber} className="mb-4">
          <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <FaTruck size={20} className="mr-2" />
              <h5 className="mb-0">Shipment Tracking</h5>
            </div>
            <span>
              <TrackingIcon iconOnly={true} size={30} tracking={trackingNumber} />
              {shipment.service}
            </span>
            <span>
              <a
                href={getTrackingUrl(shipment.carrier, trackingNumber)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
            >
              {trackingNumber}
            </a>
              <ClipboardCopy copy={trackingNumber} />
            </span>
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col md={4} className="d-flex align-items-center">
                <FaBoxOpen className="mr-2 text-secondary" />
                <div>
                  <strong>Label Created:</strong>
                  <div>{formatDate(shipment.initiated)}</div>
                </div>
              </Col>
              <Col md={4} className="d-flex align-items-center">
                <GiHandTruck className="mr-2 text-secondary" />
                <div>
                  <strong>Package Picked Up:</strong>
                  <div>{formatDate(shipment.progress?.[0]?.timestamp)}</div>
                </div>
              </Col>
              <Col md={4} className="d-flex align-items-center">
                {shipment.status.toLowerCase() === 'intransit' ? (
                  <FaExclamationCircle className="mr-2 text-warning" />
                ) : (
                  <FaCheckCircle className="mr-2 text-success" />
                )}
                <div>
                  <strong>
                    {shipment.status.toLowerCase() === 'delivered' ? 'Delivered:' : 'Expected Delivery:'}
                  </strong>
                  <div>
                    {shipment.delivered || shipment.expected 
                      ? formatDate(shipment.delivered || shipment.expected) 
                      : 'TBD'}
                  </div>
                </div>
              </Col>
            </Row>
            <ListGroup variant="flush">
              {shipment?.progress?.map((update, index) => (
                <ListGroup.Item key={index} className="d-flex align-items-center">
                  <FaCheckCircle className="mr-3 text-primary" />
                  <div>
                    <strong>{update.location}:</strong> {update.message} <br />
                    <small className="text-muted">{formatDate(update.timestamp)}</small>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default ShipmentTracking;
