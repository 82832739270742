import React, { useState, useContext, useCallback } from 'react';
import BatchContext from './BatchContext';
import { useNotification } from './NotificationContext';
import fire from './fire';

/**
 * Provider component for batch operations
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components
 */
export const BatchProvider = ({ children }) => {
  const [batches, setBatches] = useState([]);
  const { notify } = useNotification();

  /**
   * Process a single item in the batch
   * @param {Object} batch - Current batch being processed
   */
  const processBatch = useCallback(async (batch) => {
    const { dataPaths, onData } = batch;

    if (dataPaths.length) {
      const path = dataPaths.pop();
      
      try {
        const snapshot = await fire.database().ref(path).once('value');
        const data = { ...snapshot.val(), key: snapshot.key };

        // Process all transformations sequentially
        for (const op of onData) {
          const update = await op.fn(op.value, data, path);
          console.log(op.label, update);
          if (update?.data) {
            await fire.database().ref().update(update.data);
          }
          if (update?.cooldown) {
            await new Promise(resolve => setTimeout(resolve, update.cooldown));
          }
        }

        // Update batch progress
        batch.completed++;
        processBatch(batch);
      } catch (error) {
        console.error('Batch processing error:', error);
        notify('Error processing batch', 'error');
      }
    } else if (!batch.complete && batch.completed === batch.total) {
      // Mark batch as complete
      batch.complete = true;

      // Execute completion callback if provided
      if (typeof batch.onComplete === "function") {
        await batch.onComplete(batch);
      }

      // Notify completion
      notify(
        `Updated ${batch.completed} records`,
        'success',
        'Batch Processing Complete'
      );

      // Remove completed batch
      setBatches(current => 
        current.filter(b => b !== batch)
      );
    }
  }, [notify]);

  /**
   * Add a new batch operation
   * @param {import('./BatchContext').BatchOptions} opts - Batch options
   */
  const addBatch = useCallback(({ 
    dataPaths = [], 
    onData = [], 
    onComplete, 
    threads = 5 
  }) => {
    const newBatch = {
      dataPaths,
      onData,
      onComplete,
      threads,
      total: dataPaths.length,
      completed: 0,
      complete: false
    };

    // Add notification when batch starts
    notify(`Starting batch process for ${dataPaths.length} records`, 'info');

    setBatches(current => [...current, newBatch]);

    // Start processing threads
    for (let i = 0; i < threads; i++) {
      processBatch(newBatch);
    }
  }, [processBatch, notify]);

  return (
    <BatchContext.Provider value={{ addBatch }}>
      {children}
    </BatchContext.Provider>
  );
};
