import { createContext, useContext } from 'react';

/**
 * @typedef {Object} BatchContextType
 * @property {(opts: BatchOptions) => void} addBatch - Function to add a new batch operation
 */

/**
 * @typedef {Object} BatchOptions
 * @property {string[]} dataPaths - Array of database paths to process
 * @property {Array<{title: string, value: string, callback: Function}>} onData - Array of operations to perform
 * @property {Function} [onComplete] - Optional callback when batch completes
 * @property {number} [threads=5] - Number of concurrent operations
 */

/** @type {React.Context<BatchContextType>} */
const BatchContext = createContext(null);

/**
 * Custom hook to use batch context
 * @returns {BatchContextType}
 * @throws {Error} When used outside of BatchProvider
 */
export const useBatch = () => {
  const context = useContext(BatchContext);
  if (!context) {
    throw new Error('useBatch must be used within a BatchProvider');
  }
  return context;
};

export default BatchContext;
