import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import NotificationContext from './NotificationContext';
import { Routes, Route } from 'react-router-dom';
import { Listings } from './Listings';
import { Products } from './Products';
import { Purchases } from './Purchases';
import { Sales } from './Sales';
import { Worklist } from './Worklist';

const ContentContainer = ({ user, results, search, swipeHandlers }) => {  
  return (
    <div className="mt-5 pt-4" {...swipeHandlers}>
      <ToastContainer />
      <NotificationContext.Consumer>
        { notification => {
          if (notification.list.length > 0) {
            notification.list.forEach(alert => {
              toast(alert.message, {
                type: alert.type,
                onClose: () => notification.dismiss(alert)
              });
            });
          }
          return null;
        }}
      </NotificationContext.Consumer>
      <Routes>
        {user && (user.admin || user.viewPurchases) && (
          <Route 
            path="/" 
            element={
              <Purchases 
                results={results} 
                search={search}
                index="order_purchase_item"
                sort="history.purchased"
              />
            } 
          />
        )}
        
        {user && (user.admin || user.viewPurchases) && (
          <Route 
            path="/purchases" 
            element={
              <Purchases 
                results={results} 
                search={search} 
                index="order_purchase_item"
                sort={["history.purchased:desc"]}
              />
            } 
          />
        )}
        
        {user && (user.admin || user.viewSales) && (
          <Route 
            path="/sales" 
            element={
              <Sales 
                results={results} 
                search={search} 
                index="order_sale"
                sort="ordered"
                defaultSort="ordered:desc"
              />
            } 
          />
        )}
        
        {user && (user.admin || user.viewListings) && (
          <Route 
            path="/listings" 
            element={
              <Listings 
                results={results} 
                search={search} 
                index="listing"
                sort="created"
              />
            } 
          />
        )}
        
        {user && (user.admin || user.viewProducts) && (
          <Route 
            path="/products" 
            element={
              <Products 
                results={results} 
                search={search} 
                index="product"
                sort="created"
              />
            } 
          />
        )}

        {user && (user.admin || user.viewWorklist) && (
          <Route 
            path="/worklist" 
            element={
              <Worklist
                results={results}
                search={search}
                index="order_sale"
                sort="ordered"
              />
            } 
          />
        )}
      </Routes>
    </div>  
  );
}

export { ContentContainer };
