import axios from 'axios';
import React from 'react';
import fire from './fire';
import { GenerativePicker } from './GenerativePicker';
import { Slideshow } from './Slideshow';
import ShortId from 'shortid';
import { ModalComponent } from './ModalContent';

class AllThingComponent extends ModalComponent {
  constructor(props) {
    super(props);
    
    this.search = this.props.search || {};

    // Global variables
    this.index = this.props.index;
    this.onDataHooks = [];
    this.references = { };    
    this._mounted = false;


    this.addDataRef = this.addDataRef.bind(this);
    this.clearDataRefs = this.clearDataRefs.bind(this);
    this.commit = this.commit.bind(this);
    this.commitAll = this.commitAll.bind(this);
    this.getUser = this.getUser.bind(this);
    this.onData = this.onData.bind(this);
    this.setMounted = this.setMounted.bind(this);
    this.setStateSafe = this.setStateSafe.bind(this);
    this.showImages = this.showImages.bind(this);
  }
  
  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;

    Object.keys(this.references).map(bucket => 
      this.references[bucket].map(ref => ref.off())
    );
  }
  
  addDataRef(ref, bucket = 'default') {
    this.references[bucket] = this.references[bucket] || [];
    this.references[bucket].push(ref);
  }  

  clearDataRefs(bucket = 'default') {
    if (!this.references[bucket]) return;
    this.references[bucket].map(ref => ref.off());
    this.references[bucket] = [];
  }
  
  onData(callback) {
    this.onDataHooks.push(callback);
  }
  
  async commitAll(all) {
    return await fire.database().ref().update(all);
  }
  
  async commit(data, path, key) {
    if (key) {
      return await fire.database().ref(path).child(key).update(data)
    } else {
      return await fire.database().ref(path).push().set(data);    
    }
  }
  
  getUser() {
    return fire.auth().currentUser;
  }
  
  setMounted(mounted) {
    this._mounted = !!mounted;
  }
  
  showImages(images, options = { }) {
    const { buttons, ...opts } = options;
    
    // if (!images || !images.length) return;
    
    this.setModalButtons(buttons);
    
    this.showModal('images', 
      <Slideshow images={ images } { ...opts } />
    );
  }      
  
  // Ensures that we don't try to set state on a row object that has already
  // been unmounted.
  setStateSafe(state) {
    if (this._mounted) this.setState(state);
  }
}

class ResultsComponent extends AllThingComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      checked: {},
      filters: {},
      lastFilters: {},
      results: [],
      batch: {
        operations: []
      }
    };
    
    this.index = this.props.index;
    this.sort = this.props.sort || "";
    
    this.addBatchOperation = this.addBatchOperation.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.countChecked = this.countChecked.bind(this);
    this.getBatchDataPaths = this.getBatchDataPaths.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.removeBatchOperation = this.removeBatchOperation.bind(this);
    this.results = this.props.results || [];
    this.setDefault = this.setDefault.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSort = this.setSort.bind(this);
    this.toggleBulkEdit = this.toggleBulkEdit.bind(this);
    //this.sortResults = this.sortResults.bind(this);

    this.search.setIndex(this.index, this.sort);
  }
  
  static getDerivedStateFromProps(props, state) {
    if (state.filters !== state.lastFilters) {
      props.search.setFilter(
        Object.values(state.filters)
          .reduce((acc, val) => acc.concat('(' + val + ')'), [])
          .filter(val => val !== '()')
          .join(' AND ')
      );

      props.search.runQuery();
      
      return { 
        lastFilters: state.filters,
        results: props.results
      };
    }
  
    return { results: props.results };
  }
  
  addBatchOperation() {
    const { op, opValue } = this.state.batch;
    const operations = [ ...this.state.batch.operations ];
    
    operations.push({
      title: op,
      value: opValue,
      callback: this.state.batch.functions[op]
    });
    
    this.setState({
      batch: {
        ...this.state.batch,
        opValue: '',
        operations
      }
    });
  }
  
  checkAll(allPages = false) {
    const checked = { 'all': true };
    
    if (allPages) {
      this.search.batchQuery().then(res => {
        res.map(row => checked[row.key] = true);
        this.setState({ checked });
      });
    } else {    
      this.state.results.map(row => checked[row.key] = true); 
      this.setState({ checked });
    }
  } 
  
  countChecked() {
    let rowCount = 0;
    
    Object.keys(this.state.checked).map(key => 
      rowCount+= this.state.checked[key] ? 1 : 0
    );
  
    return rowCount - (this.state.checked.all ? 1 : 0);
  }
  
  getBatchDataPaths() {
    return Object.keys(this.state.checked)
      .filter(key => this.state.checked[key] && key !== 'all')
      .map(key => `/${this.index}/${key}`);
  }
  
  handleCheck(key, allPages = false) {
    if (key === 'all') {
      if (this.state.checked[key]) {
        this.setState({ checked: {} });
      } else {
        this.checkAll(allPages);
      }
    } else {
      this.setState({ checked: {
         ...this.state.checked,
         [key]: !this.state.checked[key],
      }});
    }
  }
  
  handleSelect(e, parent) {
    const { name, options } = e.target;
    let selectedArr = [];
    
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedArr.push(options[i].value);
      }
    }
    
    this.setState({ [parent]: {
      ...this.state[parent], 
      [name]: selectedArr 
    }});
  }
  
  handleUpdate(e, parent) {
    const { name, value } = e.target;
    this.setState({ [parent]: {
      ...this.state[parent], 
      [name]: value 
    }});
  }  
  
  hasChecked() {    
    return Object.keys(this.state.checked).some(id => this.state.checked[id]);
  }
  
  isChecked(key) {
    return !!this.state.checked[key];
  }
  
  removeBatchOperation(idx) {
    const { operations } = this.state.batch;
  
    this.setState({
      batch: {
         ...this.state.batch,
         operations: [
           ...operations.slice(0, idx), 
           ...operations.slice(idx + 1) 
         ]
      }
    });
  }
  
  setDefault(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }  

  setFilter(name, value, operator = 'OR') {
    if (Array.isArray(value)) {
      value = value.join(` ${ operator } `);
    }
    
    this.setState({ filters: {
      ...this.state.filters,
      [name]: value
    }});
  }

  setSort(sortFields, runQuery = true) {
    this.search.setSort(sortFields);
    if (runQuery) this.search.runQuery();
  }
    
  toggleBulkEdit() {
    this.setState(prevState => ({
      bulkEdit: !prevState.bulkEdit
    }));
  }
  
} 

class RowComponent extends AllThingComponent {
  constructor(props) {
    super(props);
    
    // Default state
    this.state = { 
      row: props.row
    };
    
    // Global variables
    this.index = this.props.index;

    this.removeRow = this.removeRow.bind(this);
    
    // Load complete record details from Firebase
    let indexPath = this.index.split('_').join('/');    
    let itemRef = fire.database().ref(indexPath + '/' + this.state.row.key);
    
    // Store firebase reference
    this.addDataRef(itemRef);
    
    // Load complete product data from firebase
    itemRef.on('value', snapshot => {
      if (this._mounted) {
        const data = { ...snapshot.val(), key: snapshot.key };
        this.setState({ row: data });      
        this.onDataHooks.map(hook => hook(data));
      }
    });
  }
  
  removeRow() {
    if (this.references[0]) this.references[0].remove().then(() => {
      setTimeout(() => this.search.runQuery(), 1000);
    });
  }
} 
  
class EditComponent extends AllThingComponent {
  constructor(props, rowDefaults) {
    super(props);

    // Globals
    this.buttons = {};
    this.collection = {};    
    this.index = this.props.index;
    this.selectedImages = [ ];

    //this.validationErrors = {};
    
    // Bindings    
    this.addCollectionRow = this.addCollectionRow.bind(this);
    this.addCollection = this.addCollection.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.generate = this.generate.bind(this);
    this.getValidationErrors = this.getValidationErrors.bind(this)
    this.hasValidationErrors = this.hasValidationErrors.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.mergeCollectionRow = this.mergeCollectionRow.bind(this);
    this.refreshAndClose = this.refreshAndClose.bind(this);
    this.removeCollectionRow = this.removeCollectionRow.bind(this);
    this.saveRow = this.saveRow.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.selectImages = this.selectImages.bind(this);    
    this.setParentModalButtons = this.setParentModalButtons.bind(this);
    this.updateCollectionRow = this.updateCollectionRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    //this.validate = this.validate.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateField = this.validateField.bind(this);

    const row = props.row || rowDefaults || { };
        
    // Initialize state
    this.state = { 
      generating: 0,
      row: {
        ...row,
        key: row && row.key
      },
      validationErrors: { }
    };
    
    // Because the Modal container is controlled by the parent, we use the
    // setModalButtons property to update Modal buttons via callback.
    if (props.setModalButtons) {
      this.buttons.save = {
        callback: this.saveRow, 
        value: 'Save',
        disabled: this.hasValidationErrors()
      };
      
      if (props.allowDelete) {
        this.buttons.delete = {
          callback: props.onDelete ? () => props.onDelete(row) : this.deleteRow,
          value: props.deleteLabel || 'Delete',
          variant: 'danger', // deprecated
          bg: 'danger'
        };
      }

      props.setModalButtons(Object.values(this.buttons));
    }
  }
  
  addCollection({ field, include = [], exclude = [], ...opts }) {
    this.collection[field] = opts;    
    this.collection[field].include = include;
    this.collection[field].exclude = exclude;
  }
  
  addCollectionRow(field, rowDefaults) {
    const defaults = rowDefaults || this.collection[field].defaults;
  
    this.setState(s => ({ 
      row: {
        ...s.row,
        [field]: [ 
          ...(s.row[field] || []), 
          { ...defaults, id: ShortId.generate() } 
        ]
      }
    }));
  }
  
  afterSave(row) {
    return Promise.resolve(row);
  }
  
  beforeSave(row) {
    return Promise.resolve(row);
  }
  
  async deleteRow(row) {
    const { key } = this.state.row;
    const indexPath = this.index.split('_').join('/');

    if (!key) return;
    
    await fire.database().ref('/' + indexPath).child(key).remove();
    
    this.refreshAndClose(1000);
  }
  
  async generate(type) {
    const { 
      image,
      images,      
      vendor, 
      model, 
      partNumber, 
      upc, 
      productURL, 
      title, 
      desc, 
      bullets, 
      category, 
      dimensions,
      updateInstructions: instructions 
    } = this.state.row;

    const endpoint = 'https://dev.newfanglednetworks.com:8080';  
    
    const update = (key, val) => {
      this.setState({ row: {
        ...this.state.row, [ key ]: val
      }});
    }
    
    switch (type) {
      case 'images':
        this.setState({ generating: this.state.generating + 1 });
       
        return axios
          .get(`${ endpoint }/generateImages`, {
            params: { vendor, model, partNumber }
          }).then(response => {
            this.setState({ generating: this.state.generating - 1 });

            this.setState({ 
              row: {
                ...this.state.row,
                imageOptions: [
                  ...(this.state.imageOptions || []),
                  ...response.data
                ]
              }
            });
            
            return response.data || [];
          });
      break;
      case 'product':        
        this.setState({ generating: this.state.generating + 1 });
        
        return axios
          .get(`${ endpoint }/generateProduct`, {
            params: { 
              vendor, 
              model, 
              partNumber, 
              upc, 
              productURL, 
              instructions, 
              images: image || images 
            }
          })
          .then(response => {                    
            this.setState({ generating: this.state.generating - 1 });
            
            const modalContent = (
              <GenerativePicker 
                existingData={ this.state.row } 
                generativeData={ response.data }
                includeOnly={[ 
                  'upc', 'vendor', 'model', 'partNumber', 
                  'title', 'desc', 'bullets', 'category',
                  'dimensions', 'reply'
                ]}
                hideModal={ this.hideModal }
                onClose={ updates => console.log(updates) }
                onUpdate={ update }
                setModalButtons={ this.setModalButtons } />
            );
        
            this.showModal(`Generative Content Picker`, modalContent);
          });
      break;
      case 'updateProduct':
        const product = {      
          vendor, 
          model, 
          partNumber, 
          upc, 
          title, 
          desc, 
          bullets, 
          category, 
          dimensions 
        };
        
        this.setState({ generating: this.state.generating + 1 });
        const response = await axios.post(`${ endpoint }/updateProduct`, { product, instructions });
        this.setState({ generating: this.state.generating - 1 });

        const modalContent = (
          <GenerativePicker 
            existingData={ this.state.row } 
            generativeData={ response.data }
            includeOnly={[ 
              'upc', 'vendor', 'model', 'partNumber', 
              'title', 'desc', 'bullets', 'category',
              'dimensions'
            ]}
            hideModal={ this.hideModal }
            onClose={ updates => console.log(updates) }
            onUpdate={ update }
            setModalButtons={ this.setModalButtons } />
        );
    
        this.showModal(`Generative Content Picker`, modalContent);
      break;  
      default:
    }   
  }
  
  getValidationErrors() {
    const { count, ...errors } = this.state.validationErrors;
    return errors;
  }
  
  hasValidationErrors() {
    return !!this.state.validationErrors.count;
  }
  
  removeCollectionRow(field, idx) {
    if (this.state.row[field].length === 1) {
      this.setState(s => ({ row: { ...s.row, [field]: [] }}));
      this.addCollectionRow(field);
    } else {
      this.setState(s => ({
        row: { 
          ...s.row, 
          [field]: s.row[field].filter((row, id) => id !== idx) 
        }
      }));
    }
  }
  
  refreshAndClose(delay, row) {
    setTimeout(() => {
      this.props.search && this.props.search.runQuery();
      this.props.onClose && this.props.onClose(row);
      this.props.hideModal();
    }, delay);
  }
  
  saveRow() {
    const { key } = this.state.row;
    const indexPath = this.index.split('_').join('/');
    let row = {};
    let newKey;
    let save;
    
    // Selectively map saveFields to the target row object
    if (this.saveFields) {
      this.saveFields.map(field => row[field] = this.state.row[field] || null);
    } else {
      row = { ...this.state.row };
    }
    
    // Collection filtering - iterate over collection fields
    Object.keys(this.collection).forEach(field => {
      const opt = this.collection[field];
      
      if (field in row && row[field].length) {
        row[field] = row[field].map(sRow => {
          let fRow = { ...sRow };
          
          // Remove fields that aren't in 'include' if specified, or remove
          // fields that are in 'exclude' if specified
          Object.keys(fRow).forEach(key => {
            if (opt.include && opt.include.length 
              && !opt.include.includes(key)) {
                delete fRow[key];
            } else if (opt.exclude && opt.exclude.length 
              && opt.exclude.includes(key)) {
                delete fRow[key];
            }
          });
          
          return fRow;
        });
      }      
    });
    
    // Only save if a valid key exists OR if allowNewRecord is true
    if (key) {
      save = (row) => fire.database().ref('/' + indexPath).child(key)
        .update(row)
    } else if (this.allowNewRecord) { 
      delete row.key;

      const newRef = fire.database().ref('/' + indexPath).push();      
      newKey = newRef.key;

      save = (row) => newRef.set(row);
    } else {
      console.log('NO KEY');
      return;      
    }
    
  
    // Save and call beforeSave and afterSave hooks  
    this.beforeSave(row)
      .then(save(row))
      .then(this.afterSave(row, newKey))
      .then(this.refreshAndClose(1000, { ...row, key: row.key || newKey }))
      .catch(err => console.error(err));
  }
  
  // Callback passed to Slideshow that updates our internal temporary array
  // of selected images as they are selected via Slideshow.  When the selections
  // are finalized, selectImages() is called to update state and reset our
  // temporary array.
  selectImage(url) {
    if (this.selectedImages.indexOf(url) >= 0) {
      this.selectedImages = 
        this.selectedImages.filter(image => image !== url);
    } else {
      this.selectedImages.push(url);
    }
  }
  
  // Update state with selected images and reset the temporary selected images
  // array.  Hide the Slideshow modal used for image selection.
  selectImages() {
    this.setStateSafe({ 
      row: {
        ...this.state.row,
        image: [ ...this.selectedImages ]
      }
    });
    
    this.selectedImages = [ ];
    this.hideModal();
  }
        
  // Modify modal buttons for current window, which is controlled by
  // the parent modal container (which is why we are using a callback passed
  // in to the current component).  Calls to setModalButton only modify
  // buttons for new modals opened within / on-top of the current component.
  setParentModalButtons(buttons) {
    this.props.setModalButtons(buttons);
  }
    
  mergeCollectionRow(field, merge, idx) {
    this.setState(s => ({ row: {
      ...s.row,
      [field]: s.row[field].map((row, id) => idx !== id ? row : { 
        ...s.row[field][idx], 
        ...merge 
      })
    }}));    
  }
  
  updateCollectionRow(field, event, idx) {
    const { name, value } = event.target;
    
    this.setState(s => ({ row: {
      ...s.row,
      [field]: s.row[field].map((row, id) => idx !== id ? row : { 
        ...s.row[field][idx], 
        [name]: value
      })
    }}));    
  }
  

  // 12/3 Commented out validateForm and setModals - I believe this are no longer used
  updateRow(event) {
    const { name, value } = event.target;
    
    this.setState(
      s => ({ row: updatePath(name, value, s.row) }), 
      () => {
        //this.validateForm();
        //this.setModalButtons();
      }
    );
  }
  
  isInvalid(element) {
    return element in this.validationErrors;
  }
  
  validate(element) {
    return element in this.validationErrors ? "error" : null;
  }
  
  // Updated 12/2 - I think this wasn't working because the state wasn't
  // being updated.  Now it is.
  validateField(element, success, message) {
    const validationErrors = { ...this.state.validationErrors };
    
    if (!success) {
      validationErrors[element] = message;
    } else {
      delete validationErrors[element];
    }
    
    validationErrors.count = Object.keys(validationErrors).filter(key => key !== 'count').length;
    this.setState({ validationErrors });

    return !validationErrors[element];
  }
    
  validateForm(fields, rules, additionalErrors = {}) {
    const validationErrors = {
     ...validateForm(fields, rules),
     ...additionalErrors
   };
   
   validationErrors.count = Object.keys(validationErrors).length - 1;
    
    // Only update validationErrors state if there has been a change. This was
    // needed to prevent an endless loop.
    if (JSON.stringify(this.state.validationErrors)
      !== JSON.stringify(validationErrors)) {
        if (this.buttons.save) this.buttons.save.disabled = !!validationErrors.count;
        this.props.setModalButtons(Object.values(this.buttons));
        this.setState({ validationErrors });
    }
  }
}

function TextDisplay({ text }) {
    const lines = text.split('\n');
    return (
        <div>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
}

export const calculateAge = (orderDate) => {
  const now = new Date();
  const ordered = new Date(orderDate);
  
  // Convert UTC to EST
  const estOffset = -5 * 60; // EST is UTC-5
  const estNow = new Date(now.getTime() + estOffset * 60000);
  const estOrdered = new Date(ordered.getTime() + estOffset * 60000);

  const diffTime = Math.abs(estNow - estOrdered);
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffHours < 1) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    return `${diffHours}h`;
  } else if (diffDays < 30) {
    return `${diffDays}d`;
  } else if (diffMonths < 12) {
    return `${diffMonths}mo`;
  } else {
    return `${diffYears}y`;
  }
}

const escapeSearch = search => {
  return search.replaceAll(/([:])/g, '\\$1' );
}

const formatAmount = amount => {
  return amount.toLocaleString('en-US', { 
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
}

const formatListingLinks = (listing, product) => {
  const scope = getListingLinks(listing, product);
  
  const markup = scope.labels.map((label, idx) => (
    <span key={idx}>
      <strong>{label}:</strong>
      &nbsp;
      <a target="_blank" rel="noopener noreferrer" href={ scope.urls[idx] }>
        { scope.values[idx] || 'NA' }
      </a>
      {idx < scope.labels.length - 1 && <span>&nbsp;|&nbsp;</span>}
    </span>
  ));
  
  return (
    <small className="text-muted channel-id">{markup}</small>
  );
}

const formatProperCase = text => {
  return text.replace(/\w\S*/g, function(word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export const getChannels = () => {
  return [
    "Amazon US", "Amazon CA", "Walmart", "eBay", "BrokerBin", "Shopify"
  ];
}

const getChannelIcon = (channel = '', active = true) => {
  const channelClass = channel.toLowerCase().replace(/[^a-z0-9]/, '-');
  let style = {};

  const channelMap = {
    "Amazon US"  : "/img/AmazonUS.svg",
    "Amazon CA"  : "/img/AmazonCA.svg",
    "Amazon MX"  : "/img/AmazonMX.svg",
    "BrokerBin"  : "/img/BrokerBin.svg",
    "eBay"   : "/img/eBay.svg",
    "Shopify": "/img/Shopify.svg",
    "Shopify NFN": "/img/Shopify.svg",
    "Shopify ISP": "/img/Shopify.svg",
    "Stitch Labs": "/img/StitchLabs.svg",
    "ShipStation": "/img/ShipStation.svg",
    "NewEgg": "/img/NewEgg.svg",
    "Walmart": "/img/Walmart.svg"
  }

  if (!active) {
    if (channel === "Walmart") {
      style = { filter: 'grayscale(100%)', opacity: 0.25 };
    } else if (channel === "Amazon US" || channel === "Amazon CA") {
      style = { filter: 'grayscale(100%)', opacity: 0.05 };
    } else {
      style = { filter: 'grayscale(100%)', opacity: 0.10 };
    }
  }

  return (
    <img 
      src={channelMap[channel]} 
      alt={ channel }
      className={`channel-icon ${channelClass}`} 
      style={style}
    />      
  );
}

const getChannelLink = ({ channel, parentId, model, url: listingUrl }) => {
  let url;
  
  console.log(channel);

  switch (channel) {
    case 'Amazon US':
    case 'Amazon CA':
    case 'Amazon MX':
    case 'Shopify ISP':
    case 'Shopify NFN':
    case 'eBay NFN':
      url = listingUrl;
    break;
    case 'BrokerBin':
      url = `https://members.brokerbin.com/main.php?loc=partkey&login=northcoast2&parts=${model}&clm=partclei&mfgfilter=`;
    break;
    case 'Walmart':
      url = `https://www.walmart.com/ip/${parentId}`;
    break;
    default:
      url = ''
    break;
  }
  
  console.log(url);

  return url;
}

const getConditionVariant = (condition) => {
  const stockTypeMap = getStockTypeMap();
  const variantMap = {
    new: 'info',
    ref: 'warning',
    use: 'secondary'
  };

  if (condition in stockTypeMap) {
    return variantMap[ stockTypeMap[ condition ] ];
  } else if (condition in variantMap) {
    return variantMap[ condition ];
  }

  return 'secondary';
}

const getContact = async (id, persist = false) => {  
  if (persist) {    
    return fire.database().ref(`contact/${id}`).on('value', persist);
  } else {
    const snap = await fire.database().ref(`contact/${id}`).once('value');
    return { ...snap.val(), key: snap.key };
  }    
}

const getListingLinks = (listing, product) => {
  const scope = {
    urls: [],
    labels: [ 'ASIN', 'SKU', 'CONDITION', 'STOCK', 'PRICE' ],
    values: [
      listing.parentId, 
      listing.childId, 
      listing.channelCondition,
      listing.channelStock,
      parseFloat(listing.channelPrice, 2) 
    ]
  };

  switch (listing.channel) {
    case 'Amazon US':      
      scope.urls = [
        `https://www.amazon.com/dp/${listing.parentId}`,
        `https://sellercentral.amazon.com/myinventory/inventory?searchTerm=${listing.childId}`        
      ];
    break;
    case 'Amazon CA':
      scope.urls = [
        `https://www.amazon.ca/dp/${listing.parentId}`,
        `https://sellercentral.amazon.ca/myinventory/inventory?searchTerm=${listing.childId}`        
      ];
    break;
    case 'Amazon MX':
      scope.urls = [
        `https://www.amazon.mx/dp/${listing.parentId}`,
        `https://sellercentral.amazon.mx/inventory/ref=xx_invmgr_dnav_xx?tbla_myitable=search:${listing.childId}`        
      ];
    break;
    case 'BrokerBin':
     scope.labels = [ 'MODEL' ];
     scope.values = [ product.model ];
     scope.urls = [ `https://members.brokerbin.com/partkey?login=northcoast2&parts=${product.model}` ]
    break;
    case 'eBay':
      // @TODO Issue with ebay parent / child id data.  Parent id SHOULD be
      // the eBay item number.  Child should be removed.  Currently both
      // SKU and eBay item number are embedded in child id.
      scope.labels = [ 'ITEM', 'SIMILAR', 'SIMILAR' ];
      scope.values = [ listing.listingId, 'NEW', 'USED' ];
      scope.urls = [ 
        `https://www.ebay.com/itm/${listing.listingId}`,
        `https://www.ebay.com/sch/i.html?_nkw=%22${ product.model }%22&_dmd=2&_sop=15&rt=nc&LH_ItemCondition=3`,
        `https://www.ebay.com/sch/i.html?_nkw=%22${ product.model }%22&_dmd=2&_sop=15&rt=nc&LH_ItemCondition=4`
      ];
    break;
    case 'Shopify':
    case 'Shopify ISP':
    case 'Shopify NFN':
      scope.labels = [ 'VARIANT' ];
      scope.values = [ listing.parentId ];
      scope.urls = [ listing.url ];
    break;
    case 'NewEgg':
      scope.labels=[ 'ITEM', 'SKU' ];
      scope.values = [ listing.parentId, listing.key ];
      scope.urls = [ 
        `https://www.newegg.com/p/${listing.parentId}`,
        `https://sellerportal.newegg.com/manage-items/itemcreation?t=edit&n=${listing.parentId}&c=USA`
      ];
    break;
    case 'Walmart':
      scope.labels=[ 'ITEM' ];
      scope.values = [ listing.parentId ];
      scope.urls = [
       `https://www.walmart.com/ip/${listing.parentId}`
      ];
    break;
    default:
      scope.labels = [];
      scope.values = [];
      scope.urls = [];
    break;
  }
  
  return scope;
}

const getListing = (id, persist = false) => {
  if (persist) {
    return fire.database().ref(`listing/${id}`).on('value', persist);
  } else {
    return fire.database().ref(`listing/${id}`).once('value');
  }    
}

const getOrder = (id, persist = false) => {
  if (persist) {
    return fire.database().ref(`order/sale/${id}`).on('value', persist);
  } else {
    return fire.database().ref(`order/sale/${id}`).once('value');
  }    
}

// @TODO These helper functions should really just return data, and not 
// snapshots.  Have to refactor everywhere it is used to make the change.
// Using snapshot.val() and snapshot.key in components is messy.
const getProduct = async (id, persist = false) => {
  if (persist) {
    return fire.database().ref(`product/${id}`).on('value', persist);
  } else {
    return await fire.database().ref(`product/${id}`).once('value');
  }
}

const getPurchaseItem = (id, persist = false) => {
  if (persist) {
    return fire.database().ref(`order/purchase/item/${id}`).on('value', persist);
  } else {
    return fire.database().ref(`order/purchase/item/${id}`).once('value');
  }
}

export const getShipment = async (id, persist = false) => {
  if (persist) {
    return new Promise((resolve) => {
      fire.database().ref(`tracking/${id}`).on('value', (snapshot) => {
        resolve({ key: snapshot.key, ...snapshot.val() });
      });
    });
  } else {
    const snapshot = await fire.database().ref(`tracking/${id}`).once('value');
    return { key: snapshot.key, ...snapshot.val() };
  }
}

const getStockLog = (id) => {
  return fire.database().ref(`stock/${id}`).once('value');
}

// @TODO This may be defunct - confirm and remove
const getStockConditions = () => {
  return { new: 'New', use: 'Used' }
}

const getStockStyles = () => {
  return {
    fba: 'text-success',
    pri: 'text-info', new: 'text-info', likenew: 'text-info', 
    src: 'text-info', ref: 'text-warning', use: 'text-warning', 
    res: 'text-secondary'
  }
}

// This also defines display order
const getStockTypes = () => {
  return {
    fba: 'Amazon', pri: 'Prime', new: 'New', likenew: 'Like New', 
    src: 'Sourced', ref: 'Refurb', use: 'Used', res: 'Reserve'
  }
}

const getStockTypeMap = () => {
  return {
    fba: 'fba', pri: 'new', new: 'new', likenew: 'new',  
    src: 'new', ref: 'ref', use: 'use', res: 'res'
  }
}

const getProductStockByType = (product = { }, type = 'new') => {
  const stockMap = getStockTypeMap();
  let stock = 0;
  
  Object.keys(stockMap).map(condition => {
    if (stockMap[ condition ] === type) {
      stock += getProductStock(product, condition);
    }
    
    return stock;
  }); 
  
  return stock
}

const getProductStock = (product = {}, condition = 'new') => {
  const stock = parseInt(
    product.stock
    && product.stock[ condition ]
    && product.stock[ condition ].available, 0
  );
  
  return isNaN(stock) ? 0 : stock;
}

// Get price based on Amazon pricing.  May want to incorporate other channels
// as we begin to dynamically reprice NewEgg (and others) as well.
const getProductPrice = (product, listings) => {
  const prices = [ ];

  Object.keys(product.listing || { }).map(lid => {  
    const listing = listings[ lid ] || { };
    
    if (listing.channel === 'Amazon US') {
      const price = parseFloat(listing.price) 
        || parseFloat(listing.channelPrice);
      
      if (price) prices.push(price);
    }  
    
    return listing;  
  });
  
  return Math.max(prices);
}

// Get a level number for the ship status.  Used to sort and filter ship statuses.
export const getShipStatusLevel = (status) => {
  const shipStatusMap = {
    'Expired': 0,
    'Pending': 1,
    'InfoReceived': 2,
    'InTransit': 3,
    'Exception': 4,
    'AvailableForPickup': 5,
    'OutForDelivery': 6,
    'AttemptFail': 7,
    'Delivered': 8
  };

  return shipStatusMap[ status ] || 0;
}

// Get currently defined templates
const getTemplates = () => {
  return fire.database().ref('/setting/template')
    .once('value')
    .then(snap => snap.val());
}

const parseTemplate = (text = '', data = { }) => {
  const replacements = [ ];

  return text.replace(/{{([^}]+)}}/g, (match, cap) => {
    const params = cap.split('|').map(e => e.trim());
    let newVal = data[ params[ 0 ] ];    
    
    if (params.length <= 1) return newVal || params[ 0 ];
    
    for(let i = 1; i < params.length; i++) {
      const opParams = params[ i ].split(':').map(e => e.trim());

      switch(opParams[ 0 ]) {
        case 'map':
          newVal = newVal
            .map(arrVal => opParams[ 1 ].replace(/\$1/g, arrVal))
            .join('');          
        break;
        case 'index':
          if (newVal.length) {
            newVal = newVal[ opParams[ 1 ] ];
          }
        break;
        case 'shift':
          if (Array.isArray(newVal)) {
            newVal = [ ...newVal ];
            newVal.shift();
          }
        break;
        default:
        break;
      }
    }
    
    return newVal;    
  });
}

// Add and remove a key / value to the order item.  This will cause the
// underlying order sale triggers to re-run and attempt to match the order
// item to a product.
const linkSaleItem = (saleId, itemId) => {
  const path = `order/sale/${ saleId }/item/${ itemId }/scan`;
  const ref = fire.database().ref(path);
  
  // Add and then remove key value
  ref.set(true).then(() => ref.set(null));
}

/*
const getProductPrice = (product, variant, channel, listings) => {
  const listingArr = (product.variant 
    && product.variant[variant] 
    && product.variant[variant].listing  
    && product.variant[variant].listing[channel]
    && product.variant[variant].listing[channel]) || [];
        
  return listingArr.reduce((lowPrice, listingId) => {
    const currentPrice = listings[listingId] && listings[listingId].price;
    
    if (currentPrice && (lowPrice === 0 || currentPrice < lowPrice)) {
      lowPrice = currentPrice;
    }    
    
    return lowPrice;
  }, 0);
}
*/

/**
 * Removes all undefined values from an object or array, including nested structures.
 * 
 * @param {Object|Array} obj - The object or array to be cleaned.
 * @returns {Object|Array} - The cleaned object or array with undefined values removed.
 */
function removeUndefinedValues(obj) {
  if (Array.isArray(obj)) {
    // If it's an array, filter out undefined values and recurse on non-undefined values
    return obj
      .map(item => (typeof item === 'object' && item !== null ? removeUndefinedValues(item) : item))
      .filter(item => item !== undefined);
  } else if (typeof obj === 'object' && obj !== null) {
    // If it's an object, traverse through its properties
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      
      // Recursively remove undefined values from nested objects or arrays
      if (typeof value === 'object' && value !== null) {
        obj[key] = removeUndefinedValues(value);
      }
      
      // If the value is undefined, delete the key from the object
      if (value === undefined) {
        delete obj[key];
      }
    });
  }

  return obj;
}

/**
 * Formats a date according to the specified format.
 * 
 * @param {Date|string|number} date - The date to format. Can be a Date object, a date string, or a timestamp.
 * @param {string} format - The desired format for the date. Supported formats are:
 *   - 'M/D/YY': Month/Day/Year (e.g., 5/12/23)
 *   - 'M/D/YY LT': Month/Day/Year Hours:Minutes (e.g., 5/12/23 14:30)
 *   - 'fromNow': Time ago format (e.g., 2 hours ago)
 * @returns {string} The formatted date string.
 */
function formatDate(date, format) {
  if (!date) return '';
  const d = new Date(date);
  
  switch (format) {
    case 'M/D/YY':
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
    case 'M/D/YY LT':
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)} ${d.getHours()}:${d.getMinutes().toString().padStart(2, '0')}`;
    case 'fromNow':
      return timeAgo(d);
    default:
      return d.toLocaleString();
  }
}

/**
 * Calculates the time difference between the given date and now, and returns a human-readable string.
 * 
 * @param {Date} date - The date to compare against the current time.
 * @returns {string} A human-readable string representing the time difference (e.g., "2 hours ago").
 */
function timeAgo(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

/**
 * Retrieves the value of a nested key in dot notation from an object.
 * @param {string} key - The dot notation key to retrieve.
 * @param {Object} obj - The object to retrieve the value from.
 * @returns {*} - The value of the nested key if it exists, otherwise undefined.
 */
function resolve(key, obj) {
  if (!key || !obj) return undefined;
  
  // Split the key into its parts using the dot notation
  const parts = key.split('.');
  
  // Traverse the object using the parts of the key
  let value = obj;
  for (const part of parts) {
    if (!value?.[part]) {
      return undefined;
    }
    value = value[part];
  }
  
  // Return the final value
  return value;
}

// Unlink a listing from a product
const unlinkListing = (lid) => {
  return fire.database().ref(`/listing/${ lid }`).child('product').remove();
}

// Update an object's nested path value where path is in dot notation
const updatePath = (path, value, target) => {
  const updated = { ...target };
  const paths = path.split('.');
  let ptr = updated;
  
  paths.forEach((part, idx) => {
    if (idx === paths.length - 1) {
      ptr[part] = value;
    } else if (part in ptr) {
      ptr = ptr[part];
    } else {
      ptr = ptr[part] = {};
    }
  });

  return updated;
}

// Toggle state between array of values.  Function must be bound prior to use.
// Can't use arrow function here as arrow functions cannot be rebound.
const toggleState = function(keys, options = { }) {
  const newState = { ...this.state };
  const keyParts = keys.split('.');
  let statePtr = newState;
  let key;
  let val;
  
  // Ensure options is array formatted
  if (!options.length) {
    options = typeof options === 'object' ? Object.keys(options) : [ options ];
  }
  
  // Traverse object until keys target is reached
  keyParts.some((keyPart, idx) => {
    if (typeof newState[ keyPart ] === 'object' && idx + 1 < keyParts.length) {
      statePtr[ keyPart ] = { ...statePtr[ keyPart ] };
      statePtr = statePtr[ keyPart ];
    } else {
      key = keyPart;
      val = statePtr[ key ];
      return true;
    }
  });

  // Advance to next options index, or back to first index if at the end
  const currentIdx = options.indexOf(val) > -1 ? options.indexOf(val) : 0;
  const nextIdx = currentIdx + 1 >= options.length ? 0 : currentIdx + 1;
    
  // Update state
  statePtr[ key ] = options[ nextIdx ];
  this.setState(newState);
}  

// Validate form fields against validation rules.  Designed to be called
// recursively to validate fields nested within objects.
const validateForm = (fields = { }, rules = { }, depth = 0, errors = { count: 0 }, top = fields) => {
  if (!Object.keys(fields) || !Object.keys(rules)) return { };
  
  const addErr = (fieldParts, err) => {
    const fieldName = fieldParts.join('.');
  
    ++errors.count;
    errors[ fieldName ] = errors[ fieldName ] || [ ];
    errors[ fieldName ].push(err);
  };
  
  //fields = fields || { };
  //rules = rules || { };

  Object.keys(rules).forEach(field => {
    const fieldParts = field.split('.');
    const fieldName = fieldParts[ depth ];
    const val = fields[ fieldName ];
    
    if (fieldParts.length > depth + 1) {
      return validateForm(val, { [ field ]: rules[ field ] }, depth + 1, errors, fields);
    }
    
    // If field value (val) is undefined at this point, and the 'alt' field
    // has been specified, evaluate the rule against the alt field.
    // @TODO Consider supporting nested fieldnames as alts  
    const fieldRules = rules[ field ] || { };
    const fieldVal = val || resolve(fieldRules.alt, top);
    
    const rule = {
      req: true,
      type: 'text',
      ...fieldRules
    };
    
    switch (rule.type) {
      case 'currency':
        if ('min' in rule && parseFloat(fieldVal) < rule.min) {
          addErr(fieldParts, `must be ${ rule.min } or MORE`);
        }
        
        if (!(new RegExp('[0-9]+[.]?[0-9]{0,2}').test(`${ fieldVal }`))) {
          addErr(fieldParts, `must be in 0.00 currency format`);
        }      
      break;
      case 'list':
        if ('min' in rule && (fieldVal || [ ]).length < rule.min) {
          addErr(fieldParts, `must have at least ${ rule.min } entry`);
        }
      break;
      case 'quantity':
        if (!Number.isInteger(fieldVal)) {
          addErr(fieldParts, `must be in integer format`);
        }
        if ('min' in rule && parseInt(fieldVal) < rule.min) {
          addErr(fieldParts, `must be ${ rule.min } or MORE`);
        }
      case 'text':
        if (Object.keys(rule).length === 2 && rule.req) {
          rule.min = 1;
        }
      
        if ('min' in rule && (fieldVal || '').length < rule.min) {
          addErr(fieldParts, `must be ${ rule.min } characters or MORE`);
        }
        
        if ('max' in rule && (fieldVal || '').length > rule.max) {
          addErr(fieldParts, `must be ${ rule.max } characters or LESS`);
        }
        
        if ('pattern' in rule && !(new RegExp(rule.pattern).test(fieldVal))) {
          addErr(fieldParts, `must match pattern ${ rule.pattern }`);
        }
      break;
      default:
      break;
    }
    
  });
  
  return errors;
}

export { 
  AllThingComponent,
  ModalComponent,
  ResultsComponent, 
  RowComponent, 
  EditComponent, 
  TextDisplay,
  escapeSearch,
  formatAmount,
  formatDate,
  formatListingLinks,
  formatProperCase,
  getChannelIcon,
  getChannelLink,
  getConditionVariant,
  getContact,
  getListing,
  getListingLinks,
  getOrder,
  getProduct, 
//  getProductListings, 
  getProductStock, 
  getProductStockByType,
  getProductPrice,
  getPurchaseItem,
  getStockConditions,
  getStockLog,
  getStockStyles,
  getStockTypes,
  getStockTypeMap,
  getTemplates,
  linkSaleItem,
  parseTemplate,
  removeUndefinedValues,
  toggleState,
  unlinkListing,
  updatePath,
  validateForm
};
