import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Tracking } from './Tracking';
import { FaBan, FaCheck, FaExclamationTriangle, FaRegClock, FaRoad, FaShare } from 'react-icons/fa';
import { formatDate } from './AllThing';
import { watchPath } from './fire';

const TrackingGlyph = ({ tracking: trackingId, showModal }) => {
  const [tracking, setTracking] = useState({});

  useEffect(() => {
    let itemRef = null;
    
    if (trackingId) {
      itemRef = watchPath(`/tracking/${trackingId}`, (trackingData) => {
        setTracking(trackingData);
      });
    }

    // Cleanup function
    return () => {
      if (itemRef) itemRef.off();
    };
  }, [trackingId]);

  const showTracking = () => {
    const modalContent = <Tracking tracking={tracking} />;
    showModal('Shipment Tracking Details', modalContent);
  };

  const status = tracking.status || '';
  let statusIcon = null;
  let tooltip = null;

  switch (status.toLowerCase()) {
    case 'inforeceived':
    case 'pending':
      statusIcon = <FaRegClock className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">Label Created</Tooltip>;
      break;
    case 'intransit':
      statusIcon = <FaRoad className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">Expected {formatDate(tracking.expected, 'fromNow')}</Tooltip>;
      break;
    case 'exception':
      statusIcon = <FaExclamationTriangle className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">Exception</Tooltip>;
      break;
    case 'outfordelivery':
      statusIcon = <FaShare className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">Out for Delivery</Tooltip>;
      break;
    case 'delivered':
      statusIcon = <FaCheck className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">Delivered {formatDate(tracking.delivered, 'M/D/YY')}</Tooltip>;
      break;
    default:
      statusIcon = <FaBan className="mr-1" onClick={showTracking} />;
      tooltip = <Tooltip id="tooltip">No Tracking</Tooltip>;
      break;
  }

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      {statusIcon}
    </OverlayTrigger>
  );
};

export { TrackingGlyph };
