import React, { useState, useEffect } from 'react';
import { QuickEdit } from './QuickEdit';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getChannelIcon, getListing, getListingLinks, calculateAge, updatePath } from './AllThing';
import { getListingFloor, getListingCeiling, getListingPrice } from './repricingUtils';
import { setPath } from './fire';
import { FaBoxes, FaBolt, FaExclamationCircle, FaExclamationTriangle, FaAmazon } from "react-icons/fa";
import { PiSealCheckBold, PiTrophyBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { BiArrowToBottom, BiArrowToTop } from "react-icons/bi";

const ListingWidget = ({ productListing, listingId, product, editListing }) => {
  const [listing, setListing] = useState(productListing);
  const condition = listing?.condition || 'new';

  useEffect(() => {
    const fetchListing = async () => {
      if (listingId) {
        const listingSnapshot = await getListing(listingId);
        setListing({ key: listingId, ...listingSnapshot.val() });
      }
    };
    fetchListing();
  }, [listingId]);

  if (!listing) {
    return <span><FaExclamationCircle className="mr-2" />No listing available for this channel.</span>;
  }

  const updateListing = (event) => {
    const { name, value } = event.target;
    setListing(updatePath(name, value, listing));
  }

  const saveListingUpdate = (event) => {
    const { name, value } = event.target;

    const formattedValue = value.length ? parseFloat(value) : value;
    setPath(`/listing/${listingId}/${name.split('.').join('/')}`, formattedValue);
  }

  return (
    <div key={listing.key}>
      <div 
        className={`d-flex align-items-center mb-1 ${condition === 'new' 
          ? 'text-info' 
          : condition === 'ref' 
            ? 'text-warning' 
            : 'text-secondary'}`}
      >
        <span 
          className="mr-2 clickable" 
          onClick={() => { 
            editListing(listing, product); 
            document.body.click(); 
          }}
        >
          {getChannelIcon(listing.channel)}
        </span>
        <span className="mr-2">
          {listing.stockFast > 0 && <><FaBolt />{listing.stockFast || '0'}</>}
          {listing.stockFast <= 0 && <><FaBoxes className="mr-1" />{listing.stock || '0'}</>}
        </span>

        <QuickEdit as="input" size="sm" htmlSize={3}                      
          name={ `reprice.price` }
          value={ listing?.reprice?.price || '' }
          className="mr-1"
          onChange={ updateListing }
          onEndEdit={ saveListingUpdate }>
            <span className={`mr-1 editable ${listing?.reprice?.price ? 'text-primary' : ''}`}>
              ${getListingPrice(listing)}
            </span>
        </QuickEdit>        

        <span className="mr-1">
          {listing?.pricing?.myOffer?.featured && (
            <PiSealCheckBold />
          )}
          {!listing?.pricing?.myOffer?.featured && (
            <PiSealCheckBold 
              className="text-muted"
              style={{ opacity: !listing?.pricing?.myOffer?.featured 
                ? 0.25 
                : 1 }} 
            />
          )}
        </span>
        <span className="mr-1">
          {listing?.pricing?.myOffer?.buybox && (
            <>  
              <PiTrophyBold />
              {listing?.pricing?.myOffer?.prime && (
                <small><FaAmazon /></small>
              )}
            </>
          )}
          {!listing?.pricing?.myOffer?.buybox && (
            <>
              <PiTrophyBold 
                className="text-muted text-dimmer"
              />
              {listing?.pricing?.buyboxOffer?.price && (
                <span className="text-muted text-dim ml-1">
                  ${parseFloat(listing?.pricing?.buyboxOffer?.price).toFixed(2)}
                </span>
              )}
              {listing?.pricing?.buyboxOffer?.prime && (
                <small className="text-muted ml-1"><FaAmazon /></small>
              )}
            </>
          )}
        </span>
        <span className="mr-1">
          {listing?.pricing?.health?.issue && (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  {listing.pricing.health.issue}<br /> 
                  {Object.entries(listing?.pricing?.health?.reference || {}).map(([type, price]) => (
                    <>
                      {type}: ${price}<br />
                    </>
                  ))}
                </Tooltip>
              }
            >
              <small><FaExclamationTriangle className="text-danger"/></small>
            </OverlayTrigger>
          )}
        </span>
      </div>
      <div className="text-small text-secondary mb-2">
        { !!listing?.pricing?.buyboxOffer?.updated && (
          <span>
            <OverlayTrigger
              placement="auto"
              overlay={
              <Tooltip>
                {calculateAge(listing.pricing.buyboxOffer.updated)}
              </Tooltip>
            }
            >
              <span className="mr-half">
                <LuAlarmClock />
              </span>
            </OverlayTrigger>
          </span>
        )}

        <a href={getListingLinks(listing, product).urls[0]} target="_blank">
          <span className="mr-2 text-secondary"> {listing.parentId && `${listing.parentId}`}</span>
        </a>
        
        { !!listing?.reprice?.price && (
          <>
            <span className="mr-1 text-dim">
              <BiArrowToBottom className="mr-half" />
              <span className={`mr-1 ${listing?.reprice?.floor ? 'text-primary' : ''}`}>
                ${getListingFloor(listing, product)}
              </span>
            </span>

            <span className="mr-2 text-dim">
              <BiArrowToTop className="mr-half" />
              <span className={`mr-1 ${listing?.reprice?.ceiling ? 'text-primary' : ''}`}>
                ${getListingCeiling(listing, product)}
              </span>
            </span>                            
          </>
        )}

        { !listing?.reprice?.price && (
          <>
            <QuickEdit as="input" size="sm" htmlSize={3}              
              name={ `reprice.floor` }
              value={ listing?.reprice?.floor || '' }
              onChange={ updateListing }
              onEndEdit={ saveListingUpdate }>
                <span className="mr-1">
                  <BiArrowToBottom className="mr-half" />
                  <span className={`mr-1 editable ${listing?.reprice?.floor ? 'text-primary' : ''}`}>
                    ${getListingFloor(listing, product)}
                  </span>
                </span>
            </QuickEdit>

            <QuickEdit as="input" size="sm" htmlSize={3}              
              name={ `reprice.ceiling` }
              value={ listing?.reprice?.ceiling || '' }
              onChange={ updateListing }
              onEndEdit={ saveListingUpdate }>
                <span className="mr-2">
                  <BiArrowToTop className="mr-half" />
                  <span className={`mr-1 editable ${listing?.reprice?.ceiling ? 'text-primary' : ''}`}>
                    ${getListingCeiling(listing, product)}
                  </span>
                </span>                            
            </QuickEdit>
          </>
        )}

        {listing.condition && listing.condition.toUpperCase()}
      </div>
    </div>
  );
};

export default ListingWidget;
