// Helper function to get date in EST/GMT-5
const getESTDate = (daysOffset = 0) => {
  const date = new Date();
  // Adjust to EST (GMT-5)
  date.setHours(date.getHours() - 5);
  // Set time to beginning of day (00:00:00.000)
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + daysOffset);
  return date.toISOString().split('T')[0];
};

export const productSort = {
  label: 'Sort',
  multiple: false,
  options: [
    { value: 'analytics.sale.365.sales', label: 'Annual Sales' },
    { value: 'lastSaleDate', label: 'Last Sale' },
    { value: 'stockOutDate', label: 'Stock Out', 
      hiddenFilter: '_exists_:listing.id' },
    { value: 'created', label: 'Created' },
    { value: 'listing.pricing.buyboxOffer.updated', label: 'Buybox Update' },
    { value: `_script|
      doc['stock.fba.available'].value +
      doc['stock.pri.available'].value +
      doc['stock.new.available'].value +
      doc['stock.likenew.available'].value +
      doc['stock.src.available'].value +
      doc['stock.ref.available'].value +
      doc['stock.use.available'].value +
      doc['stock.res.available'].value
      `, label: 'Stock (all)', 
      hiddenFilter: 'NOT title:Accessories AND NOT title:Supplies' }
  ]
}

export const productStatusFilter = {
  label: 'Status',
  multiple: false,
  options: [
    { value: 'archived:true || title:DUPLICATE', label: 'Duplicate' },
  ]
};

export const productChannelFilter = {
  label: 'Store',
  multiple: true,
  operator: 'OR',
  options: [
    { value: 'listing.channel:"Amazon US"', label: 'Amazon US' },
    { value: 'listing.channel:"Walmart"', label: 'Walmart' },
    { value: 'listing.channel:"eBay"', label: 'eBay' },
    { value: 'listing.channel:"Amazon CA"', label: 'Amazon CA' },
    { value: 'listing.channel:"BrokerBin"', label: 'BrokerBin' },
    { value: 'listing.channel:"Shopify"', label: 'Web Store' },
  ]
};

export const productPricingFilter = {
  label: 'Pricing',
  multiple: true,
  operator: 'AND',
  options: [
    { value: 'listing.pricing.myOffer.buybox:true', label: 'Buybox' },
    { value: 'listing.pricing.myOffer.featured:true', label: 'Featured' },
    { value: () => `listing.pricing.buyboxOffer.updated:>=${getESTDate(-14)}`, label: 'Recent' }
  ]
};

export const productStockFilter = {
  label: 'Stock',
  multiple: true,
  operator: 'OR',
  options: [
    { 
      value: '(stock.pri.available:>0 OR ' +
             'stock.new.available:>0 OR ' +
             'stock.likenew.available:>0 OR ' +
             'stock.fba.available:>0 OR ' +
             'stock.src.available:>0 OR ' +
             'stock.ref.available:>0 OR ' +
             'stock.use.available:>0)', 
      label: 'Any' 
    },
    { value: '(stock.pri.available:>0 OR ' +
             'stock.new.available:>0 OR ' +
             'stock.likenew.available:>0 OR ' +
             'stock.fba.available:>0 OR ' +
             'stock.src.available:>0)', 
      label: 'New' 
    },
    { value: '(stock.use.available:>0 OR ' +
             'stock.ref.available:>0 OR ' +
             'stock.src.available:>0)', 
      label: 'Used' 
    },
    { value: '(stock.res.available:>0)', 
      label: 'Reserved' 
    }
  ]
};

export const purchaseStatusFilter = {
  label: 'Status',
  multiple: true,
  operator: 'AND',
  options: [
    { value: '_exists_:history.cancelled', label: 'Cancelled' },
    { value: '_exists_:manifest.pid', label: 'Recorded' },
    { value: 'status:Delivered', label: 'Delivered' },
    { value: 'manifest.type:receipt', label: 'Received' },
    { 
      value: `(
        _exists_:manifest.committed OR 
        manifest.condition:order OR 
        manifest.condition:parts OR
        manifest.condition:return
      )`, 
      label: 'Committed' 
    },
    { value: '_exists_:history.refunded', label: 'Refunded' },
  ]
}

export const purchaseReturnFilter = {
  label: 'Returns',
  multiple: false,
  operator: 'OR',
  options: [
    { 
      value: `(
        manifest.condition:return AND NOT
        _exists_:manifest.return AND NOT
        _exists_:history.refunded
      )`, 
      label: 'Request' 
    },
    { 
      value: `(
        manifest.condition:returnrequested AND NOT
        _exists_:manifest.return AND NOT
        _exists_:history.refunded
      )`, 
      label: 'Requested' 
    },
    { 
      value: `(
        (manifest.condition:returned OR _exists_:manifest.return) AND NOT
        _exists_:history.refunded
      )`, 
      label: 'Returned' 
    }
  ]
}

export const purchaseDateFilter = {
  label: 'Date',
  multiple: true,
  operator: 'AND',
  options: [
    { value: () => `history.purchased:>=${getESTDate(-14)}`, label: 'Within 2 Weeks' },
    { value: () => `history.purchased:>=${getESTDate(-60)}`, label: 'Within 60 Days' },
    { value: () => `history.purchased:>=${getESTDate(-90)}`, label: 'Within 90 Days' },
  ]
}


export const saleSort = {
  label: 'Sort By',
  multiple: false,
  options: [
    { value: 'ordered', label: 'Ordered' },
    { value: 'shipping.cutoff', label: 'Ship By', direction: 'asc' },
  ]
};

export const saleChannelFilter = {
  label: 'Stores',
  multiple: true,
  operator: 'OR',
  options: [
    { value: 'channel:"Amazon US"', label: 'Amazon US' },
    { value: 'channel:"Amazon CA"', label: 'Amazon CA' },
    { value: 'channel:"eBay"', label: 'eBay' },
    { value: 'channel:"BrokerBin"', label: 'BrokerBin' },
    { value: 'channel:"Shopify"', label: 'Shopify' },
    { value: 'channel:"NewEgg"', label: 'NewEgg' },
    { value: 'channel:"Walmart"', label: 'Walmart' },
  ]
};

export const saleToDoFilter = {
  label: 'Filter',
  multiple: true,
  operator: 'AND',
  options: [
    { 
      value: `item.status.order:Yes OR 
              item.status.other_order:Yes OR 
              item.stockCondition:src OR 
              item.stockCondition:res`, 
      label: 'Order' 
    },
    { value: 'item.status.picked:Yes', label: 'Picked' },
    { value: 'item.status.packed:1', label: 'Packed' },
    { value: 'item.stockCondition:pri', label: 'Prime' },
    { value: 'item.stockCondition:new', label: 'New' },
  ]
}

export const saleStatusFilter = {
  label: 'Status',
  multiple: false,
  operator: 'OR',
  options: [
    {
      value: () => `(
        (NOT status:"Cancelled" AND NOT status:"Canceled" AND NOT status:"Pending") AND 
        ((NOT _exists_:tracking) OR (shipping.status:"") OR (shipping.status:"InfoReceived")) AND
        ordered:>=${getESTDate(-60)}
      )`,
      label: 'Open',
      sort: 'Ship By'
    },
    { 
      value: () => `(
        shipping.cutoff:>0 AND shipping.cutoff:<=${getESTDate(0)} AND 
        ordered:>=${getESTDate(-60)} AND
        NOT _exists_:tracking AND 
        (NOT status:"Cancelled" OR NOT status:"Canceled")
      )`, 
      label: 'Confirm Today' 
    },
    {
      value: `(_exists_:shipping.status AND NOT shipping.status:"InfoReceived")`,
      label: 'Shipped',
      sort: 'Ship By:desc'
    },
    { 
      value: '_exists_:return.tracking', 
      label: 'Return' 
    },
    { 
      value: `(
        NOT _exists_:item.product AND 
        NOT channel:"ShipStation" AND 
        NOT channel:"20140"
      )`, 
      label: 'Unlinked' 
    }
  ]
};

export const worklistSort = {
  label: 'Sort By',
  multiple: false,
  options: [
    { value: 'ordered', label: 'Ordered' },
    { value: 'shipping.cutoff', label: 'Ship By', direction: 'asc' },
  ]
};

export const worklistStatusFilter = {
  label: 'Status',
  multiple: false,
  operator: 'OR',
  options: [
    {
      value: () => `(
        (NOT status:"Cancelled" AND NOT status:"Canceled" AND NOT status:"Pending") AND 
        ((NOT _exists_:tracking) OR (shipping.status:"") OR (shipping.status:"InfoReceived")) AND
        ordered:>=${getESTDate(-60)}
      )`,
      label: 'Open'
    },
    { 
      value: () => `(
        shipping.cutoff:>0 AND shipping.cutoff:<=${getESTDate(0)} AND 
        ordered:>=${getESTDate(-60)} AND
        NOT _exists_:tracking AND 
        (NOT status:"Cancelled" OR NOT status:"Canceled")
      )`, 
      label: 'Confirm Today' 
    },
    {
      value: () => `(
        (shipping.status:"InfoReceived" OR shipping.status:"Unshipped") AND
        ordered:>=${getESTDate(-60)} AND
        (NOT status:"Cancelled" OR NOT status:"Canceled")
      )`,
      label: 'Not Shipped',
      sort: 'Ship By'
    },
    {
      value: `(_exists_:shipping.status AND NOT shipping.status:"InfoReceived")`,
      label: 'Shipped'
    },
    { 
      value: '_exists_:return.tracking', 
      label: 'Return' 
    },
    { 
      value: `(
        NOT _exists_:item.product AND 
        NOT channel:"ShipStation" AND 
        NOT channel:"20140"
      )`, 
      label: 'Unlinked' 
    }
  ]
};

// Optional: Keep the original filterDefinitions object for backwards compatibility
export const filterDefinitions = {
  archived: productStatusFilter,
  channels: productChannelFilter,
  fgStock: productStockFilter
};
