import { createContext, useContext } from 'react';

/**
 * @typedef {Object} Notification
 * @property {number} id - Unique identifier
 * @property {string} message - Notification message
 * @property {('info'|'success'|'warning'|'error')} type - Notification type
 * @property {string} [headline] - Optional notification headline
 */

/**
 * @typedef {Object} NotificationContextType
 * @property {Notification[]} list - List of active notifications
 * @property {(message: string, type?: string, headline?: string) => void} notify - Function to add a notification
 * @property {(notification: Notification) => void} dismiss - Function to remove a notification
 */

/** @type {React.Context<NotificationContextType>} */
const NotificationContext = createContext(null);

/**
 * Custom hook to use notification context
 * @returns {NotificationContextType}
 * @throws {Error} When used outside of NotificationProvider
 */
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export default NotificationContext;
