import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Form, FormControl, Button } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
//import { useSwipeable } from 'react-swipeable';


const NavBarTop = ({ user, auth, setQuery, runQuery, setSwipeHandlers }) => {
  const [query, setLocalQuery] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const routes = ['/products', '/listings', '/purchases', '/sales', '/worklist'];

  // Format current nav item for display
  const currentNavItem = routes.find(
    route => route === location.pathname
  )?.split('/')?.[1] || '';
  
  /*
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = routes.indexOf(location.pathname);
      const nextIndex = currentIndex === routes.length - 1 ? 0 : currentIndex + 1;
      navigate(routes[nextIndex]);
    },
    onSwipedRight: () => {
      const currentIndex = routes.indexOf(location.pathname);
      const prevIndex = currentIndex === 0 ? routes.length - 1 : currentIndex - 1;
      navigate(routes[prevIndex]);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    delta: 100
  });

  useEffect(() => {
    setSwipeHandlers(handlers);
    return () => {
      setSwipeHandlers(null);
    };
  }, [handlers, setSwipeHandlers]);
  */
 
  const handleClick = (e) => {
    e.preventDefault();

    setLocalQuery('');
    setQuery('*');
    runQuery(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') runQuery(true);
  };

  const logout = () => {
    auth().signOut();
    navigate('/');
  };

  const handleUpdate = (e) => {
    setLocalQuery(e.target.value);
    setQuery(e.target.value);
  };

  return (
    <Navbar bg="dark" variant="dark" fixed="top">
      <Navbar.Brand 
        href="#" 
        onClick={(e) => {
          e.preventDefault();
          const currentIndex = routes.indexOf(location.pathname);
          const nextIndex = currentIndex === routes.length - 1 ? 0 : currentIndex + 1;
          navigate(routes[nextIndex]);
        }}
      >
        AllThing
      </Navbar.Brand>
      <Form className="d-flex flex-grow-1 justify-content-end mx-2">
        <div className="position-relative" style={{ flex: 1 }}>
          <FormControl 
            type="text" 
            placeholder={`Search ${currentNavItem}`}
            value={query}
            onChange={handleUpdate} 
            onKeyPress={handleKeyPress} 
            className="mr-sm-2" 
            style={{ fontSize: '16px' }}
          />
          <FaTimesCircle
            className="position-absolute"
            style={{
              right: '10px', 
              top: '50%',
              transform: 'translateY(-50%)',
              marginTop: '1px',
              opacity: query ? '0.5' : '0.1',
              cursor: 'pointer',
              color: 'black'
            }}
            onClick={handleClick}
            aria-label="Clear search"
          />
        </div>
      </Form>
      <Nav className="d-none d-lg-flex">
        <>
          {(user.admin || user.viewProducts) && (
            <Nav.Item className="px-2">
              <NavLink
                to="/products"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Products
              </NavLink>
            </Nav.Item>
          )}
          {(user.admin || user.viewListings) && (
            <Nav.Item className="px-2">
              <NavLink
                to="/listings"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Listings
              </NavLink>
            </Nav.Item>
          )}
          {(user.admin || user.viewPurchases) && (
            <Nav.Item className="px-2">
              <NavLink
                to="/purchases"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Purchases
              </NavLink>
            </Nav.Item>
          )}
          {(user.admin || user.viewSales) && (
            <Nav.Item className="px-2">
              <NavLink
                to="/sales"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Sales
              </NavLink>
            </Nav.Item>
          )}
          {(user.admin || user.viewWorklist) && (
            <Nav.Item className="px-2">
              <NavLink
                to="/worklist"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Worklist
              </NavLink>
            </Nav.Item>
          )}
        </>
      </Nav>
      <Nav className="px-4 d-none d-lg-flex">
        <Nav.Link onClick={logout}>Logout {user.name}</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export { NavBarTop };
