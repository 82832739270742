import React, { useState } from 'react';
import { FaRegCopy, FaCheck } from 'react-icons/fa';

/**
 * A component that displays a copy icon and copies text to clipboard when clicked
 * @param {Object} props - Component props
 * @param {string} props.copy - The text to copy to clipboard
 * @returns {JSX.Element} ClipboardCopy component
 */
const ClipboardCopy = ({ copy, className }) => {
  const [copied, setCopied] = useState(false);

  /**
   * Fallback method for copying text when Clipboard API is not available
   * @param {string} text - The text to copy
   */
  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
  };

  /**
   * Handles the copy action and shows success state
   * @async
   */
  const handleCopy = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(copy);
      } else {
        fallbackCopyToClipboard(copy);
      }
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    copied ? (
      <FaCheck className="ml-2 text-smaller" />
    ) : (
      <FaRegCopy className={`ml-2 text-smaller clickable ${className}`} onClick={handleCopy} />
    )
  );
};

export default ClipboardCopy;
