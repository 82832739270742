import React from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { getChannelIcon, getChannels, getListingLinks, getConditionVariant } from './AllThing'; // Assuming this function is available
import { CiSquarePlus } from "react-icons/ci";
import { TbMoneybag } from "react-icons/tb";
import ListingWidget from './ListingWidget';

const ProductListingsWidget = ({ listings, product, editListing }) => {
  const listingsByCondition = Object.entries(listings).reduce((acc, [key, listing]) => {
    acc[listing.condition] = acc[listing.condition] || {};
    acc[listing.condition][listing.channel] = acc[listing.condition][listing.channel] || [];
    acc[listing.condition][listing.channel].push({
      ...listing, 
      links: getListingLinks(listing, product),
      price: listing?.pricing?.myOffer?.price || 
             listing.channelPrice || 
             listing.override?.price || 
             listing.price || 
             0
    });
    return acc;
  }, {});

  const sortedListingsByCondition = Object.keys(listingsByCondition)
    .sort()
    .reduce((acc, key) => {
      acc[key] = listingsByCondition[key];
      return acc;
    }, {});

  const listingsPriceByCondition = Object.entries(listingsByCondition).reduce((acc, [condition, channels]) => {
    const allListings = Object.values(channels).flat();
    const totalPrices = allListings.reduce((sum, listing) => {
      const price = listing.price || 0;
      return sum + (Number(price) > 0 ? Number(price) : 0);
    }, 0);
    
    const nonZeroPriceListingsCount = allListings.filter(listing => {
      const price = listing.price || 0;
      return Number(price) > 0;
    }).length;
    
    const averagePrice = totalPrices / (nonZeroPriceListingsCount || 1);
    acc[condition] = averagePrice;
    return acc;
  }, {});

  return (
    <div className="product-listings">
      {Object.entries(sortedListingsByCondition).map(([condition, channels]) => (
        <div key={condition}>
          <span style={{ display: 'inline-block', width: '3rem', textAlign: 'center' }}>
            <Badge
              pill
              variant={getConditionVariant(condition)}
              className="mr-2 circle-badge"
            >
              <small>{channels[Object.keys(channels)[0]]?.[0]?.stock || 0}</small>
            </Badge>
          </span>
          <span style={{ width: '70px', display: 'inline-block' }}>
            {!!listingsPriceByCondition[condition] &&
              <small 
                className={`mr-2 text-${getConditionVariant(condition)}`}                
              >
                ${listingsPriceByCondition[condition].toFixed(2)}
              </small>
            }
          </span>
          <span style={{ width: '70px', display: 'inline-block' }}>
            {!!product?.price?.[condition] && !!product?.cost?.[condition] && (
              <small 
                className={`mr-2 text-secondary`}
              > 
                <TbMoneybag className="mr-1" />
                <span className="text-dim ">
                  {(
                    ((product.price[condition] - product.cost[condition]) / 
                    product.cost[condition])
                  )?.toFixed(1)}x
                </span>
              </small>
          )}
          </span>
          <span>
            {getChannels().map(channel => (
              <Channel
                key={channel}
                channel={channel}
                listings={channels[channel]}
                condition={condition}
                product={product}
                editListing={editListing}
              />
            ))}
          </span>
          {condition === Object.keys(sortedListingsByCondition).slice(-1)[0] && (
            <NewChannel
              product={product}
              listings={listings}
              editListing={editListing}
            />
          )}
        </div>
      ))}
      {Object.keys(listings).length === 0 && (
        <NewChannel
          product={product}
          listings={listings}
          editListing={editListing}
        />
      )}
    </div>
  );
};

const Channel = ({ channel, listings = [], condition, product, editListing }) => {
  return (
    <div key={channel} className="mr-1" style={{ width: '14px', height: '14px', display: 'inline-block' }}>
      <OverlayTrigger
        placement="auto"
        trigger="click"
        rootClose={true}
        overlay={
          <Popover id={`popover-${channel}`}>
            <Popover.Content>
              <div>
                {listings.map((listing) => (
                  listing.condition === condition && (
                    <ListingWidget 
                      key={listing.key}
                      productListing={listing}
                      listingId={listing.key}
                      product={product}
                      editListing={editListing}
                    />
                  )
                ))}
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        {getChannelIcon(channel, !!listings.length)}
      </OverlayTrigger>
    </div>
  );
}

const NewChannel = props => {
  const { product, listings, editListing } = props;

  return (
    <CiSquarePlus 
      className="text-muted ml-1"
      onClick={e => editListing({}, product, listings)} />
  );
}

export default ProductListingsWidget;
